import React, { useEffect } from 'react';
import DisableIcon from '../images/disable-icon.svg';
import CheckIcon from '../images/icon-info.svg';
import ExclamationIcon from '../images/exclamation-icon.svg';
import LockedIcon from '../images/lockedIcon.svg';
import OpenIcon from '../images/openIcon.svg';
import EmbedIcon from '../images/embedIcon.svg';
import ShareIcon from '../images/shareIcon.svg';
import WarningIcon from '../images/warningIcon.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { EmbedSceneModal, PrivateSharingModal, ShareSceneModal } from './modals';
import { useSceneVisualizerActions } from '../data/useSceneVisualizer';
import { useCoreScene } from '../../../routes/dashboard/pages/projects/data';
import { get, useEcommerce } from '@radical/api';
import { changeDescription, changeRatio, setPrivateShare, updateAi } from '../data/sceneDetails';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Textarea } from '@components/form';
import { DASHBOARD_PATH } from '@routes/dashboard';
import { Modal } from '@components/modals';
import rocketIcon from './images/rocket.png';
import { SolidButton } from '@components/buttons';
import { Loader } from '@radical/ui/utils/LoadingData/loader';
import { TopUpPopUp, UpgradePopUp } from '@routes/dashboard/pages/projects/upload/live/steps/components';
import { ErrorMessages } from '@routes/dashboard/pages/projects/components/core/NewProjectContent/NewProjectContent';
import { SpinIcon, DownloadIcon, FaceTrackingIcon } from './Icons';
import { useQueryClient } from '@tanstack/react-query';
import { useLoggedIn } from '@features/authentication';
import { CORE_INTEGRATIONS_LIMITS_QUERY, useUserLimits } from '../data/useUserLimits';

const SERVICE_URL = window.__ENV__.WP_HOST;

function compareSemverTags(tag1: string, tag2: string) {
  const parseTag = (tag: string) => tag.split('.').map((revision: string) => parseInt(revision.split('-')[0]));

  const [major1, minor1, patch1] = parseTag(tag1);
  const [major2, minor2, patch2] = parseTag(tag2);

  if (major1 > major2) return 1;
  if (major1 < major2) return -1;

  if (minor1 > minor2) return 1;
  if (minor1 < minor2) return -1;

  if (patch1 > patch2) return 1;
  if (patch1 < patch2) return -1;

  return 0; // tags are equal
}

export default function SceneSidebar({ sceneId }: { sceneId: string }) {
  const queryClient = useQueryClient();
  const isLoggedIn = useLoggedIn();
  const { data: ecomData } = useEcommerce();
  const { data: limitsData } = useUserLimits({ enabled: isLoggedIn });
  const { data: sceneDetails, refetch: refetchSceneDetails } = useCoreScene(sceneId);
  const { data: sceneActions, refetch: refetchActions } = useSceneVisualizerActions({ sceneId });
  const [isPrivateSharingModalOpen, setIsPrivateSharingModalOpen] = useState(false);
  const [isEmbedSceneModalOpen, setIsEmbedSceneModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isApproveSubscriptionModalOpen, setIsApproveSubscriptionModalOpen] = useState(false);
  const [areActionsDisabled, setAreActionsDisabled] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [downloadButtonSubtext, setDownloadButtonSubtext] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [topUpPopUpOpen, setTopUpPopUpOpen] = useState<boolean>(false);
  const [isFaceTrackingAvailable, setIsFaceTrackingAvailable] = useState<boolean>(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleDownloadAction = async (retryTime?: number) => {
    if (
      ecomData?.core.subscriptionTitle === 'free' &&
      limitsData?.downloadLimit &&
      limitsData?.usedDownloads === limitsData?.downloadLimit &&
      !sceneActions?.download.buttonText.toLowerCase().includes('download')
    ) {
      setIsUpgradeOpen(true);
      return;
    }
    if (
      sceneActions?.download.action === ErrorMessages.SUBSCRIPTION_IS_REQUIRED &&
      ecomData?.core.subscriptionTitle === 'free'
    ) {
      setIsDownloading(false);
      setDownloadButtonSubtext('');
      setIsUpgradeOpen(true);
      return;
    }
    if (sceneActions?.download.isActionAllowed) {
      try {
        setIsDownloading(true);
        setIsDownloadDisabled(true);
        setTimeout(() => {
          setIsDownloadDisabled(false);
        }, 5000);
        setDownloadButtonSubtext('We are preparing your FBX');
        const res: any = await get({ path: `${SERVICE_URL}/scan/${sceneId}/action/download` });
        if (res?.data?.file_status === 'suceess') {
          // Update download count
          const current = limitsData;
          queryClient.invalidateQueries([CORE_INTEGRATIONS_LIMITS_QUERY]);
          if (
            ecomData?.core.subscriptionTitle === 'free' &&
            sceneActions.download.buttonText === 'Export FBX' &&
            current
          ) {
            queryClient.setQueryData([CORE_INTEGRATIONS_LIMITS_QUERY], {
              ...current,
              usedDownloads: current.usedDownloads + 1,
            });
          }
          setDownloadButtonSubtext('Your FBX is ready!');
          const link = document.createElement('a');
          link.href = res.data.file_url;
          link.download = 'scene.fbx';
          link.click();
          setTimeout(() => {
            setDownloadButtonSubtext('');
          }, 5000);
          refetchActions();
          setIsDownloading(false);
        } else {
          setTimeout(() => {
            if ((retryTime || 0) < 20) {
              handleDownloadAction((retryTime || 0) + 1);
            }
          }, 3000);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (sceneActions?.download.action === 'approve_subscription') {
      setIsApproveSubscriptionModalOpen(true);
    }
  };

  async function handlePrivacyChange() {
    if (areActionsDisabled) return;
    setAreActionsDisabled(true);
    try {
      const response = await setPrivateShare(sceneId, !sceneActions?.sharing.state);
      await refetchActions();
    } catch (err) {
      console.log(err);
    }
    setIsPrivateSharingModalOpen(false);
    setAreActionsDisabled(false);
  }

  const footlock = urlSearchParams.get('fl') === 'on';

  async function handleAiUpdate() {
    if (sceneActions?.updateAi.isActionAllowed) {
      const ai = await updateAi(sceneId);
      if (ai?.status === 'success') {
        navigate(`/${DASHBOARD_PATH}/scene/${ai?.data?.id}`);
      } else {
        if (ai?.data.message === ErrorMessages.SUBSCRIPTION_IS_REQUIRED) {
          if (ecomData?.core.subscriptionTitle === 'free') {
            setIsUpgradeOpen(true);
          } else {
            setTopUpPopUpOpen(true);
          }
        }
      }
    }
  }

  async function handleAspectRatioChange() {
    if (sceneActions?.aspectRatio.isActionAllowed) {
      const ratio = await changeRatio(sceneId);
      if (ratio?.status === 'success') {
        navigate(`/${DASHBOARD_PATH}/scene/${ratio?.data?.id}`);
      } else {
        if (ratio?.data.message === ErrorMessages.ADDON_REQUIRED) {
          if (ecomData?.core.subscriptionTitle === 'free') {
            setIsUpgradeOpen(true);
          } else {
            setTopUpPopUpOpen(true);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (sceneDetails) {
      if (sceneDetails?.appliedAI && compareSemverTags(sceneDetails.appliedAI, 'v3.6.0') === -1) {
        setIsFaceTrackingAvailable(false);
      } else {
        setIsFaceTrackingAvailable(true);
      }
    }
  }, [sceneDetails]);

  return (
    <div className="w-full header:min-w-[300px] header:max-w-[28%] flex flex-col border border-bright-gray px-[30px] bg-white">
      {sceneDetails && sceneActions && (
        <>
          <section className="py-[20px] w-full">
            <div className="flex items-center gap-8 justify-between">
              {sceneActions && (
                <div className="flex w-full flex-col gap-[8px] items-center">
                  <button
                    onClick={() => handleDownloadAction()}
                    disabled={isDownloading || isDownloadDisabled}
                    className={`px-[10px] mx-auto rounded-[4px] cursor-pointer relative leading-[48px]
                    bg-konkikyo-blue hover:bg-blue-rose focus:bg-sambucus group md:block ${
                      !sceneActions.download.isActionAllowed && `bg-steam pointer-events-none `
                    }`}
                  >
                    <div className="flex items-center relative pl-[10px] pr-[18px]">
                      {isDownloading ? (
                        <div className="md:h-full animate-spin linear duration-1000 infinite">
                          <SpinIcon />
                        </div>
                      ) : (
                        <div
                          className={`${!sceneActions.download.isActionAllowed && `[&>svg>path]:!fill-taupe-gray `}`}
                        >
                          <DownloadIcon />
                        </div>
                      )}
                      <p
                        className={`font-semibold uppercase text-[11px]  ${
                          !sceneActions.download.isActionAllowed ? `!text-taupe-gray ` : 'text-white'
                        }`}
                      >
                        {sceneActions.download.buttonText}
                      </p>
                    </div>
                    {downloadButtonSubtext && (
                      <p className="absolute top-[calc(100%-10px)] left-[50%] translate-x-[-50%] text-xs min-w-[300px]">
                        {downloadButtonSubtext}
                      </p>
                    )}
                  </button>
                  {limitsData?.usedDownloads !== undefined &&
                    ecomData?.core.subscriptionTitle === 'free' &&
                    sceneActions?.isUserAuthor && (
                      <div className="mt-[16px] text-center">
                        <p>{`${limitsData.usedDownloads} of ${limitsData.downloadLimit} FBX exports used · Resets on ${limitsData.resetsAt}`}</p>
                        <div className="mt-[8px]">
                          <span
                            className="text-[13px] leading-[20px] font-[600] text-han-blue cursor-pointer"
                            onClick={() => setIsUpgradeOpen(true)}
                          >
                            Upgrade for unlimited exports
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </section>
          {sceneActions && (sceneActions.isUserAdmin || sceneActions?.isUserAuthor) && !sceneActions.isSceneFailed && (
            <section className="py-[20px] border-t relative border-bright-gray text-[#888888]">
              {areActionsDisabled && (
                <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 z-10">
                  <Loader />
                </div>
              )}
              <div className="flex justify-between items-center pt-[6px]">
                <p>Private sharing:</p>
                <div className="flex justify-center items-center gap-[15px]">
                  <p
                    className={`font-thin cursor-pointer group ${
                      sceneActions.sharing.state ? 'text-radical-blue' : 'text-taupe-gray'
                    } hover:text-radical-blue`}
                    onClick={() => sceneActions.sharing.isActionAllowed && handlePrivacyChange()}
                  >
                    <span className="hidden group-hover:inline-block mr-[4px] font-thin text-radical-blue">edit</span>
                    {sceneActions.sharing.state ? 'On' : 'Off'}
                  </p>
                  <div className="w-[30px] flex justify-center items-center">
                    {/* <img src={LockedIcon} className="mb-[2px]" /> */}
                    <img src={sceneActions.sharing.state ? OpenIcon : LockedIcon} className="mb-[2px]" />
                  </div>
                </div>
              </div>{' '}
              <div className="flex justify-between items-center pt-[6px]">
                <p>Embed iFrame:</p>
                <div className="flex justify-center items-center gap-[15px]">
                  <div
                    className={`w-[30px] flex justify-center items-center ${
                      sceneActions.sharing.state ? 'opacity-100' : 'opacity-50'
                    }`}
                    onClick={() => setIsEmbedSceneModalOpen(true)}
                    // onClick={() => sceneActions.sharing.state && setIsEmbedSceneModalOpen(true)}
                  >
                    <img src={EmbedIcon} className="mb-[2px]" />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center pt-[6px]">
                <p>Share URL:</p>
                <div className="flex justify-center items-center gap-[15px]">
                  <div
                    className={`w-[30px] flex justify-center items-center ${
                      sceneActions.sharing.state ? 'opacity-100' : 'opacity-50'
                    }`}
                    onClick={() => setIsShareModalOpen(true)}
                  >
                    <img src={ShareIcon} className="mb-[2px]" />
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="py-[20px] border-t border-bright-gray text-[#888888]">
            <div className="flex flex-wrap text-base py-[2px] mb-[2px]  font-normal justify-between">
              {' '}
              <p className="justify-center">Applied AI:</p>
              <span>{sceneDetails.appliedAI}</span>
            </div>
            <div className="flex flex-wrap text-base py-[5px] justify-between">
              <p className="justify-center">Current AI:</p>
              {sceneActions?.updateAi.isActionAllowed && (
                <button
                  className={`underline text-sm ${
                    sceneActions?.updateAi.isActionAllowed ? 'text-radical-blue cursor-pointer' : 'text-gray-400'
                  }`}
                  disabled={!sceneActions?.updateAi.isActionAllowed}
                  onClick={handleAiUpdate}
                >
                  update
                </button>
              )}
              <span>{sceneDetails.currentAI}</span>
            </div>
            {(sceneActions?.updateAi?.relatedScenes?.length || 0) > 0 && (
              <div className="flex flex-wrap text-base py-[5px] justify-between">
                <p className="justify-center">Related Scenes:</p>
                <div className="max-w-[50%] overflow-y-auto flex flex-wrap">
                  {sceneActions?.updateAi.relatedScenes.map((scan) => (
                    <span className="px-1">
                      <a href={`/${DASHBOARD_PATH}/scene/${scan}`}>{scan}</a>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </section>
          <section className="py-[20px] border-t border-bright-gray text-[#888888]">
            {/* ARKIT */}
            <div className="flex flex-wrap text-base py-[2px] mt-[4px] mb-[8px] font-normal justify-between">
              <div className="flex items-start content-start gap-[4px]">
                <FaceTrackingIcon />
                <p>Face animation:</p>
              </div>
              <div className={`group relative group-hover:block`}>
                {!isFaceTrackingAvailable ? (
                  <img src={WarningIcon} className="h-[16px] w-[16px]" />
                ) : (
                  <img src={CheckIcon} className="h-[16px] w-[16px]" />
                )}
                <div className="absolute right-0 bottom-0">
                  <div className="w-[350px] flex-wrap rounded-[2px] group-hover:block hidden bg-white text-center justify-center mr-[16px] mb-[16px] py-[20px] px-[20px] shadow-lg">
                    <p className="font-bold text-center text-davys-grey">ARKIT facial animation</p>
                    <p className="mt-[20px] text-center text-[#888888]">
                      Every Core scene from "Applied AI" version 3.6 (and up) includes face animation data. For best
                      results, ensure your face is visible to the camera at all times.
                    </p>
                    <Link className="text-bluecola text-center" to={'https://discord.gg/syXNpKVjBF'} target="_blank">
                      Learn more on RADiCAL's Discord.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* ARKIT */}
            <div className="flex flex-wrap text-base py-[2px] mb-[8px] font-normal justify-between">
              <p>Calibration:</p>
              <div className={`group relative group-hover:block`}>
                {sceneActions?.calibration.status ? (
                  <img src={WarningIcon} className="h-[16px] w-[16px]" />
                ) : (
                  <img src={CheckIcon} className="h-[16px] w-[16px]" />
                )}
                <div className="absolute right-0 bottom-0">
                  <div className="w-[350px] flex-wrap rounded-[2px] group-hover:block hidden bg-white text-justify-center mr-[16px] mb-[16px] py-[20px] px-[20px] shadow-lg">
                    <p className="font-bold text-center text-davys-grey">Warning: T-pose calibration</p>
                    <p className="mt-[20px] text-center text-[#888888]">
                      If this warning is visible, we couldn't detect a valid T-pose calibration. This may indicate your
                      scene is badly calibrated, which could severely impact the quality of your result throughout this
                      scene. Whether or not that is the case depends on a range of circumstances.{' '}
                      <Link
                        className="text-bluecola"
                        to={'https://radicalmotion.com/faq/recording-environment-people/calibration-5-second-rule'}
                        target="_blank"
                      >
                        Learn more here.
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {sceneActions?.inFrameRatio?.status && (
              <div className="flex flex-wrap text-base py-[2px] mb-[8px] font-normal justify-between">
                <p>In-frame ratio:</p>
                <div>{sceneActions?.inFrameRatio?.text}</div>
              </div>
            )}
          </section>{' '}
          {sceneActions && sceneActions.footlock.isActionAllowed && (
            <section className="py-[20px] border-t border-bright-gray text-[#888888]">
              <div className="flex flex-wrap text-base py-[2px] mb-[2px] font-normal justify-between">
                <p className="justify-center">
                  Footlock:<sup className="text-[#fd7d6a] uppercase text-[7px] font-thin pl-[2px]">beta</sup>
                </p>
                <span>
                  <div className="flex flex-row">
                    <p className="mr-[28px] text-[12px] font-thin">{sceneActions?.footlock.state}</p>
                    <div className="group relative group-hover:block">
                      {sceneActions?.footlock.isActionAllowed ? (
                        <img src={CheckIcon} className="h-[16px] w-[16px]" />
                      ) : (
                        <img src={DisableIcon} className="h-[16px] w-[16px]" />
                      )}
                      <div className="absolute right-0 bottom-0">
                        <div className="w-[450px] flex-wrap rounded-[2px] group-hover:block hidden bg-white text-justify-center  mr-[16px] mb-[16px] py-[20px] px-[20px] shadow-lg">
                          <h6 className="font-bold text-center text-davys-grey">
                            Footlock experimental solving for better floor contact
                          </h6>
                          <div className="mt-[20px] text-[#888888]">
                            <p className="mb-[10px] text-center">
                              For scenes created after December 8, 2020, you can choose to view your results with a
                              "footlock" solver enabled. The solver is designed to ensure a more stable and realistic
                              contact between the character's feet and the floor. To see the solver in action, click the
                              "Switch" button bellow (opens a separate tab).
                            </p>
                            <p className="mb-[10px] text-center">
                              The FBX you download will only have the footlock solver enabled if you download it from
                              within a scene profile in the browser window that indicates Current view: On.
                            </p>
                            <p className="mb-[10px] text-center">
                              This footlock solution is experimental and we're continually improving it. To understand
                              how best to use it, where it might do well, or fail, please
                              <Link className="text-bluecola" to={''}>
                                consult our Learn
                              </Link>{' '}
                              section.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="flex flex-wrap text-base py-[5px] justify-between">
                <p className="justify-center">Current view:</p>
                <button
                  className={`underline text-sm ${
                    sceneActions?.footlock.isActionAllowed ? 'text-radical-blue cursor-pointer' : 'text-gray-400'
                  }`}
                  disabled={!sceneActions?.footlock.isActionAllowed}
                  onClick={() => setSearchParams({ fl: footlock ? 'off' : 'on' })}
                >
                  switch
                </button>
                <div className="flex flex-row justify-items-end">
                  <span className="pr-[10px] font-thin mr-[20px]">{footlock ? 'On' : 'Off'}</span>
                  <span>
                    {footlock ? (
                      <img src={CheckIcon} className="h-[16px] w-[16px]" />
                    ) : (
                      <img src={DisableIcon} className="h-[16px] w-[16px] opacity-30" />
                    )}
                  </span>
                </div>
              </div>
            </section>
          )}
          <section className="py-[20px] border-t border-bright-gray text-[#888888]">
            <div className="flex flex-wrap text-base py-[2px] mb-[2px] font-normal justify-between">
              <p className="justify-center">Aspect ratio:</p>
              <div className="flex flex-row justify-items-end">
                <span>
                  <div className="flex flex-row">
                    <div className="group relative group-hover:block">
                      {sceneActions?.aspectRatio.ratio.toLowerCase() === 'wide' ? (
                        <img src={WarningIcon} className="h-[16px] w-[16px]" />
                      ) : (
                        <img src={CheckIcon} className="h-[16px] w-[16px]" />
                      )}
                      <div className="absolute right-0 bottom-0">
                        <div className="w-[450px] flex-wrap rounded-[2px] group-hover:block hidden bg-white text-justify-center  mr-[16px] mb-[16px] py-[20px] px-[20px] shadow-lg">
                          <h6 className="font-bold text-center text-davys-grey">Original Aspect Ratio</h6>
                          <div className="mt-[20px]">
                            <p className="mb-[10px] text-center text-[#888888]">
                              If your video has the recommended aspect ratio of 4:3, all is well. However, you will see
                              a warning here if your video is in widescreen format - in that case, you should check
                              whether optimization is turned On or Off.
                            </p>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="flex flex-wrap text-base py-[5px] justify-between">
              <p className="justify-center">Optimization:</p>
              <button
                className={`underline text-sm mr-[40px] ${
                  sceneActions?.aspectRatio.isActionAllowed ? 'text-radical-blue cursor-pointer' : 'text-gray-400 '
                }`}
                disabled={!sceneActions?.aspectRatio.isActionAllowed}
                onClick={handleAspectRatioChange}
              >
                {sceneActions?.aspectRatio.optimizationText}
              </button>
              <span>
                {sceneActions?.aspectRatio.isActionAllowed ? (
                  <img src={CheckIcon} className="h-[16px] w-[16px]" />
                ) : (
                  <img src={ExclamationIcon} className="h-[16px] w-[16px]" />
                )}
              </span>
            </div>
          </section>
          <section className="text-base relative py-[10px] border-t border-bright-gray h-full">
            {loader && (
              <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 z-10">
                <Loader />
              </div>
            )}
            <Formik
              initialValues={{
                description: sceneDetails.description,
              }}
              onSubmit={async function (
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): Promise<any> {
                if (sceneDetails.description !== values.description) {
                  await changeDescription(sceneId, values.description)
                    .then(() => {
                      refetchSceneDetails();
                      setIsEditingDescription(false);
                    })
                    .catch((error) => {
                      formikHelpers.setErrors({ description: error.message });
                    });
                }
              }}
            >
              {({ values, submitForm }) => (
                <Form className="h-full">
                  <div className="flex justify-between items-center py-2">
                    <p>Notes</p>
                    {isEditingDescription ? (
                      <button
                        type="button"
                        onClick={async () => {
                          setLoader(true);
                          await submitForm();
                          setIsEditingDescription(false);
                          setLoader(false);
                        }}
                        className="underline text-radical-blue text-sm"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled={
                          (!sceneActions?.isUserAdmin && !sceneActions?.isUserAuthor) || sceneActions?.isSceneFailed
                        }
                        className={`underline text-sm ${
                          (sceneActions?.isUserAdmin && sceneActions?.isUserAuthor) || !sceneActions?.isSceneFailed
                            ? 'text-radical-blue cursor-pointer'
                            : 'text-gray-400'
                        }`}
                        onClick={() => setIsEditingDescription(true)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                  <Textarea
                    name="description"
                    disabled={!isEditingDescription}
                    customStyle={`!bg-white  overflow-y-auto ${
                      isEditingDescription
                        ? '!border-cyan-blue-azure !h-[60px] !border'
                        : '!px-0 max-h-[130px] min-h-fit !h-[90px]'
                    }`}
                    maxLength={280}
                  />
                  {isEditingDescription && <p>{`${280 - values.description.length} characters remaining`}</p>}
                </Form>
              )}
            </Formik>
          </section>
          {sceneActions && (
            <>
              <PrivateSharingModal
                isSharingEnabled={sceneActions.sharing.state}
                isOpen={isPrivateSharingModalOpen}
                url={`${window.location.origin.toString()}/dashboard/scene/${sceneId}`}
                setIsOpen={setIsPrivateSharingModalOpen}
                onConfirm={handlePrivacyChange}
                onCancel={() => setIsPrivateSharingModalOpen(false)}
              />
              <EmbedSceneModal
                isOpen={isEmbedSceneModalOpen}
                url={sceneDetails.url}
                setIsOpen={() => setIsEmbedSceneModalOpen(!isEmbedSceneModalOpen)}
              />{' '}
              <ShareSceneModal
                isOpen={isShareModalOpen}
                url={sceneDetails.url}
                contentText={encodeURIComponent(`${sceneDetails.projectTitle}: ${sceneDetails.title}`)}
                descriptionText={encodeURIComponent(sceneDetails.description)}
                setIsOpen={() => setIsShareModalOpen(!isShareModalOpen)}
              />
            </>
          )}
          <Modal
            isOpen={isApproveSubscriptionModalOpen}
            setIsOpen={() => setIsApproveSubscriptionModalOpen(false)}
            customStyles="px-0"
          >
            <div className="max-w-[320px]">
              <div className="flex justify-center items-center gap-[10px] pb-[20px] px-[20px]">
                <p className="font-medium">Just making sure...</p>
                <img src={rocketIcon} className="w-[21px]" />
              </div>
              <hr />
              <div className="px-[20px] pt-[20px] flex flex-col justify-center items-center">
                <p className="font-semibold text-[12px] text-center mb-[35px] tracking-wide">
                  This action requires an active subscription.
                </p>
                <SolidButton
                  title="Pricing Page"
                  customStyles="px-[30px] py-[6px] mt-[35px] outline-0 border-none"
                  onClick={() => navigate('/pricing')}
                />
                <p
                  className="text-[11px] text-philippine-silver font-semibold p-1 mt-[10px] cursor-pointer"
                  onClick={() => setIsApproveSubscriptionModalOpen(false)}
                >
                  Cancel
                </p>
              </div>
            </div>
          </Modal>
          <UpgradePopUp title="Want to download FBX data?" openModal={isUpgradeOpen} openModalFc={setIsUpgradeOpen} />
          <TopUpPopUp openModal={topUpPopUpOpen} openModalFc={setTopUpPopUpOpen} />
        </>
      )}
    </div>
  );
}
