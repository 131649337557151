import { GLSL3 } from "three";
import { colorspace_fragment, common, dithering_fragment, fog_fragment, opaque_fragment, premultiplied_alpha_fragment, tonemapping_fragment } from "./fragments";

export function updateShader(shader: any, uniforms: any) {
    // Set the GLSL version to 3.00 es (WebGL2 compatible)
    shader.glslVersion = GLSL3;

    // Merge existing uniforms with new uniforms passed as an argument
    shader.uniforms = {
        ...shader.uniforms,
        ...uniforms,
    };

    // Replace the common fragment shader code in the main shader with the provided common fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, common );
    
    // Replace the opaque_fragment code in the main shader with the provided opaque_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <opaque_fragment>`, opaque_fragment);
    
    // Replace the tonemapping_fragment code in the main shader with the provided tonemapping_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <tonemapping_fragment>`, tonemapping_fragment);
    
    // Replace the colorspace_fragment code in the main shader with the provided colorspace_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <colorspace_fragment>`, colorspace_fragment);
    
    // Replace the fog_fragment code in the main shader with the provided fog_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <fog_fragment>`, fog_fragment);
    
    // Replace the premultiplied_alpha_fragment code in the main shader with the provided premultiplied_alpha_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <premultiplied_alpha_fragment>`, premultiplied_alpha_fragment);
    
    // Replace the dithering_fragment code in the main shader with the provided dithering_fragment shader
    shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, dithering_fragment);

    return shader;
}
