import React, { ComponentProps, useState } from 'react';
import { Icon, ImageName } from '../../../icons';
import { classNames } from '../../../utils';

type Props = {
  type?: string;
  icon?: ImageName;
  title?: string;
  caption?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  action?: React.ReactNode;
  focusRing?: boolean;
  disabled?: boolean;
  error?: string;
  onFocusOut?: () => void;
  className?: string;
  optionModal?: boolean;
};

export default function InputFieldWithActions({
  type = 'text',
  value,
  onChange,
  icon,
  title,
  caption,
  placeholder = '',
  action,
  focusRing,
  disabled,
  error,
  onFocusOut,
  className = '',
  optionModal,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={classNames('flex flex-col w-full', className)}>
      {title && <div className={classNames('input-title', `flex text-[13px] text-[#555]`)}> {title}</div>}
      <div
        className={`flex rounded-[4px] pl-[6px] items-center bg-anti-flash-white active:bg-chinese-white focus:outline-none  ${
          !action ? 'py-[8px]' : undefined
        } ${isFocused ? 'ring-1 focus:ring-cornflower-blue' : ''}
        ${error ? 'ring-1 ring-fire-engine-red' : ''}
        `}
      >
        {icon && <Icon image={icon} />}
        <input
          className={`w-full pl-[8px] font-demi text-[13px] bg-transparent text-[#555] placeholder:text-quick-silver focus:outline-none`}
          placeholder={placeholder}
          required={true}
          value={value}
          disabled={disabled}
          type={type}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => {
            if (focusRing) {
              setIsFocused(true);
            }
          }}
          onBlur={() => {
            onFocusOut && onFocusOut();
            setIsFocused(false);
          }}
        />
        {action}
      </div>
      {error && <div className="flex text-[13px] font-avenir text-fire-engine-red"> {error} </div>}
      {caption && <div className="flex text-[12px] text-[#555]"> {caption} </div>}
    </div>
  );
}
