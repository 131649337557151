import { ReactNode } from 'react';

export const Caption = ({
  children,
  bold,
  underline,
  italic,
  uppercase,
  xs,
  className,
}: {
  children?: ReactNode;
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  uppercase?: boolean;
  xs?: boolean;
  className?: string;
}) => {
  return (
    <span
      className={`${xs ? 'text-[9px]' : 'text-[13px]'} 
      ${bold ? 'font-semibold' : 'font-normal'} leading-[12px] tracking-[0.461538px] ${underline && 'underline'} 
      ${italic && 'italic'} ${uppercase && 'uppercase'}
      ${className && className}`}
    >
      {children}
    </span>
  );
};
