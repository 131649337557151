import { Camera, GLSL3 } from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

// Define a new class that extends TransformControls to include shader support for WebGLMultipleRenderTargets
export class RadicalTransformControls extends TransformControls {
  constructor(camera: Camera, domElement: HTMLElement) {
    // Call the parent class constructor
    super(camera, domElement);

    // Traverse through all children of this control and update their materials
    // to include support for Multiple Render Targets and shader modifications for bloom effect
    this.traverse((child: any) => {
      // Check if the child has a material property
      if (child.material) {
        // Modify the shader right before it compiles
        child.material.onBeforeCompile = (shader: any) => {
          // Update the shader with custom modifications
          shader = this.updateShader(shader);
        };
      }
    });
  }

  // Method to update the shader code
  private updateShader(shader: any) {
    // Set the GLSL version to GLSL3 for compatibility with WebGLMultipleRenderTargets
    shader.glslVersion = GLSL3;

    // Update the fragment shader to define custom output locations for bloom, base color, and depth
    // This is necessary for using multiple render targets
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
            layout(location = 0) out vec4 base_FragColor;
            layout(location = 1) out vec4 bloom_FragColor;
            layout(location = 2) out vec4 depth_FragColor;
            layout(location = 3) out vec4 id_FragColor;
            `
    );

    // Modify how the final fragment color is calculated by considering bloom effect
    // and adjusting the output for different render targets
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
                diffuseColor.a = 1.0;
            #endif
            
            #ifdef USE_TRANSMISSION
                diffuseColor.a *= material.transmissionAlpha;
            #endif
            
            bloom_FragColor = vec4( 0.0, 0.0, 0.0, diffuseColor.a );
            base_FragColor = vec4( outgoingLight, diffuseColor.a );
            depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
            id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
          `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
                bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
                base_FragColor.rgb = toneMapping( base_FragColor.rgb );
            #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
            base_FragColor = linearToOutputTexel( base_FragColor );
          `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
                #ifdef FOG_EXP2
                    float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
                #else
                    float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
                #endif
                bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
                base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
            #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
                // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
                bloom_FragColor.rgb *= bloom_FragColor.a;
                base_FragColor.rgb *= base_FragColor.a;
            #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
                bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
                base_FragColor.rgb = dithering( base_FragColor.rgb );
            #endif`
    );

    return shader;
  }
}
