import { useEffect, useState } from 'react';
import { P, classNames } from '@radical/ui';
import add from './assets/add.svg';

type Props = {
  isSelected?: boolean;
  name: string;
  uuid: string;
  iconUrl?: string;
  thumbnailUrl?: string;
  onChange: (value: string) => void;
  onButtonClick?: () => void;
};

export default function HdrCard({ isSelected, name, iconUrl, thumbnailUrl, uuid, onChange, onButtonClick }: Props) {
  const [isLoaded, setLoaded] = useState(false);

  return (
    <div
      className={`HdrCard w-full h-[110px] rounded-[8px] bg-anti-flash-white flex items-center justify-center cursor-pointer relative z-[1] overflow-hidden group ${
        isSelected ? `border-[3px] border-blasphemous-blue` : 'py-[1px]'
      }`}
      onClick={() => onChange(uuid)}
    >
      <div className="flex h-full flex-col justify-end items-center gap-[16px] pt-[16px] pb-[12px]">
        <img src={iconUrl} className={`z-[1] transition duration-300 ${!isSelected ? 'group-hover:opacity-50' : ''}`} />
        <P className={`z-[1] text-lotion ${isSelected ? 'text-lotion' : 'text-gray-400'}`}>{name}</P>
      </div>
      <img
        src={thumbnailUrl}
        onLoad={() => setLoaded(true)}
        loading="lazy"
        className={classNames(
          `absolute w-full h-full inset-0 z-[0] rounded-[8px] transition duration-300`,
          isSelected ? 'border-[3px] border-white rounded-[8px]' : 'group-hover:scale-[120%]',
          isLoaded ? 'opacity-100' : 'opacity-0'
        )}
      />
      <button
        onClick={onButtonClick}
        className={`absolute top-[4px] right-[4px] w-[24px] h-[24px] items-center justify-center bg-lotion rounded-full transition-all duration-150 opacity-0 ${
          !isSelected ? 'group-hover:opacity-100' : ''
        }
        ${onButtonClick ? 'flex' : 'hidden'}`}
      >
        <img src={add} />
      </button>
    </div>
  );
}
