import { Armature, SkinMTX } from '../armature/index';
import Clip from '../animation/Clip';

import BoneViewMesh from './BoneViewMesh';
import SkinMTXMaterial from './SkinMTXMaterial';
import { UtilGltf2 } from './UtilGltf2';

class UtilArm {
  static newBoneView(arm: any, pose?: any, meshScl?: any, dirScl?: any) {
    const boneView = new BoneViewMesh(arm);

    // Because of the transform on the Armature itself, need to scale up the bones
    // to offset the massive scale down of the model
    // @ts-ignore
    if (meshScl) boneView.material.uniforms.meshScl.value = meshScl;
    // @ts-ignore
    if (dirScl) boneView.material.uniforms.dirScl.value = dirScl;

    // Set Initial Data So it Renders
    boneView.updateFromPose(pose || arm); // arm.newPose().updateWorld( true )

    return boneView;
  }

  static skinMtxMesh(gltf: any, arm: any, base: any = 'cyan', meshName: any = null) {
    const mat = SkinMTXMaterial(base, arm.getSkinOffsets()[0]); // 3JS Example of Matrix Skinning GLSL Code
    return UtilGltf2.loadMesh(gltf, meshName, mat); // Pull Skinned Mesh from GLTF
  }

  static clipFromGltf(gltf: any) {
    return Clip.fromGLTF2(gltf.getAnimation());
  }

  static armFromGltf(gltf: any, defaultBoneLen = 0.07) {
    const skin = gltf.getSkin();
    const arm = new Armature();
    let isRadical = false;

    // Create Armature
    for (let j of skin.joints) {
      //   console.log("Joint: ", j);
      isRadical = !(j.parentIndex >= 0);
      const index = isRadical ? skin.joints.indexOf(j.parent) : j.parentIndex;
      //   console.log("adding index: ", index);
      arm.addBone(
        j.name,
        index, // || skin.joints.indexOf(j),
        isRadical ? j.quaternion.toArray() : j.rotation,
        isRadical ? j.position.toArray() : j.position,
        isRadical ? j.scale.toArray() : j.scale
      );
    }

    // Bind
    arm.bind(SkinMTX, defaultBoneLen);

    // Save Offsets if available
    !isRadical
      ? arm.offset.set(skin.rotation, skin.position, skin.scale)
      : arm.offset.set(skin.quaternion.toArray(), skin.position.toArray(), skin.scale.toArray());
    //if( skin.rotation ) arm.offset.rot.copy( skin.rotation );
    //if( skin.position ) arm.offset.pos.copy( skin.position );
    //if( skin.scale )    arm.offset.scl.copy( skin.scale );

    return arm;
  }
}

export default UtilArm;
