import { Link, Navigate, useNavigate } from 'react-router-dom';
import { catchIcon, IDropDownItem } from '../DropdownMenu/DropdownList';
import { CanvasIcon, CoreIcon, ExternalIcon } from '../Icons';
import { shouldOpenInNewTab } from '@routes/landing/layouts/main/components/LandingHeader';
interface IMobileDropdownListProps {
  setIsOpenMenu: (value: boolean) => void;
  setIsSidebarOpened: (value: boolean) => void;
  isFullScreen?: boolean;
  list?: IDropDownItem[];
}

export default function DropdownList({
  setIsOpenMenu,
  setIsSidebarOpened,
  isFullScreen,
  list,
}: IMobileDropdownListProps) {
  const itemsList = list ?? [
    {
      title: 'Unreal Engine',
      link: '/unreal',
    },
    {
      title: 'Autodesk Maya',
      link: '/maya',
    },
    {
      title: 'Unity',
      link: '/unity',
    },
    {
      title: 'Blender',
      link: '/blender',
    },
  ];

  const navigate = useNavigate();

  const handleItemClick = () => {
    setIsOpenMenu(false);
    setIsSidebarOpened(false);
  };

  function scrollTutorials() {
    document.getElementById('tutorials')?.scrollIntoView({ behavior: 'smooth' });
    setIsOpenMenu(false);
    setIsSidebarOpened(false);
  }

  function scrollFAQ() {
    navigate('/');
    setTimeout(() => {
      document.getElementById('faq')?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
    setIsOpenMenu(false);
    setIsSidebarOpened(false);
  }

  function scrollTouch() {
    setTimeout(() => {
      document.getElementById('touch')?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
    setIsOpenMenu(false);
    setIsSidebarOpened(false);
  }

  const handleClick = (link: string) => {
    switch (link) {
      case '#faq':
        scrollFAQ();
        break;
      case '#tutorials':
        scrollTutorials();
        break;
      case '#touch':
        scrollTouch();
        break;
      default:
        console.log('none');
    }
  };

  return (
    <>
      <div>
        {!!itemsList &&
          itemsList.map(({ Icon, link, title }) => {
            if (link === '#faq' || link === '#tutorials' || link === '#touch') {
              return (
                <button
                  className={`flex flex-1 start gap-10 w-full py-[2px] self-stretch cursor-pointer`}
                  onClick={() => handleClick(link)}
                  key={title}
                >
                  <p
                    className={`font-demi flex items-center gap-[10px] relative text-[12px] px-[20px] py-[5px] text-[#555] hover:text-black 
                  ${isFullScreen && 'my-2'}`}
                  >
                    {catchIcon(link) ? <img src={catchIcon(link)} /> : null}
                    {Icon}
                    {title}
                  </p>
                </button>
              );
            } else {
              return (
                <Link
                  to={link}
                  target={shouldOpenInNewTab(link) ? '_blank' : '_self'}
                  className={`flex flex-1 start gap-10 w-full py-[2px] self-stretch cursor-pointer`}
                  onClick={() => handleItemClick()}
                  key={title}
                >
                  <p
                    className={`font-demi flex items-center gap-[8px] relative text-[12px] px-[20px] py-[5px] text-[#555] hover:text-black 
                      ${isFullScreen && 'my-2'}`}
                  >
                    {link === '/motion' && <CoreIcon />}
                    {link === '/canvas' && <CanvasIcon />}
                    {catchIcon(link) ? <img src={catchIcon(link)} /> : null}
                    {title}
                    {shouldOpenInNewTab(link) && <ExternalIcon />}
                  </p>
                </Link>
              );
            }
          })}
      </div>
    </>
  );
}
