import { useDrag, useDragDropManager } from 'react-dnd';
import './SceneLightsBoard.css';
import lightbulb from './assets/lightbulb.svg';
import spotlight from './assets/spotlght.svg';
import { FC, useEffect, useState } from 'react';
import { useSceneAssets } from '../../scene-assets.state';
import { DragAsset, LightTypeFE } from '@radical/canvas-fe-types';
import { LightType } from '@radical/canvas-types';

export const lights: LightTypeFE[] = [
  {
    icon: lightbulb,
    name: 'Lightbulb',
    description: 'Omnidirectional',
    type: LightType.POINT_LIGHT,
  },
  {
    icon: spotlight,
    name: 'Spotlight',
    description: 'Directional',
    type: LightType.SPOT_LIGHT,
  },
  // {
  //   icon: lightbulb,
  //   name: 'Ambient Light',
  //   description: 'Ambient',
  //   type: LightType.AMBIENT_LIGHT,
  // },
  // {
  //   icon: spotlight,
  //   name: 'Directional Light',
  //   description: 'Directional',
  //   type: LightType.DIRECTIONAL_LIGHT,
  // },
];

export const SceneLightBoard = () => {
  const dragState = useSceneAssets().drag;
  const { setDragging, setPosition } = useSceneAssets();

  return (
    <div className="SceneLightBoard h-full w-full flex justify-evenly items-center gap-[16px] pointer-events-auto flex-wrap ">
      {lights.map((light) => (
        <LightBoxWithImage light={light} setDragging={setDragging} setPosition={setPosition} key={light.name} />
      ))}
    </div>
  );
};

const LightBoxWithImage: FC<{
  light: LightTypeFE;
  setPosition: (x: number, y: number) => void;
  setDragging: (da: DragAsset) => void;
}> = ({
  light,
  setDragging,
  setPosition,
}: {
  light: LightTypeFE;
  setPosition: (x: number, y: number) => void;
  setDragging: (da: DragAsset) => void;
}) => {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const [dragg, setDragg] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'light',
    // item: { type: 'camera' },
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging();
      document.body.style.cursor = isDragging ? 'grabbing' : 'default';
      return { isDragging };
    },
    item: () => {
      setDragg(true);
      return { type: 'light' };
    },
    end: () => {
      setDragg(false);
    },
  }));

  useEffect(() => {
    monitor.subscribeToOffsetChange(() => {
      // do stuff like setState, though consider directly updating style through refs for performance
      const offset = monitor.getClientOffset();
      // const distance = monitor.getDifferenceFromInitialOffset();
      if (offset) setPosition(offset.x, offset.y);
      // if (distance) setDistance({ x: distance.x, y: distance.y });
    });
  }, [monitor]);

  useEffect(() => {
    if (dragg) {
      // console.log('Started asset drag');
      setDragging({ dragging: true, light: light });
      // Analytics.event('CANVAS/asset_add', { name: a.asset.name });
    } else {
      // console.log('stopped dragggg');
      setDragging({ dragging: false, light: undefined });
    }
  }, [dragg]);

  return (
    <div key={light.name} ref={drag}>
      <div
        className={`light-container w-[110px] h-[110px] flex flex-col justify-center items-center 
        pointer-events-auto ${
          isDragging ? 'cursor-grabbing' : 'cursor-grab'
        } bg-[#f2f2f2] hover:bg-lotion p-[4px] rounded-[8px] overflow-hidden shrink-0`}
      >
        <img src={light.icon} alt={light.name} className="my-[8px]" />
        <p className="text-[13px] text-[#555] font-semibold">{light.name}</p>
        <p className="text-[10px] text-[#A4A4A4] font-semibold mt-[10px]">{light.description}</p>
      </div>
    </div>
  );
};
