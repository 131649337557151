import {
  AnimationMixer,
  Bone,
  Box3,
  BoxGeometry,
  BufferGeometry,
  Camera,
  Color,
  GLSL3,
  Group,
  HSL,
  Line,
  LineBasicMaterial,
  LineSegments,
  Material,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PlaneGeometry,
  Quaternion,
  Raycaster,
  Scene,
  ShaderMaterial,
  Skeleton,
  SkeletonHelper,
  SkinnedMesh,
  Sphere,
  SphereGeometry,
  Texture,
  Vector3,
  EdgesGeometry,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';

import { generateQuaternionOffsets, generateStaticQuaternionOffsets } from './live-offsets';
import { CREATE_MOTION_HISTOGRAM, CREATE_ROOT_TRAJECTORY } from '@radical/constants-editor';
import {
  AnimationChangeValue,
  AnimationCoreData,
  AnimationFaceData,
  AnimationObjectInfo,
  CharacterModelInfo,
  CharacterModelStorage,
  FileTypes,
  IRadicalCharacter,
  ObjectType,
  RigTypes,
  RigFlavours,
  FeatureState,
} from '@radical/canvas-types';
import { BonesHelper, renameBones } from '@radical/radical-bones-helper';
import { Text3D } from '@radical/radical-text3d';

import Analytics from '@radical/analytics-frontend';

import { BoneNames } from './bone-names';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { CharacterType } from '@radical/canvas-fe-types';

export interface CharacterInternals {
  model: any;
  bones: Bone[];
  targetBone: Bone | undefined;
  skinnedMesh: SkinnedMesh | undefined;
  skinnedMeshes: Array<SkinnedMesh>;
  action: any;
  hitters: any[];
  boneHelper: BonesHelper;
  internalScale: Vector3;
  maxBox: Box3;
  // skeleton: SkeletonHelper | undefined;
}

export interface CharacterLoaded {
  id: string;
  name: string;
  character: RadicalCharacterLegacy | undefined;
  video: any;
  loaded: boolean;
  tracks: boolean;
  mesh: Object3D | undefined;
  loading: boolean;
  original: boolean;
  position: Vector3;
  copies: CharacterLoaded[];
  animationsToAdd: StoredAnimation[];
  animationsFaceToAdd?: StoredFaceAnimation[];
}

export interface LoadedCallback {
  (): void;
}

type BoneReferenceRotation = {
  bone: Bone;
  quat: Quaternion;
  quatOriginal?: Quaternion;
  bones: Bone[];
};

export type BoneReferencesType = {
  root_r: BoneReferenceRotation;
  Spine_r: BoneReferenceRotation;
  Spine1_r: BoneReferenceRotation;
  Spine2_r: BoneReferenceRotation;
  Head_r: BoneReferenceRotation;
  LeftArm_r: BoneReferenceRotation;
  LeftForeArm_r: BoneReferenceRotation;
  LeftLeg_r: BoneReferenceRotation;
  LeftFoot_r: BoneReferenceRotation;
  LeftShoulder_r: BoneReferenceRotation;
  LeftUpLeg_r: BoneReferenceRotation;
  RightArm_r: BoneReferenceRotation;
  RightForeArm_r: BoneReferenceRotation;
  RightLeg_r: BoneReferenceRotation;
  RightFoot_r: BoneReferenceRotation;
  RightShoulder_r: BoneReferenceRotation;
  RightUpLeg_r: BoneReferenceRotation;
  Neck_r: BoneReferenceRotation;
  LeftHand_r: BoneReferenceRotation; // hands are left out
  RightHand_r: BoneReferenceRotation;
};

export type BoneReferencesDevType = {
  root_r: BoneReferenceRotation;
  Spine_r: BoneReferenceRotation;
  Spine1_r: BoneReferenceRotation;
  Spine2_r: BoneReferenceRotation;
  Head_r: BoneReferenceRotation;
  LeftArm_r: BoneReferenceRotation;
  LeftForeArm_r: BoneReferenceRotation;
  LeftLeg_r: BoneReferenceRotation;
  LeftFoot_r: BoneReferenceRotation;
  LeftShoulder_r: BoneReferenceRotation;
  LeftUpLeg_r: BoneReferenceRotation;
  RightArm_r: BoneReferenceRotation;
  RightForeArm_r: BoneReferenceRotation;
  RightLeg_r: BoneReferenceRotation;
  RightFoot_r: BoneReferenceRotation;
  RightShoulder_r: BoneReferenceRotation;
  RightUpLeg_r: BoneReferenceRotation;
  Neck_r: BoneReferenceRotation;
  LeftHand_r: BoneReferenceRotation; // hands are left out
  RightHand_r: BoneReferenceRotation;
  LeftHandThumb1_r: BoneReferenceRotation;
  LeftHandThumb2_r: BoneReferenceRotation;
  LeftHandThumb3_r: BoneReferenceRotation;
  // LeftHandThumb4_r: BoneReferenceRotation,
  LeftHandIndex1_r: BoneReferenceRotation;
  LeftHandIndex2_r: BoneReferenceRotation;
  LeftHandIndex3_r: BoneReferenceRotation;
  //LeftHandIndex4_r: BoneReferenceRotation,
  LeftHandMiddle1_r: BoneReferenceRotation;
  LeftHandMiddle2_r: BoneReferenceRotation;
  LeftHandMiddle3_r: BoneReferenceRotation;
  //LeftHandMiddle4_r: BoneReferenceRotation,
  LeftHandRing1_r: BoneReferenceRotation;
  LeftHandRing2_r: BoneReferenceRotation;
  LeftHandRing3_r: BoneReferenceRotation;
  //LeftHandRing4_r: BoneReferenceRotation,
  LeftHandPinky1_r: BoneReferenceRotation;
  LeftHandPinky2_r: BoneReferenceRotation;
  LeftHandPinky3_r: BoneReferenceRotation;
  //LeftHandPinky4_r: BoneReferenceRotation,

  RightHandThumb1_r: BoneReferenceRotation;
  RightHandThumb2_r: BoneReferenceRotation;
  RightHandThumb3_r: BoneReferenceRotation;
  // LeftHandThumb4_r: BoneReferenceRotation,
  RightHandIndex1_r: BoneReferenceRotation;
  RightHandIndex2_r: BoneReferenceRotation;
  RightHandIndex3_r: BoneReferenceRotation;
  //RightHandIndex4_r: BoneReferenceRotation,
  RightHandMiddle1_r: BoneReferenceRotation;
  RightHandMiddle2_r: BoneReferenceRotation;
  RightHandMiddle3_r: BoneReferenceRotation;
  //RightHandMiddle4_r: BoneReferenceRotation,
  RightHandRing1_r: BoneReferenceRotation;
  RightHandRing2_r: BoneReferenceRotation;
  RightHandRing3_r: BoneReferenceRotation;
  //RightHandRing4_r: BoneReferenceRotation,
  RightHandPinky1_r: BoneReferenceRotation;
  RightHandPinky2_r: BoneReferenceRotation;
  RightHandPinky3_r: BoneReferenceRotation;
  //RightHandPinky4_r: BoneReferenceRotation,
};

export type StaticBoneReferencesType = {
  // LeftHand_r: BoneReferenceRotation; // hands are left out
  // RightHand_r: BoneReferenceRotation;
  LeftHandThumb1_r: BoneReferenceRotation;
  LeftHandThumb2_r: BoneReferenceRotation;
  LeftHandThumb3_r: BoneReferenceRotation;
  // LeftHandThumb4_r: BoneReferenceRotation,
  LeftHandIndex1_r: BoneReferenceRotation;
  LeftHandIndex2_r: BoneReferenceRotation;
  LeftHandIndex3_r: BoneReferenceRotation;
  //LeftHandIndex4_r: BoneReferenceRotation,
  LeftHandMiddle1_r: BoneReferenceRotation;
  LeftHandMiddle2_r: BoneReferenceRotation;
  LeftHandMiddle3_r: BoneReferenceRotation;
  //LeftHandMiddle4_r: BoneReferenceRotation,
  LeftHandRing1_r: BoneReferenceRotation;
  LeftHandRing2_r: BoneReferenceRotation;
  LeftHandRing3_r: BoneReferenceRotation;
  //LeftHandRing4_r: BoneReferenceRotation,
  LeftHandPinky1_r: BoneReferenceRotation;
  LeftHandPinky2_r: BoneReferenceRotation;
  LeftHandPinky3_r: BoneReferenceRotation;
  //LeftHandPinky4_r: BoneReferenceRotation,

  RightHandThumb1_r: BoneReferenceRotation;
  RightHandThumb2_r: BoneReferenceRotation;
  RightHandThumb3_r: BoneReferenceRotation;
  // LeftHandThumb4_r: BoneReferenceRotation,
  RightHandIndex1_r: BoneReferenceRotation;
  RightHandIndex2_r: BoneReferenceRotation;
  RightHandIndex3_r: BoneReferenceRotation;
  //RightHandIndex4_r: BoneReferenceRotation,
  RightHandMiddle1_r: BoneReferenceRotation;
  RightHandMiddle2_r: BoneReferenceRotation;
  RightHandMiddle3_r: BoneReferenceRotation;
  //RightHandMiddle4_r: BoneReferenceRotation,
  RightHandRing1_r: BoneReferenceRotation;
  RightHandRing2_r: BoneReferenceRotation;
  RightHandRing3_r: BoneReferenceRotation;
  //RightHandRing4_r: BoneReferenceRotation,
  RightHandPinky1_r: BoneReferenceRotation;
  RightHandPinky2_r: BoneReferenceRotation;
  RightHandPinky3_r: BoneReferenceRotation;
  //RightHandPinky4_r: BoneReferenceRotation,
};

export type StaticBoneReferencesDevType = {
  // LeftHand_r: BoneReferenceRotation; // hands are left out
  // RightHand_r: BoneReferenceRotation;
};

export type RadicalCharacterParams = {
  charId: string;
  hasShadow: boolean;
  showSkeleton: boolean;
  showModel: boolean;
  charData: CharacterType;
  showTrajectory?: boolean;
  showHistogram?: boolean;
  devMode?: boolean;
};

const DEFAULT_ROOT_HEIGHT = 0.9675;
const TRAJECTORY_COLOR = 0xff0000;
const TRAJECTORY_HIGHLIGHT = 0x0000cc;

// indexes of 2s keypoints for bones
// old const left = [2, 3, 4, 9, 10, 11, 15, 17, 22, 23, 24]; // left side
// old const center = [0, 1, 8]; // center
// const blue = [5, 6, 7, 12, 13, 14, 16, 18, 19, 20, 21]; // right side
const left = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];
const center = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
// const right = [11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31];
const keypointColors = {
  left: 0x006400, // green
  center: 0xff0000, // red
  right: 0x0063dd, // blue
};

type StoredAnimation = {
  animationInfo: AnimationObjectInfo | undefined;
  animationData: AnimationCoreData;
};

type StoredFaceAnimation = {
  animationInfo: AnimationObjectInfo | undefined;
  animationData: AnimationFaceData;
};

export class RadicalCharacterLegacy extends Object3D implements IRadicalCharacter {
  private readonly charId: string;

  private internals: CharacterInternals | undefined;
  private rotationOffsets: Quaternion[] = [];
  private rotationStaticOffsets: Quaternion[] = [];
  private boneReferences: BoneReferencesType | BoneReferencesDevType | undefined = undefined; //{} as BoneReferencesType;
  private staticBoneReferences: StaticBoneReferencesType | StaticBoneReferencesDevType | undefined = undefined;
  private bonesHelperVisible: boolean = false;

  // holds the bounding shpere once generated
  private boundingSphere: Sphere | undefined;

  private skeleton: Skeleton | undefined = undefined;
  private skeletonHelper: SkeletonHelper | undefined = undefined;
  // we will show this while a model is being changed
  private tempSkeletonHelper: SkeletonHelper | undefined = undefined;

  private characterType: CharacterType | undefined = undefined;

  private characterModel: CharacterModelInfo | undefined = undefined;

  // will hold dev mode value
  private inDevMode: boolean = false;

  // will hold the 2d points for dev mode
  private pointsHolder: Object3D;

  // will hold referenceto head mes if any
  private meshWithMorphs: SkinnedMesh[] = [];
  private morphDictionary: {
    [key: string]: number;
  }[] = [];

  // will hold 2d keypoints (Live and Core) if requested in admin mode
  private points2D: Mesh[] = [];

  // animations linked to character
  // private linkedAnimations: AnimationLoaded[];

  // animation object and data linked to character
  private animationsAttached: Map<string, StoredAnimation> = new Map<string, StoredAnimation>();

  // animation object and data linked to character
  private animationsFaceAttached: Map<string, StoredFaceAnimation> = new Map<string, StoredFaceAnimation>();

  // animations to load after bject has loaded
  // private animationsToAttach: Map<string, StoredAnimation> = new Map<string, StoredAnimation>();

  // total duration of the linked animations
  private linkedAnimationsDuration: number;
  private linkedFaceAnimationsDuration: number = 0;
  // animations linked to character to load after model is loaded
  private linkAnimationsPostLoad: any[] = [];

  // animation JSON data linked to character to load after model is loaded
  private linkJONAnimationPostLoad: any[] = [];

  // will hold loaded state
  private modelLoaded: boolean;

  // will hold animation clips
  private animationClips: any[];

  // will hold the scale factor based either on calculation or rig type
  private scaleFactor: number = 1;

  // this holds the materilas foun on the object
  private materials: Material[];
  // this will hold the orginal material while locked material is applied (because materials can be shared across clones)
  private materialsOriginal: Material[] = [];

  // this holds all the skinned meshed found on the model
  // private skinnedMeshes: SkinnedMesh[];

  // shadow status
  private hasShadow: boolean;

  // skeleton viivility
  private showSkleton: boolean;

  // show model --- more for internal use
  private showModel: boolean;

  // show json trajectory
  private showTrajectory: boolean;
  // shw histogram (ghosts)
  private showHistogram: boolean;

  // keep reference to the hip bone
  private hipBone: Bone = new Bone();
  private hipLine: Line | null = null;
  private hipTrajectory: Object3D | null = null;
  private hipTrLastSelect: Mesh | null = null;

  // keep unfnished loading models when model is changed
  private modelsLoading: Map<string, CharacterModelInfo> = new Map();

  // are we in dev mode?
  private devMode: boolean = false;

  private selected: boolean = false;

  private createGhosts: boolean = false;
  private ghostsArray: {
    duration: number;
    total: number;
    object: LineSegments;
  }[] = [];
  private animationGhosts: {
    object: LineSegments;
    frame: number;
    opacity: number;
  }[] = [];
  private ghosts: Object3D = new Object3D();
  private animationGhostsHolder: Object3D = new Object3D();
  private allTrajectories = new Map<string, Object3D>();
  private allGhosts = new Map<string, Object3D>();
  // will offset the root bone by this value
  private defaultRootOffset: Vector3 = new Vector3();

  private hue: number = 0;

  // this will hold the 3d text
  private text3D: Text3D | undefined;

  // this will control text visibility
  private textVisible: boolean = true;

  // private camera reference
  private cameraReference: Camera | undefined;

  // this will hold the loading box and its material
  private loadingBox: Object3D = new Object3D();
  private loadingBoxMaterial: ShaderMaterial | LineBasicMaterial | undefined = undefined;
  private loadingSeed: number = 0;

  // frameid for animation
  private frameId: number = 0;

  // will keep local time for shader animation
  private time: number = 0;

  // this will hold the locked state
  private locked: boolean = false;

  // temp, holds live player id with applied Live data to char
  private livePlayerId: string | undefined = undefined;

  // Uniforms for the shader of this object
  private uniforms: {
    bloomLayer: { value: number };
    isHovered: { value: number };
  };

  // Raycaster for snapping
  public snapRaycaster: Raycaster;

  //

  private selectionBox: LineSegments;

  //

  constructor(
    params: RadicalCharacterParams // charId: string, // hasShadow: boolean, // showSkeleton: boolean, // showModel: boolean, // charData: CharacterType, // showTrajectory?: boolean, // showHistogram?: boolean, // devMode?: boolean // )
  ) {
    super();
    const { charId, charData, hasShadow, showModel, showSkeleton, devMode, showHistogram, showTrajectory } = params;
    this.charId = charId;
    this.uuid = charId;
    this.hasShadow = hasShadow;
    this.devMode = devMode !== undefined ? devMode : false;
    this.pointsHolder = new Object3D();
    this.linkedAnimationsDuration = 0;
    this.modelLoaded = false;
    this.animationClips = [];
    this.materials = [];
    this.characterType = charData;
    this.showSkleton = showSkeleton;
    this.showModel = showModel;
    this.showTrajectory = showTrajectory || CREATE_ROOT_TRAJECTORY;
    this.showHistogram = showHistogram || CREATE_MOTION_HISTOGRAM;
    if (this.characterType.fileType === FileTypes.GLB) {
      if (this.characterType.rigType === RigTypes.MIXAMO) {
        this.scaleFactor = 1;
      }
      if (this.characterType.rigType === RigTypes.RADICAL) {
        this.scaleFactor = 1; //0.01
      }
    }
    this.add(this.loadingBox);

    //

    // Set the uniforms
    this.uniforms = {
      bloomLayer: { value: 0.0 },
      isHovered: { value: 0.0 },
    };

    //

    //Initialize the raycaster for snap
    this.snapRaycaster = new Raycaster();

    // placeholder
    const edgeGeom = new EdgesGeometry(new BoxGeometry(1, 1, 1));
    const edgeMat = new LineBasicMaterial({ color: 0xffffff });
    this.selectionBox = new LineSegments(edgeGeom, edgeMat);
    this.selectionBox.raycast = function () {};
  }

  public setLoaderBox(o: Object3D) {
    this.add(o);
    this.start();
    this.loadingBox = o;
    this.loadingSeed = Math.round(Math.random() * 5000);
    o.traverse((child) => {
      if (child instanceof LineSegments && child.name === 'main') {
        this.loadingBoxMaterial = child.material.clone();
        child.material = this.loadingBoxMaterial;
      }
    });
  }

  public generateSelectionBox(maxB?: Box3) {
    let maxBox = maxB;
    if (this.selectionBox) {
      // this.parent.add(this.selectionBox);

      this.remove(this.selectionBox);
    }
    // if (!this.isModelLoaded()) return;
    if (maxBox === undefined) {
      const bh = this.getBonesHelper();
      //@ts-ignore
      maxBox = bh && Object.keys(bh).length !== 0 ? new Box3().setFromObject(bh) : new Box3();
    }
    const { max, min } = maxBox;

    let boxCenter: Vector3 = new Vector3();
    maxBox.getCenter(boxCenter);

    const edgeGeom = new EdgesGeometry(new BoxGeometry(max.x - min.x, max.y - min.y, max.z - min.z));

    const edgeMat = new LineBasicMaterial({
      color: 0xffffff, // Set the color of the frame.
      linewidth: 1, // Set the line width of the frame.
    });

    this.selectionBox = new LineSegments(edgeGeom, edgeMat);

    // Position the frame at the center of the asset's bounding box.
    this.selectionBox.position.x = boxCenter.x - this.position.x;
    this.selectionBox.position.y = boxCenter.y - this.position.y;
    this.selectionBox.position.z = boxCenter.z - this.position.z;

    // this.selectionBox.visible = false;

    // Add the frame to the Group, making it part of the selector indicator.
    this.add(this.selectionBox);
  }

  private createLoaderMesh(): Mesh {
    const geom = new BoxGeometry(2, 2, 0.5, 3, 3, 3);
    geom.translate(0, 1, 0);
    const mat = new MeshBasicMaterial({ color: 0x44aa88, wireframe: true });
    return new Mesh(geom, mat);
  }

  private getThisBone(boneName: string, boneCollection: Bone[]): Bone {
    if (boneCollection) {
      const bones = boneCollection;
      for (let q = 0; q < bones.length; q += 1) {
        if (bones[q].name.indexOf(boneName) > -1) {
          let b = bones[q];
          while (b.parent && b.parent instanceof Bone && b.parent.name === boneName) b = b.parent;
          return b;
        }
      }
      console.warn('Bone not found: ', boneName);
      return new Bone();
    }
    console.warn('Bone collection not found');
    return new Bone();
  }

  private getTheseBones(boneName: string, object: Object3D): any[] {
    const bonesReturned: any[] = [];
    object.traverse((childBones) => {
      if (childBones.name === boneName) {
        bonesReturned.push(childBones);
      }
    });
    return bonesReturned;
  }

  public addAnimationFromClip(clip: any) {
    if (clip && this.internals) {
      this.animationClips.push(clip);
      // console.log('adding clip: ', clip);
      let action = null;
      this.internals.model.animations = clip;
      this.internals.model.mixer = new AnimationMixer(this.internals.model);
      const animation = this.internals.model.animations[0];
      if (animation) {
        action = this.internals.model.mixer.clipAction(animation);
        action.play();
      }
      // console.log('action added: ', action);
      this.internals.action = action;
    }
  }

  public addLiveAnimation(id: string | undefined) {
    const charModel = this.getCharacterModel();
    if (charModel) charModel.liveAttendeeId = id;
    this.livePlayerId = id;
  }

  public hasLiveAnimation(): boolean {
    return !(this.livePlayerId === undefined);
  }

  public getLivePlayerId(): string | undefined {
    return this.livePlayerId;
  }

  public removeLiveAnimation() {
    this.getCharacterModel()!.liveAttendeeId = undefined;
    this.livePlayerId = undefined;
  }

  public playLiveAnimation(data: any) {
    // console.log('playing data: ', data);
    if (data === undefined) {
      console.warn('Missing frame data...');
      return;
    }
    data.frame_keypoints_data && this.setKeypointsFromData(data.frame_keypoints_data);
    data.frame_data && this.setRotationsFromFrameData(data.frame_data, 1);
    data.frame_data_face && this.setFaceMorphFromData(data.frame_data_face);
  }

  public setKeypointsFromData(data: any) {
    // console.log('In dev mode?: ', this.inDevMode);
    if (this.inDevMode) {
      const keypoints2DArray = Object.values(data);
      this.setPointsToPosition(keypoints2DArray);
    } else if (this.points2D.length === 0) {
      const keypoints2DArray = Object.values(data);
      this.createPoints(keypoints2DArray);
    }
  }

  public createPoints(points: any) {
    console.log('Create points: ', points);
    for (let i = 0; i < points.length; i += 1) {
      const colorP = left.includes(i)
        ? keypointColors.left
        : center.includes(i)
        ? keypointColors.center
        : keypointColors.right;

      const s = new Mesh(
        new SphereGeometry(0.02),
        new MeshBasicMaterial({
          color: colorP,
        })
      );
      s.material.onBeforeCompile = (shader: any) => {
        shader = this.updateShader(shader);
      };
      // s.position.set(
      //     points[i][0] / 1.7, // - 150,
      //     -points[i][1] / 1.7 + 270,
      //     points[i][2] / 1.7 - 150
      // );
      s.position.set(
        points[i][0] * 0.001 - 1.5, // - 150,
        points[i][1] * 0.001 + 1.5,
        points[i][2] * 0.001
      );
      // this.firstFramePositions.push(s.position.clone());
      this.pointsHolder.add(s);
      this.points2D.push(s);
    }
  }

  public setPointsToPosition(pointPosition: any) {
    if (this.points2D.length) {
      for (let i = 0; i < this.points2D.length; i += 1) {
        if (false) {
          // (this.showFirst) {
          // this.points2D[i].position.copy(this.firstFramePositions[i]);
        } else if (pointPosition) {
          // this.points[i].position.set(
          //     pointPosition[i][0] / 1.7, // - 150,
          //     -pointPosition[i][1] / 1.7 + 270,
          //     pointPosition[i][2] / 1.7 - 150
          // );
          this.points2D[i].position.set(
            (pointPosition[i][0] / 2.5) * 0.01 - 0.7, // - 150,
            (-pointPosition[i][1] / 2.5) * 0.01 + 2,
            (pointPosition[i][2] / 2.5) * 0.01
          );
        }
      }
      this.pointsHolder.position.copy(this.position);
    } else {
      // console.log('points positin: ', pointPosition);
      this.createPoints(pointPosition);
    }
  }

  public attachAnimation(info: AnimationObjectInfo, data: AnimationCoreData): number {
    const { playback, uuid } = info;
    // const { duration } = data;
    const newAnimation = {
      animationInfo: info,
      animationData: data,
    };
    const animValues = [...this.animationsAttached.values()];

    let lastAnim = animValues.length ? animValues[0].animationInfo : undefined;
    if (newAnimation.animationInfo.playback.start === undefined) {
      // new animation that does not have start time (unlike those from websocket)
      if (lastAnim !== undefined) {
        animValues.forEach((anim) => {
          if (lastAnim && anim.animationInfo)
            lastAnim =
              (anim.animationInfo.playback.start || 0) >= (lastAnim.playback.start || 0)
                ? anim.animationInfo
                : lastAnim;
        });

        newAnimation.animationInfo.playback.start = lastAnim
          ? (lastAnim.playback.start || 0) + lastAnim.playback.duration
          : 0;
      } else {
        newAnimation.animationInfo.playback.start = 0;
      }
    }
    this.animationsAttached.set(uuid, newAnimation);
    playback.start = newAnimation.animationInfo ? newAnimation.animationInfo.playback.start : 0;

    this.linkedAnimationsDuration = playback.start + (info ? info.playback.duration : 0);
    // console.log('---------Position after last ADD: ', this.linkedAnimationsDuration);

    return playback.start;
  }

  public getAnimationObjects(): AnimationObjectInfo[] {
    const anims = [...this.animationsAttached.values()];
    const animObjs = anims.map((a) => a.animationInfo).filter((item): item is AnimationObjectInfo => !!item);
    return animObjs;
  }

  public getAnimationCores(): AnimationCoreData[] {
    const anims = [...this.animationsAttached.values()];
    const animObjs = anims.map((a) => a.animationData).filter((item): item is AnimationCoreData => !!item);
    return animObjs;
  }

  public getAnimationById(id: string): AnimationObjectInfo | undefined {
    return this.animationsAttached.get(id)?.animationInfo;
  }

  public getAnimationSceneById(id: string): AnimationCoreData | undefined {
    return this.animationsAttached.get(id)?.animationData;
  }

  public removeAnimationData(id: string): number {
    const anim = this.animationsAttached.delete(id);
    // this.linkedAnimationsDuration = la ? la.playback.start + la.duration : 0;
    this.linkedAnimationsDuration = this.updateAnimationsDuration();
    return this.linkedAnimationsDuration;
  }

  public removeAllAnimations() {
    this.animationsAttached.clear();
    this.linkedAnimationsDuration = this.updateAnimationsDuration();
  }

  public modifyAnimationPlayback(ch: AnimationChangeValue, animId: string, final?: boolean) {
    const anim = this.animationsAttached.get(animId);
    if (anim === undefined || anim.animationInfo === undefined) return;
    const { fps, speed, start } = ch;
    start && (anim.animationInfo.playback.start = start);
    speed && (anim.animationInfo.playback.speed = speed);
    fps && (anim.animationInfo.playback.fps = fps);
    // console.log('modify anim playback');
    this.linkedAnimationsDuration = this.updateAnimationsDuration();
  }

  public hasAnimationClips(): boolean {
    return this.animationClips.length !== 0;
  }

  public getBonesHelper(): BonesHelper | undefined {
    return this.internals?.boneHelper;
  }

  public getShowTrajectory(): boolean {
    return this.showTrajectory;
  }

  public getShowHistogram(): boolean {
    return this.showHistogram;
  }

  public getScaleFactor(): number {
    return this.scaleFactor;
  }

  public getTotalDuration(): number {
    return this.linkedAnimationsDuration;
  }

  public setCurrentCamera(cam: Camera): void {
    this.cameraReference = cam;
  }

  public setLocked(lock: boolean) {
    this.locked = lock;
    // let transparent = false;
    // let opacity = 1;
    // if (lock) {
    //   opacity = 0.35;
    //   transparent = true;
    // }

    // this.materials.forEach((mat, i) => {
    //   if (lock) {
    //     this.materialsOriginal[i] = mat;
    //     mat = mat.clone();
    //   } else {
    //     mat = this.materialsOriginal[i];
    //     // TODO Simply cache generated selection materials instead of deletion
    //     // Refactor with approach from AssetObject. Extract to LockableObject module,
    //     // to share the same implementation across all lockable objects.
    //     delete this.materialsOriginal[i];
    //   }

    //   if (typeof mat === 'undefined') {
    //     console.error('Found undefined material', this.materials, i);
    //     return;
    //   }

    //   mat.transparent = transparent;
    //   mat.opacity = opacity;
    //   mat.needsUpdate = true;

    //   if (this.internals && this.internals.skinnedMeshes[i]) this.internals.skinnedMeshes[i].material = mat;
    // });
  }

  public isLocked(): boolean {
    return this.locked;
  }

  public createText3D(name: string, visible: boolean) {
    const r = 0; // this.textPosition === "top" ? 0 : -Math.PI / 2;
    const size = 0.05; // this.textPosition === "top" ? 5 : 15;
    this.name = name;
    if (this.text3D) {
      this.remove(this.text3D);
    }
    this.text3D = new Text3D('helvetiker', name, 0.005, size, 0, r, true);
    if (this.text3D) this.add(this.text3D);
    this.text3D.position.set(0, 2, 0);
    this.updateText3D(this);
    // this.name = name;
    this.setShowNames(visible);
    // console.log('text created: ', this.text3D);
  }

  public setShowNames(val: boolean) {
    this.textVisible = val;
    if (this.text3D) this.text3D.visible = val;
  }

  public setCharacterModel(c: CharacterModelInfo) {
    this.characterModel = c;
  }

  public getAssetInfo(): CharacterModelInfo | undefined {
    return this.characterModel;
  }

  public getCharacterModel(): CharacterModelInfo | undefined {
    return this.characterModel;
  }

  public getBoundingSphere(): Sphere | undefined {
    return this.boundingSphere;
  }

  public setBoundingSphere(o: Sphere) {
    this.boundingSphere = o;
  }

  private updateAnimationsDuration(): number {
    let lastAnim = undefined;
    let lastStart = 0;
    this.animationsAttached.forEach((anim) => {
      // console.log('Update anim: ', anim);
      if (anim.animationInfo === undefined) return;
      const {
        playback: { start },
      } = anim.animationInfo;
      if (start >= lastStart) {
        lastStart = start;
        lastAnim = anim.animationInfo;
      }
    });
    const la = lastAnim ? (lastAnim as AnimationObjectInfo) : undefined;
    // console.log('last after deleted: ', la);
    return la ? (la.playback.start ? la.playback.start : 0) + la.playback.duration : 0;
  }

  private findRootSkinnedMesh(object: Object3D | Group) {
    // : SkinnedMesh | undefined {
    const skinnedMeshes: Array<SkinnedMesh> = [];
    const morphedMeshes: Array<SkinnedMesh> = [];
    const materials: Array<MeshStandardMaterial> = [];
    const textures: Array<Texture> = [];
    let size: Vector3 = new Vector3();
    let maxBox: Box3 = new Box3();
    let skinnedMesh: SkinnedMesh | undefined = undefined;

    object.traverse((child: any) => {
      if (child instanceof SkinnedMesh || child.isSkinnedMesh) {
        // console.log('Child type: ', typeof child);
        skinnedMeshes.push(child);
        if (child.morphTargetDictionary && child.morphTargetInfluences) {
          const skinnedChild = child as SkinnedMesh;
          morphedMeshes.push(skinnedChild);
        }
        if (child.material.emissiveIntensity > 1.0) {
          child.layers.enable(2);
        }
        child.material.transparency = true;
        child.material.needsUpdate = true;
        materials.push(child.material);
        child.geometry.computeBoundingBox();
        maxBox.union(child.geometry.boundingBox);
      }
    });
    maxBox.getSize(size);

    if (skinnedMeshes.length > 0) {
      let maxBones = skinnedMeshes[0].skeleton ? skinnedMeshes[0].skeleton.bones.length : 0;
      let skinnedMesh = skinnedMeshes[0];
      skinnedMeshes.forEach((skm) => {
        const boneNb = skm.skeleton ? skm.skeleton.bones.length : 0;
        if (boneNb > maxBones) {
          maxBones = boneNb;
          skinnedMesh = skm;
        }
      });
    }

    return {
      materials,
    };
  }

  private generateCharInternals(object: Mesh | Group, fromClone?: boolean): CharacterInternals {
    // console.log('generating internal for: ', object);
    let skinnedMesh: SkinnedMesh | undefined;
    const skinnedMeshes: Array<SkinnedMesh> = [];
    let size: Vector3 = new Vector3();
    let maxBox: Box3 = new Box3();

    object.traverse((child: any) => {
      if (child instanceof SkinnedMesh || child.isSkinnedMesh) {
        // Edit the shader of the material
        // This is to handle the selective bloom on this object
        // So we can isolate objects not needing bloom
        // Modify the shader used by the mesh's material
        child.material.onBeforeCompile = (shader: any) => {
          // Specify the GLSL version
          shader.glslVersion = GLSL3;

          // Merge existing shader uniforms with additional custom uniforms
          shader.uniforms = {
            ...shader.uniforms,
            ...this.uniforms,
          };

          // Modify the fragment shader to include a custom uniform for controlling bloom intensity
          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <common>`,
            `#include <common>
            uniform float bloomLayer;
            uniform float isHovered;
            layout(location = 0) out vec4 base_FragColor;
            layout(location = 1) out vec4 bloom_FragColor;
            layout(location = 2) out vec4 depth_FragColor;
            layout(location = 3) out vec4 id_FragColor;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <opaque_fragment>`,

            `#ifdef OPAQUE
            diffuseColor.a = 1.0;
            #endif
            
            #ifdef USE_TRANSMISSION
            diffuseColor.a *= material.transmissionAlpha;
            #endif

            float cameraNear = 0.1; //hard coded for now
            float cameraFar = 40.0;
            float depth = gl_FragCoord.z / gl_FragCoord.w;
            float depthMapped = smoothstep(cameraNear, cameraFar, depth);
            
            vec4 objectColor = vec4( outgoingLight, diffuseColor.a );
            vec4 hoveringColor = vec4(1.0);
            
            base_FragColor = mix(objectColor, hoveringColor, isHovered);//vec4( outgoingLight, diffuseColor.a );
            bloom_FragColor = vec4( outgoingLight * bloomLayer, diffuseColor.a );
            depth_FragColor = vec4( depthMapped, depthMapped, depthMapped, diffuseColor.a );
            id_FragColor = vec4( depthMapped, depthMapped, depthMapped, diffuseColor.a );
            `
          );

          // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
          // These sections customize how the final color outputs are processed and rendered

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <tonemapping_fragment>`,
            `#if defined( TONE_MAPPING )
                bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
                base_FragColor.rgb = toneMapping( base_FragColor.rgb );
              #endif`
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <colorspace_fragment>`,
            `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
              base_FragColor = linearToOutputTexel( base_FragColor );
              `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <fog_fragment>`,
            `#ifdef USE_FOG
                #ifdef FOG_EXP2
                  float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
                #else
                  float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
                #endif
                bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
                base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
            #endif`
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <premultiplied_alpha_fragment>`,
            `#ifdef PREMULTIPLIED_ALPHA
                // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
                bloom_FragColor.rgb *= bloom_FragColor.a;
                base_FragColor.rgb *= base_FragColor.a;
              #endif`
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <dithering_fragment>`,
            `#ifdef DITHERING
                bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
                base_FragColor.rgb = dithering( base_FragColor.rgb );
              #endif`
          );

          // If the emissive intensity of the material is high, set the bloomLayer uniform to 1.0 to enable bloom
          if (child.material.emissiveIntensity > 1) this.uniforms.bloomLayer.value = 1.0;
        };

        // NOTE! setting TRUE improves render performance by A LOT
        // Temp added back
        child.frustumCulled = false;
        skinnedMeshes.push(child);
        child.castShadow = this.hasShadow;
        child.receiveShadow = this.hasShadow;
        // child.material.roughness = 1;
        if (child.material.emissiveIntensity > 1.0) {
          child.layers.enable(2);
        }
        child.material.transparency = true;
        child.material.needsUpdate = true;
        this.materials.push(child.material);
        child.geometry.computeBoundingBox();
        maxBox.union(child.geometry.boundingBox);
        if (child.skeleton && child.skeleton.bones.length > 40) {
          // get the one with the highest bone count -- todo: revize this
          skinnedMesh = child;
        }
        // get morphs
        if (child.morphTargetDictionary && child.morphTargetInfluences) {
          const skinnedChild = child as SkinnedMesh;
          skinnedChild.frustumCulled = false;
          this.meshWithMorphs.push(skinnedChild);
        }
      }
    });

    maxBox.getSize(size);

    //

    this.generateSelectionBox(maxBox);

    if (size.y > 100) this.scaleFactor = 0.01;
    else this.scaleFactor = 1;

    if (skinnedMeshes.length > 0) {
      let maxBones = skinnedMeshes[0].skeleton ? skinnedMeshes[0].skeleton.bones.length : 0;
      skinnedMesh = skinnedMeshes[0];
      skinnedMeshes.forEach((skm) => {
        const boneNb = skm.skeleton ? skm.skeleton.bones.length : 0;
        if (boneNb >= maxBones && skm.skeleton.bones[0].parent && skm.skeleton.bones[0].parent.type !== 'Bone') {
          maxBones = boneNb;
          skinnedMesh = skm;
        }
      });
    }

    const bones = skinnedMesh && skinnedMesh.skeleton ? skinnedMesh.skeleton.bones : [];
    const parent = skinnedMesh ? skinnedMesh.parent : undefined;
    this.skeleton = skinnedMesh && skinnedMesh.skeleton ? skinnedMesh.skeleton : undefined;
    const internalScale = parent ? parent.scale.clone() : new Vector3(1, 1, 1);
    if (parent && parent.name === 'Armature') {
      parent.quaternion.copy(new Quaternion());
      // parent.parent && parent.parent.quaternion.copy(new Quaternion());
    }
    // this.scaleFactor = internalScale.x;
    // console.log('skinned mesh: ', skinnedMesh, skinnedMeshes);
    renameBones(bones); // remove any name in front of bone names (mixamo)
    const hipBoneName = this.characterType ? BoneNames.hips[this.characterType.rigType] : null;
    if (hipBoneName) this.hipBone = this.getThisBone(hipBoneName, bones);
    if (this.showTrajectory) {
      const points = [];
      points.push(new Vector3(0, 0, 0));
      points.push(new Vector3(0, -10, 0));
      const geometry = new BufferGeometry().setFromPoints(points);
      const line = new Line(geometry, new MeshBasicMaterial({ color: 0xff7777 }));
      this.hipLine = line;
      this.add(this.hipLine);
    }
    // find the head:

    // this.hipBone.attach(line);
    // calulate offset hght of the imported root bone from default radical bone
    const p = new Vector3();
    const flavour = this.characterType ? this.characterType.flavour : undefined;
    const flavourAvatarCreators =
      flavour && (flavour === RigFlavours.AVATURN || flavour === RigFlavours.READYPLAYERME) ? true : false;

    if (this.characterType?.rigType === RigTypes.RADICAL) {
      const y = this.hipBone.position.z;
      this.hipBone.position.z = this.hipBone.position.y;
      this.hipBone.position.y = y;
    } else {
      if (this.characterType?.rigType === RigTypes.MIXAMO && flavour && flavour === RigFlavours.CINEMA4D) {
        const y = this.hipBone.position.z;
        this.hipBone.position.z = this.hipBone.position.y;
        this.hipBone.position.y = fromClone ? y : -y;
      }
      fromClone && (this.hipBone.position.y = this.hipBone.position.z);
      this.hipBone.position.z = 0;
    }
    this.hipBone.getWorldPosition(p);
    const yPos = this.characterType?.rigType === RigTypes.RADICAL ? this.hipBone.position.z : this.hipBone.position.y;
    // const zPos = this.characterType?.rigType === RigTypes.RADICAL ? this.hipBone.position.y : this.hipBone.position.z;
    // const pos = new Vector3();
    // this.hipBone.getWorldPosition(pos);
    // console.log('------------- RIG TYPE: ', this.characterType?.rigType);
    this.defaultRootOffset.y = yPos * this.scaleFactor * internalScale.x - DEFAULT_ROOT_HEIGHT;
    // this.defaultRootOffset.z = zPos * internalScale.x - DEFAULT_ROOT_HEIGHT;
    // console.log('Default Y offset: ', this.defaultRootOffset, 'internal scale: ', internalScale, this.scaleFactor);
    return {
      model: object,
      bones,
      targetBone: this.hipBone,
      skinnedMesh,
      skinnedMeshes,
      action: null,
      hitters: [],
      boneHelper: {} as BonesHelper,
      internalScale,
      maxBox,
    };
  }

  //

  // Set the amount of color for this object
  // Useful when using the Bloom to isolate this object
  // When isolated (colorAmount = 0) this object doesn't get bloom but keeps occlusion
  public setColorAmount(value: number) {
    this.uniforms.bloomLayer = { value };
  }

  //

  public generateBoneHelper(): BonesHelper {
    if (this.internals) {
      const skm = this.internals?.skinnedMesh;

      const bh = new BonesHelper(
        skm?.skeleton.bones[0].parent,
        this.internals?.model,
        this,
        { boneLengthScale: 1, cacheKey: this.charId },
        this.bonesHelperVisible,
        this.characterType?.rigType === RigTypes.MIXAMO ? 1 / this.internals.internalScale.x : 1 / this.scaleFactor,
        this.characterType?.rigType === RigTypes.MIXAMO
      );
      this.internals.boneHelper = bh;
      this.internals.hitters = bh ? bh.getHitMeshes() : [];
      if (skm && skm.geometry) skm.geometry.computeBoundingBox();

      return bh;
    } else return {} as BonesHelper;
  }

  public getCharInternals(): CharacterInternals | undefined {
    return this.internals;
  }

  public getSkeletonHelper(): SkeletonHelper | undefined {
    return this.skeletonHelper;
  }

  public getTempSkeletonHelper(): SkeletonHelper | undefined {
    return this.tempSkeletonHelper;
  }

  public isModelLoaded(): boolean {
    return this.modelLoaded;
  }

  public changeCharacterModel({
    charModel,
    url,
    mesh,
  }: {
    charModel: CharacterModelStorage;
    url?: string;
    mesh?: Object3D;
  }) {
    const prevModel = { ...this.characterModel };
    this.characterModel = {
      ...charModel,
      sceneName: prevModel.sceneName || 'no-scene-name',
      modelUuid: charModel.uuid,
      uuid: prevModel.uuid || 'no-uuid-on-character',
      faceAnimation: prevModel.faceAnimation || FeatureState.Disabled,
      fingerTracking: prevModel.fingerTracking || FeatureState.Disabled,
    };

    this.characterType = { fileType: charModel.fileType, rigType: charModel.rigType, flavour: charModel.flavour };
    const prevModelLoaded = this.modelLoaded;
    // if (!prevModelLoaded) this.modelsLoading.set(this.characterModel.modelUuid, this.characterModel);
    this.modelLoaded = false;
    return new Promise((resolve, reject) => {
      this.skeletonHelper = undefined;
      this.boneReferences = undefined;
      this.staticBoneReferences = undefined;
      this.hipBone = new Bone();
      this.materials = [];
      if (mesh) {
        this.cloneFromMesh(mesh, prevModel.rigType);
        this.modelLoaded = true;
        resolve(true);
      } else {
        if (!prevModelLoaded) return;
        if (url)
          this.loadURLForEditor(url)
            .then(() => {
              this.modelLoaded = true;
              resolve(true);
            })
            .catch((e) => {
              console.log('rejecting load from urls: reason: ', e);
              reject(e);
            });
      }
    });
  }

  private async promiseGLBRadicalRig(url: string, rotate: boolean = true, scene?: Group) {
    const gltfLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    gltfLoader.setDRACOLoader(dracoLoader);
    const processScene = (scene: Group) => {
      const char = scene;
      if (rotate) char.rotation.set(-Math.PI / 2, 0, 0);
      // char.position.set(0, 1, 0);
      // console.log('GLB char: ', char);
      const internals = this.generateCharInternals(char);
      // boxCenter = box.getCenter(new Vector3());
      // char.position.set(0, boxCenter.y * -scaleChar + (size.y * -scaleChar) / 2, 0);
      char.scale.set(this.scaleFactor, this.scaleFactor, this.scaleFactor);
      this.internals = internals;
      if (!this.boneReferences) {
        this.boneReferences = this.createBoneReferences(true, this.internals?.bones, this.internals?.model);
      }
      if (!this.staticBoneReferences) {
        this.staticBoneReferences = this.createStaticBoneReferences(this.internals?.bones, this.internals?.model);
      }
    };
    return new Promise((resolve) => {
      if (scene) {
        processScene(scene);
        resolve(this);
      } else {
        gltfLoader.load(
          url,
          (glb) => {
            processScene(glb.scene);
            resolve(this);
          }
          // this.onProgress.bind(this)
        );
      }
    });
  }

  private async promiseGLBMixamoRig(url: string, rotate = true, scene?: Group): Promise<RadicalCharacterLegacy> {
    const gltfLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    gltfLoader.setDRACOLoader(dracoLoader);
    const rig = this.characterType?.rigType;

    const processScene = (scene: Group) => {
      const char = scene;
      if (rotate) char.rotation.set(-Math.PI / 2, 0, 0);
      const internals = this.generateCharInternals(char);
      this.internals = internals;
      this.rotationOffsets = generateQuaternionOffsets();
      this.rotationStaticOffsets = generateStaticQuaternionOffsets();
      if (!this.boneReferences) {
        this.boneReferences = this.createBoneReferences(true, this.internals?.bones, this.internals?.model);
      }
      this.staticBoneReferences =
        this.staticBoneReferences || this.createStaticBoneReferences(this.internals?.bones, this.internals?.model);

      if (!this.devMode) {
        for (const boneName in this.rotationStaticOffsets) {
          const refBoneName = boneName === BoneNames.hips[RigTypes.MIXAMO] ? 'root_r' : `${boneName}_r`;
          const staticBoneRef = (this.staticBoneReferences as any)[refBoneName];
          if (staticBoneRef) {
            const sbone = staticBoneRef.bone;
            const soffset = this.rotationStaticOffsets[boneName].clone();
            staticBoneRef.quatOriginal = sbone.quaternion.clone();
            sbone.quaternion.copy(soffset.multiply(staticBoneRef.quatOriginal));
            staticBoneRef.quat = soffset;
          }
        }
      }

      for (const boneName in this.rotationOffsets) {
        const refBoneName = boneName === BoneNames.hips[RigTypes.MIXAMO] ? 'root_r' : `${boneName}_r`;
        // offsetting static bones (not animated yet by radical) to get a more natural pose for hands than t-pose
        // const staticBoneRef = (this.staticBoneReferences as any)[refBoneName];
        // if (staticBoneRef) {
        //   const sbone = staticBoneRef.bone;
        //   const soffset = this.rotationOffsets[boneName].clone();
        //   staticBoneRef.quatOriginal = sbone.quaternion.clone();
        //   sbone.quaternion.copy(soffset.multiply(staticBoneRef.quatOriginal));
        //   staticBoneRef.quat = soffset;
        // }
        // Offseting some angles to get to correct rotations
        if (
          this.boneReferences &&
          Object.prototype.hasOwnProperty.call(this.rotationOffsets, boneName) &&
          Object.prototype.hasOwnProperty.call(this.boneReferences, refBoneName)
        ) {
          const offset = this.rotationOffsets[boneName].clone();
          const br = Object.entries(this.boneReferences)?.find(([key, value]) => {
            if (key === refBoneName) {
              return true;
            }
            return false;
          });
          const boneRef: BoneReferenceRotation | undefined = br ? br[1] : undefined;
          // console.log('bone ref: ', boneRef, ' bone name: ', boneName);
          if (boneRef && rig === RigTypes.MIXAMO) {
            switch (boneName) {
              case 'Hips':
                offset.multiply(new Quaternion(1, 0, 0, 0));
                break;
              case 'Spine':
                offset.multiply(new Quaternion(1, 0, 0, 0));
                break;
              case BoneNames.leftUpLeg[RigTypes.MIXAMO]:
                // console.log('offsetting.....');
                offset.multiply(new Quaternion(1, 0, 0, 0));
                offset.x *= -1;
                offset.y *= -1;
                offset.z *= -1;
                break;
              case 'RightUpLeg':
                offset.multiply(new Quaternion(1, 0, 0, 0));
                offset.x *= -1;
                offset.y *= -1;
                offset.z *= -1;
                break;
              case 'RightShoulder':
                break;
              default:
                break;
            }
            // if (rig === RigTypes.SML) offset.copy(new Quaternion());
            boneRef.quatOriginal = boneRef.bone.quaternion.clone();
            boneRef.bone.quaternion.copy(offset);
            boneRef.quat = offset;
          }
        }
      }
    };
    if (rig === undefined) return this;
    return new Promise((resolve, reject) => {
      if (scene) {
        // just process the object if already loaded
        processScene(scene);
        resolve(this);
      } else {
        // normal url load otherwise
        gltfLoader.load(
          url,
          (glb) => {
            // console.log('glb file: ', glb);
            processScene(glb.scene);
            resolve(this);
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          (error) => {
            console.log('error: ', error);
            reject();
          }
        );
      }
    });
  }

  public getCharId(): string {
    return this.charId;
  }

  private setFaceMorphFromData(data: any) {
    Object.entries(data).forEach((el) => {
      if (el[1]) {
        // console.log(el[0], el[1]);
        // console.log('Mesh with morphs: ', this.meshWithMorphs);
        this.meshWithMorphs.forEach((mm) => {
          const ind = mm.morphTargetDictionary ? mm.morphTargetDictionary[el[0]] : undefined;
          // ind && console.log('MM: ', mm.morphTargetDictionary);
          // ind && console.log('Morphs applied: ', ind, el[0], el[1]);
          ind && mm.morphTargetInfluences && (mm.morphTargetInfluences[ind] = el[1] as number);
        });
      }
    });
  }

  public setRotationsFromFrameData(data: any, multiply = 100) {
    if (this.boneReferences && this.characterType) {
      Object.entries(this.boneReferences).forEach(([boneName, boneRef]) => {
        // console.log(`${boneName}: ${boneRef}`);
        // console.log('set rotation for char: ', this.characterType);
        const { bone, bones, quat } = boneRef;
        const rig = this.characterType?.rigType;
        const dataUsed = data.frame_data ? data.frame_data : data;
        // console.log('data used: ', dataUsed[boneName], boneName);
        if (dataUsed[boneName] === undefined) {
          // don't show warning for now for fingers
          if (boneName.indexOf('Hand') < 0) console.warn('Missing bone info in data: ', boneName, dataUsed);
          return;
        }
        const quatCalc =
          rig === RigTypes.MIXAMO
            ? this.setMixamoRotaion(bone.name, quat.clone(), [...dataUsed[boneName]])
            : rig === RigTypes.RADICAL
            ? this.setRadicalBoneRotation(bone.name, quat.clone(), [...dataUsed[boneName]])
            : this.setSMLBoneRotation(bone.name, quat.clone(), [...dataUsed[boneName]]);
        if (bones && bones.length > 1) {
          bones[0].quaternion.copy(quatCalc);
        } else {
          bone.quaternion.copy(quatCalc); // .slerp(quat, 0.01);
        }
        if (boneName === 'root_r') {
          const pos = this.getRootPosition(dataUsed);
          // const position = new Vector3(
          //   data.frame_data.root_t[0] * multiply,
          //   data.frame_data.root_t[2] * -multiply,
          //   data.frame_data.root_t[1] * multiply
          // );
          if (bones && bones.length > 1) {
            bones[0].position.copy(pos);
          } else {
            bone.position.copy(pos);
          }
        }
      });
    }
  }

  private getRootPosition(data: any): Vector3 {
    const scale = this.internals ? this.internals.internalScale.x : 1;
    const multiply = this.characterType?.rigType === RigTypes.MIXAMO ? 1 / scale : 1 / this.scaleFactor;
    const pos =
      this.characterType?.rigType === RigTypes.MIXAMO
        ? new Vector3(
            data.root_t[0] * multiply,
            data.root_t[2] * -multiply,
            (data.root_t[1] + this.defaultRootOffset.y) * multiply
          )
        : this.characterType?.rigType === RigTypes.RADICAL
        ? new Vector3(
            data.root_t[0] * multiply,
            (data.root_t[1] + this.defaultRootOffset.y) * multiply,
            data.root_t[2] * multiply
          )
        : new Vector3(
            data.root_t[0] * multiply,
            (data.root_t[1] + this.defaultRootOffset.y) * multiply,
            data.root_t[2] * multiply
          );
    return pos;
  }

  private createStaticBoneReferences(
    bones: Bone[] | undefined,
    object: Object3D
  ): StaticBoneReferencesType | StaticBoneReferencesDevType | undefined {
    const rig = this.characterType?.rigType;
    if (rig === undefined || bones === undefined) return undefined;
    // console.log('Are we in dev mode?', this.devMode);
    const sbr: StaticBoneReferencesType | StaticBoneReferencesDevType = this.devMode
      ? {}
      : {
          // LeftHand_r: {
          //   bone: this.getThisBone(BoneNames.leftHand[rig], bones),
          //   quat: this.getThisBone(BoneNames.leftHand[rig], bones).quaternion.clone(),
          //   bones: this.getTheseBones(BoneNames.leftHand[rig], object),
          // },
          LeftHandThumb1_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb1[rig], object),
          },
          LeftHandThumb2_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb2[rig], object),
          },
          LeftHandThumb3_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb3[rig], object),
          },
          LeftHandIndex1_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex1[rig], object),
          },
          LeftHandIndex2_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex2[rig], object),
          },
          LeftHandIndex3_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex3[rig], object),
          },
          LeftHandMiddle1_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle1[rig], object),
          },
          LeftHandMiddle2_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle2[rig], object),
          },
          LeftHandMiddle3_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle3[rig], object),
          },
          LeftHandRing1_r: {
            bone: this.getThisBone(BoneNames.leftHandRing1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing1[rig], object),
          },
          LeftHandRing2_r: {
            bone: this.getThisBone(BoneNames.leftHandRing2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing2[rig], object),
          },
          LeftHandRing3_r: {
            bone: this.getThisBone(BoneNames.leftHandRing3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing3[rig], object),
          },
          LeftHandPinky1_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky1[rig], object),
          },
          LeftHandPinky2_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky2[rig], object),
          },
          LeftHandPinky3_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky3[rig], object),
          },
          // RightHand_r: {
          //   bone: this.getThisBone(BoneNames.rightHand[rig], bones),
          //   quat: this.getThisBone(BoneNames.rightHand[rig], bones).quaternion.clone(),
          //   bones: this.getTheseBones(BoneNames.rightHand[rig], object),
          // },
          RightHandThumb1_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb1[rig], object),
          },
          RightHandThumb2_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb2[rig], object),
          },
          RightHandThumb3_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb3[rig], object),
          },
          RightHandIndex1_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex1[rig], object),
          },
          RightHandIndex2_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex2[rig], object),
          },
          RightHandIndex3_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex3[rig], object),
          },
          RightHandMiddle1_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle1[rig], object),
          },
          RightHandMiddle2_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle2[rig], object),
          },
          RightHandMiddle3_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle3[rig], object),
          },
          RightHandRing1_r: {
            bone: this.getThisBone(BoneNames.rightHandRing1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing1[rig], object),
          },
          RightHandRing2_r: {
            bone: this.getThisBone(BoneNames.rightHandRing2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing2[rig], object),
          },
          RightHandRing3_r: {
            bone: this.getThisBone(BoneNames.rightHandRing3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing3[rig], object),
          },
          RightHandPinky1_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky1[rig], object),
          },
          RightHandPinky2_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky2[rig], object),
          },
          RightHandPinky3_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky3[rig], object),
          },
        };
    Object.keys(sbr).forEach((boneInfo) => {
      const bi: BoneReferenceRotation = (sbr as any)[boneInfo];
      if (bi.bones && bi.bones.length > 1) {
        bi.quat = bi.bones[0].quaternion.clone();
      }
    });
    return sbr;
  }

  private createBoneReferences(
    repositionMixamoBones = false,
    bones: Bone[] | undefined,
    object: Object3D
  ): BoneReferencesType | undefined {
    // this.characterType.rigType = RigTypes.SML;

    const rig = this.characterType?.rigType;
    if (rig === undefined) return undefined;
    // console.log('bones: ', bones);
    if (bones) {
      let br: BoneReferencesType | BoneReferencesDevType = {
        root_r: {
          bone: this.getThisBone(BoneNames.hips[rig], bones),
          quat: this.getThisBone(BoneNames.hips[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.hips[rig], object),
        },
        Spine_r: {
          bone: this.getThisBone(BoneNames.spine[rig], bones),
          quat: this.getThisBone(BoneNames.spine[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.spine[rig], object),
        },
        Spine1_r: {
          bone: this.getThisBone(BoneNames.spine1[rig], bones),
          quat: this.getThisBone(BoneNames.spine1[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.spine1[rig], object),
        },
        Spine2_r: {
          bone: this.getThisBone(BoneNames.spine2[rig], bones),
          quat: this.getThisBone(BoneNames.spine2[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.spine2[rig], object),
        },
        Head_r: {
          bone: this.getThisBone(BoneNames.head[rig], bones),
          quat: this.getThisBone(BoneNames.head[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.head[rig], object),
        },
        LeftArm_r: {
          bone: this.getThisBone(BoneNames.leftArm[rig], bones),
          quat: this.getThisBone(BoneNames.leftArm[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftArm[rig], object),
        },
        LeftForeArm_r: {
          bone: this.getThisBone(BoneNames.leftForeArm[rig], bones),
          quat: this.getThisBone(BoneNames.leftForeArm[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftForeArm[rig], object),
        },
        LeftHand_r: {
          bone: this.getThisBone(BoneNames.leftHand[rig], bones),
          quat: this.getThisBone(BoneNames.leftHand[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftHand[rig], object),
        },
        LeftLeg_r: {
          bone: this.getThisBone(BoneNames.leftLeg[rig], bones),
          quat: this.getThisBone(BoneNames.leftLeg[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftLeg[rig], object),
        },
        LeftFoot_r: {
          bone: this.getThisBone(BoneNames.leftFoot[rig], bones),
          quat: this.getThisBone(BoneNames.leftFoot[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftFoot[rig], object),
        },
        LeftShoulder_r: {
          bone: this.getThisBone(BoneNames.leftShoulder[rig], bones),
          quat: this.getThisBone(BoneNames.leftShoulder[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftShoulder[rig], object),
        },
        LeftUpLeg_r: {
          bone: this.getThisBone(BoneNames.leftUpLeg[rig], bones),
          quat: this.getThisBone(BoneNames.leftUpLeg[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.leftUpLeg[rig], object),
        },
        RightArm_r: {
          bone: this.getThisBone(BoneNames.rightArm[rig], bones),
          quat: this.getThisBone(BoneNames.rightArm[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightArm[rig], object),
        },
        RightForeArm_r: {
          bone: this.getThisBone(BoneNames.rightForeArm[rig], bones),
          quat: this.getThisBone(BoneNames.rightForeArm[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightForeArm[rig], object),
        },
        RightHand_r: {
          bone: this.getThisBone(BoneNames.rightHand[rig], bones),
          quat: this.getThisBone(BoneNames.rightHand[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightHand[rig], object),
        },
        RightLeg_r: {
          bone: this.getThisBone(BoneNames.rightLeg[rig], bones),
          quat: this.getThisBone(BoneNames.rightLeg[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightLeg[rig], object),
        },
        RightFoot_r: {
          bone: this.getThisBone(BoneNames.rightFoot[rig], bones),
          quat: this.getThisBone(BoneNames.rightFoot[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightFoot[rig], object),
        },
        RightShoulder_r: {
          bone: this.getThisBone(BoneNames.rightShoulder[rig], bones),
          quat: this.getThisBone(BoneNames.rightShoulder[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightShoulder[rig], object),
        },
        RightUpLeg_r: {
          bone: this.getThisBone(BoneNames.rightUpLeg[rig], bones),
          quat: this.getThisBone(BoneNames.rightUpLeg[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.rightUpLeg[rig], object),
        },
        Neck_r: {
          bone: this.getThisBone(BoneNames.neck[rig], bones),
          quat: this.getThisBone(BoneNames.neck[rig], bones).quaternion.clone(),
          bones: this.getTheseBones(BoneNames.neck[rig], object),
        },
      };

      if (this.devMode) {
        (br as BoneReferencesDevType) = {
          ...br,
          LeftHandThumb1_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb1[rig], object),
          },
          LeftHandThumb2_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb2[rig], object),
          },
          LeftHandThumb3_r: {
            bone: this.getThisBone(BoneNames.leftHandThumb3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandThumb3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandThumb3[rig], object),
          },
          LeftHandIndex1_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex1[rig], object),
          },
          LeftHandIndex2_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex2[rig], object),
          },
          LeftHandIndex3_r: {
            bone: this.getThisBone(BoneNames.leftHandIndex3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandIndex3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandIndex3[rig], object),
          },
          LeftHandMiddle1_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle1[rig], object),
          },
          LeftHandMiddle2_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle2[rig], object),
          },
          LeftHandMiddle3_r: {
            bone: this.getThisBone(BoneNames.leftHandMiddle3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandMiddle3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandMiddle3[rig], object),
          },
          LeftHandRing1_r: {
            bone: this.getThisBone(BoneNames.leftHandRing1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing1[rig], object),
          },
          LeftHandRing2_r: {
            bone: this.getThisBone(BoneNames.leftHandRing2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing2[rig], object),
          },
          LeftHandRing3_r: {
            bone: this.getThisBone(BoneNames.leftHandRing3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandRing3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandRing3[rig], object),
          },
          LeftHandPinky1_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky1[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky1[rig], object),
          },
          LeftHandPinky2_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky2[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky2[rig], object),
          },
          LeftHandPinky3_r: {
            bone: this.getThisBone(BoneNames.leftHandPinky3[rig], bones),
            quat: this.getThisBone(BoneNames.leftHandPinky3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.leftHandPinky3[rig], object),
          },
          // RightHand_r: {
          //   bone: this.getThisBone(BoneNames.rightHand[rig], bones),
          //   quat: this.getThisBone(BoneNames.rightHand[rig], bones).quaternion.clone(),
          //   bones: this.getTheseBones(BoneNames.rightHand[rig], object),
          // },
          RightHandThumb1_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb1[rig], object),
          },
          RightHandThumb2_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb2[rig], object),
          },
          RightHandThumb3_r: {
            bone: this.getThisBone(BoneNames.rightHandThumb3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandThumb3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandThumb3[rig], object),
          },
          RightHandIndex1_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex1[rig], object),
          },
          RightHandIndex2_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex2[rig], object),
          },
          RightHandIndex3_r: {
            bone: this.getThisBone(BoneNames.rightHandIndex3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandIndex3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandIndex3[rig], object),
          },
          RightHandMiddle1_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle1[rig], object),
          },
          RightHandMiddle2_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle2[rig], object),
          },
          RightHandMiddle3_r: {
            bone: this.getThisBone(BoneNames.rightHandMiddle3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandMiddle3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandMiddle3[rig], object),
          },
          RightHandRing1_r: {
            bone: this.getThisBone(BoneNames.rightHandRing1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing1[rig], object),
          },
          RightHandRing2_r: {
            bone: this.getThisBone(BoneNames.rightHandRing2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing2[rig], object),
          },
          RightHandRing3_r: {
            bone: this.getThisBone(BoneNames.rightHandRing3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandRing3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandRing3[rig], object),
          },
          RightHandPinky1_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky1[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky1[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky1[rig], object),
          },
          RightHandPinky2_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky2[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky2[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky2[rig], object),
          },
          RightHandPinky3_r: {
            bone: this.getThisBone(BoneNames.rightHandPinky3[rig], bones),
            quat: this.getThisBone(BoneNames.rightHandPinky3[rig], bones).quaternion.clone(),
            bones: this.getTheseBones(BoneNames.rightHandPinky3[rig], object),
          },
        };
      }

      Object.keys(br).forEach((boneInfo) => {
        const bi: BoneReferenceRotation = (br as any)[boneInfo];
        if (bi.bones && bi.bones.length > 1) {
          bi.quat = bi.bones[0].quaternion.clone();
        }
        if (boneInfo === 'root_r') {
          // reposition hip for initial correct position
          if (repositionMixamoBones) {
            bi.bone.position.set(bi.bone.position.x, bi.bone.position.z, bi.bone.position.y);
          }
        }
      });
      // console.log('bone references: ', br);
      return br;
    } else return undefined;
  }

  private setRadicalBoneRotation = (boneName: string, boneQuat: Quaternion, rotationData: any) => {
    const inputQuat = new Quaternion(rotationData[1], rotationData[2], rotationData[3], rotationData[0]);
    const quat = new Quaternion().multiply(boneQuat.clone()).multiply(inputQuat.clone());
    return quat;
  };

  // not used now
  private setSMLBoneRotation = (boneName: string, boneQuat: Quaternion, rotationData: any) => {
    let inputQuat;
    const rig = this.characterType?.rigType;
    if (rig === undefined) return new Quaternion();
    switch (boneName) {
      case BoneNames.leftUpLeg[rig]:
        inputQuat = new Quaternion(-rotationData[3], rotationData[1], rotationData[2], rotationData[0]);
        inputQuat = new Quaternion(rotationData[3], -rotationData[1], -rotationData[2], rotationData[0]);
        break;
      // case BoneNames.leftLeg[rig]:
      case BoneNames.leftFoot[rig]:
        inputQuat = new Quaternion(rotationData[3], rotationData[1], -rotationData[2], -rotationData[0]);
        // inputQuat = new Quaternion();
        // console.log('bone name: ', boneName, ' - left side');
        break;
      default:
        inputQuat = new Quaternion(rotationData[1], rotationData[2], rotationData[3], rotationData[0]);
        // console.log('bone name: ', boneName, ' - default');
        break;
    }
    // inputQuat = new Quaternion(rotationData[1], rotationData[2], rotationData[3], rotationData[0]);
    const quat = new Quaternion().multiply(boneQuat.clone()).multiply(inputQuat.clone());
    return quat;
  };

  private setMixamoRotaion(boneName: string, boneQuat: Quaternion, rotationData: any[]): Quaternion {
    let inputQuat;
    const rig = this.characterType?.rigType;
    if (rig === undefined) return new Quaternion();
    switch (boneName) {
      case BoneNames.leftUpLeg[rig]:
      case BoneNames.leftLeg[rig]:
      case BoneNames.leftFoot[rig]:
        // console.log('setting bone: ', boneName, this.characterModel);
        inputQuat = new Quaternion(rotationData[3], -rotationData[1], -rotationData[2], rotationData[0]);
        break;
      case 'RightUpLeg':
      case 'RightLeg':
      case 'RightFoot':
        inputQuat = new Quaternion(rotationData[3], rotationData[1], rotationData[2], rotationData[0]);
        break;
      case 'Spine':
      case 'Spine1':
      case 'Spine2':
      case 'Neck':
        inputQuat = new Quaternion(-rotationData[3], rotationData[1], -rotationData[2], rotationData[0]);
        break;
      case 'Head':
        inputQuat = new Quaternion(-rotationData[3], rotationData[1], -rotationData[2], rotationData[0]);
        break;
      case BoneNames.hips[rig]:
        inputQuat = new Quaternion(rotationData[1], rotationData[2], rotationData[3], rotationData[0]);
        break;
      case 'RightShoulder':
      case 'RightArm':
      case 'RightForeArm':
      case 'RightHand':
        inputQuat = new Quaternion(rotationData[2], -rotationData[1], rotationData[3], rotationData[0]);
        // .multiply(new Quaternion(0, 0, 0, 1));
        break;
      case 'LeftShoulder':
      case 'LeftArm':
      case 'LeftForeArm':
      case 'LeftHand':
        inputQuat = new Quaternion(rotationData[2], rotationData[1], -rotationData[3], rotationData[0]);
        // .multiply(new Quaternion(0, 0, 0, 1));
        break;
      case 'RightHandThumb1':
      case 'RightHandThumb2':
      case 'RightHandThumb3':
      case 'RightHandRing1':
      case 'RightHandRing2':
      case 'RightHandRing3':
      case 'RightHandPinky1':
      case 'RightHandPinky2':
      case 'RightHandPinky3':
      case 'RightHandMiddle1':
      case 'RightHandMiddle2':
      case 'RightHandMiddle3':
      case 'RightHandIndex1':
      case 'RightHandIndex2':
      case 'RightHandIndex3':
      case 'LeftHandThumb1':
      case 'LeftHandThumb2':
      case 'LeftHandThumb3':
      case 'LeftHandRing1':
      case 'LeftHandRing2':
      case 'LeftHandRing3':
      case 'LeftHandPinky1':
      case 'LeftHandPinky2':
      case 'LeftHandPinky3':
      case 'LeftHandMiddle1':
      case 'LeftHandMiddle2':
      case 'LeftHandMiddle3':
      case 'LeftHandIndex1':
      case 'LeftHandIndex2':
      case 'LeftHandIndex3':
        inputQuat = new Quaternion(rotationData[1], rotationData[2], rotationData[3], rotationData[0]);
        break;
      default:
        inputQuat = new Quaternion(rotationData[3], rotationData[1], rotationData[2], rotationData[0]);
        break;
    }

    const quat = new Quaternion().multiply(boneQuat.clone()).multiply(inputQuat.clone());

    return quat;
  }

  // public async loadAndValidate(url: string, fileFormat: FileTypes): Promise<{ hasRig: true; rigType: RigTypes }> {
  //   return new Promise((resolve, reject) => {
  //     switch (fileFormat) {
  //       case FileTypes.GLB:
  //         const loader = new GLTFLoader();
  //         const dracoLoader = new DRACOLoader();
  //         dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
  //         loader.setDRACOLoader(dracoLoader);
  //         loader.load(url, (glb) => {
  //           // console.log('loaded file: ', glb);
  //           const char = glb.scene;
  //           this.findRootSkinnedMesh(char);
  //         });
  //     }
  //   });
  // }

  public async loadURLForEditor(url: string, loadedObject?: Group): Promise<RadicalCharacterLegacy> {
    // if we have loaded object we've already loaded the url (through validator), so we process it insted of loader result
    const loadPromises: Promise<any>[] = [];
    const { characterType } = this;
    return new Promise((resolve, reject) => {
      // console.log('load char: ', charType);
      if (characterType?.rigType === RigTypes.MIXAMO) {
        if (characterType?.fileType === FileTypes.GLB) {
          loadPromises.push(this.promiseGLBMixamoRig(url, true, loadedObject));
          // this.scaleFactor = 1;
        }
      }
      if (characterType?.rigType === RigTypes.RADICAL) {
        if (characterType?.fileType === FileTypes.GLB) {
          // this.scaleFactor = 0.01;
          loadPromises.push(this.promiseGLBRadicalRig(url, false, loadedObject));
        }
      }
      // if (characterType?.rigType === RigTypes.SML) {
      //   if (characterType?.fileType === FileTypes.GLB) {
      //     // this.scaleFactor = 0.01;
      //     loadPromises.push(this.promiseGLBMixamoRig(url, false));
      //   }
      // }
      Promise.all(loadPromises)
        .then((results) => {
          this.add(this.internals?.model);
          this.skeletonHelper = new SkeletonHelper(this.internals?.model);
          // Set cloned visibility based on parent
          if (!this.showSkleton) {
            this.skeletonHelper.visible = false;
          }
          this.skeletonHelper.frustumCulled = false;
          this.skeletonHelper.renderOrder = 1;
          // TODO: Is this relevant now that we're using a shader pass for tone mapping?
          this.materials.forEach((mat) => {
            if ((mat as MeshStandardMaterial).emissiveIntensity > 1) {
              mat.toneMapped = false;
            }
            // (mat as MeshStandardMaterial).envMapIntensity = 1;
            // (mat as MeshStandardMaterial).emissiveIntensity = 0;
          });
          this.modelLoaded = true;
          if (this.internals && this.internals.model) this.internals.model.visible = this.showModel;
          resolve(this);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public cloneOriginalMesh() {
    return SkeletonUtils.clone(this.internals?.model);
  }

  // create character model from an original mesh, already loaded
  public async cloneFromMesh(mesh: Object3D, previousRig?: RigTypes): Promise<boolean> {
    return new Promise((resolve) => {
      const clone = SkeletonUtils.clone(mesh) as Group;
      if (this.characterType?.rigType === RigTypes.MIXAMO) {
        if (previousRig && previousRig === RigTypes.RADICAL) {
        } else clone.rotation.set(-Math.PI / 2, 0, 0);
      }
      this.internals = this.generateCharInternals(clone, true);
      clone.scale.set(this.scaleFactor, this.scaleFactor, this.scaleFactor);
      if (!this.boneReferences) {
        // console.log('Recreating bone references: ', this.internals?.bones);
        // console.log('New model: ', this.characterModel);

        this.boneReferences = this.createBoneReferences(true, this.internals?.bones, this.internals?.model);
      }
      this.add(this.internals?.model);
      this.skeletonHelper = new SkeletonHelper(this.internals?.model);
      this.skeletonHelper.frustumCulled = false;
      this.skeletonHelper.renderOrder = 1;
      if (!this.showSkleton) {
        this.skeletonHelper.visible = false;
      }
      this.modelLoaded = true;
      resolve(true);
    });
  }

  public setDevMode(b: boolean, scene: Scene) {
    this.inDevMode = b;
    if (b) scene.add(this.pointsHolder);
    else scene.remove(this.pointsHolder);
  }

  public update(time?: number, fram?: number): void {
    if (this.inDevMode && this.pointsHolder) {
      this.pointsHolder.position.copy(this.position);
      // console.log('Points holder position: ', this.pointsHolder.position);
    }
    if (!this.modelLoaded) return;
    // playback for keyframe animations (not used yet)
    if (this.internals && this.internals.model && this.internals.model.mixer) {
      if (time) {
        const sec =
          time <= this.internals.action._clip.duration
            ? // time % this.internals.action._clip.duration :
              time
            : time % this.internals.action._clip.duration;
        this.internals.action.time = sec;
      }
      this.internals.model.mixer.update(0);
      // this.internals.boneHelper.updateMatrixWorld(false, 'char-update');
    }

    if (this.internals && this.internals.model && (time || fram || fram === 0)) {
      const frame = fram || fram === 0 ? fram : Math.floor((time || 0) / 33);
      // console.log('CHARACTER - Trying to update: ', fram);
      this.animationsFaceAttached.forEach((faceAnim) => {
        if (faceAnim.animationData.data === undefined) return;
        if (faceAnim.animationInfo === undefined) return;
        const {
          data: { animation },
        } = faceAnim.animationData;
        const {
          playback: { start, speed },
        } = faceAnim.animationInfo;
        const animNoLoop = Math.floor((frame - (start ? start : 0)) * speed);
        if (animation && animation[animNoLoop]) {
          // console.log('Apply frame at: ', frame);
          this.setFaceMorphFromData(animation[animNoLoop]);
        }
      });

      this.animationsAttached.forEach((anim) => {
        if (anim.animationData.data === undefined) return;
        const {
          data: { animation },
        } = anim.animationData;
        if (anim.animationInfo === undefined) return;
        const {
          playback: { start, speed },
        } = anim.animationInfo;
        // loop
        // const animFrame = Math.floor((frame - start) * speed) % animation.length; // last part for loop
        // console.log('Applying data: ', anim.animationData.data);
        // no loop, true frame
        const animNoLoop = Math.floor((frame - (start ? start : 0)) * speed);
        if (animation && animation[animNoLoop]) {
          if (this.animationGhosts[animNoLoop]) {
            for (let i = animNoLoop - 10; i < animNoLoop + 10; i++) {
              const opac =
                i < animNoLoop
                  ? Math.abs(i - animNoLoop + 10) / 10 - 0.1
                  : i > animNoLoop
                  ? Math.abs(animNoLoop - i + 10) / 10 - 0.1
                  : 1;
              if (this.animationGhosts[i]) {
                this.animationGhosts[i].opacity = opac;
                (this.animationGhosts[i].object.material as LineBasicMaterial).opacity = opac;
              }
            }
          }
          this.setRotationsFromFrameData(animation[animNoLoop], 1);
          animation[animNoLoop].frame_data_face && this.setFaceMorphFromData(animation[animNoLoop].frame_data_face);
          if (this.createGhosts) {
            this.chreateTemporaryGhosts(600);
            this.calculateGhostDurations();
          }
          if (this.hipLine && this.hipTrajectory) {
            // console.log('hip line: ', this.hipLine);
            var v1 = new Vector3().copy(this.hipBone.position).add(this.position);
            var v2 = new Vector3().copy(this.hipBone.position).add(this.position);
            v2.y = -10;
            var dir = new Vector3();
            dir.subVectors(v2, v1).normalize();
            var ray = new Raycaster(v1, dir);
            const int = ray.intersectObject(this.hipTrajectory);
            if (int[0]) {
              if (this.hipTrLastSelect) {
                this.hipTrLastSelect.position.y = 0;
                (this.hipTrLastSelect.material as MeshBasicMaterial).color = new Color(TRAJECTORY_COLOR);
              }
              const inter = int[1] ? (int[1].distance < int[0].distance ? int[1] : int[0]) : int[0];
              this.hipTrLastSelect = inter.object as Mesh;
              this.hipTrLastSelect.position.y = 0.001;
              // this.hipTrLastSelect.renderOrder = 1;
              (this.hipTrLastSelect.material as MeshBasicMaterial).color = new Color(TRAJECTORY_HIGHLIGHT);
            }
            this.hipLine.position.copy(this.hipBone.position);
          }
        }
      });
    }

    // if (this.linkedVideoPanel) this.linkedVideoPanel.update();

    // todo TEXTS for clones
    if (this.text3D && this.hipBone) {
      this.updateText3D(this);
    }

    // this.clones.forEach((clone: any) => {
    //     if (clone.text3D && clone.hipBone) {
    //         clone.updateText3D(clone);
    //     }
    //     if (clone.hasLinkedLight()) {
    //         clone.linkedLights.forEach((light) => {
    //             if (!light.isLightLocked()) {
    //                 // console.log('add current distance: ', light.getCurrentDistance());
    //                 light.setPosition(
    //                     clone.position.clone().add(light.getCurrentDistance())
    //                 );
    //             } else {
    //                 const dist = new Vector3().copy(light.position).sub(this.position);
    //                 light.setCurrentDistance(dist);
    //             }
    //             // if (!light.isLightLocked()) light.setPosition(clone.position.clone().add(light.getCurrentDistance()));
    //         });
    //     }
    // });
  }

  public forcedUpdate() {
    return;
  }

  public getHipBone(): Bone {
    return this.hipBone;
  }

  public updateLoader(t: number) {
    t *= 1;
    if (this.loadingBox) {
      const speed = 0.1; // + 0 * 0.05;
      const rot = t * speed;
      const val = (Math.cos((t + this.loadingSeed) / 1000) + 1) * 3500;
      // console.log('seed: ', this.loadingSeed, 'val: ', val);
      if (this.loadingBoxMaterial && this.loadingBoxMaterial instanceof ShaderMaterial)
        this.loadingBoxMaterial.uniforms.time.value = val;
    }
  }

  public setSkeletonVisible(b: boolean) {
    this.showSkleton = b;
    if (this.skeletonHelper) this.skeletonHelper.visible = b;
  }

  public setLinkAnimationsPostLoad(anim: any) {
    this.linkAnimationsPostLoad.push(anim);
  }

  public setLinkJONAnimationPostLoad(anim: any) {
    this.linkJONAnimationPostLoad.push(anim);
  }

  public getLinkJONAnimationPostLoad(): any[] {
    return this.linkJONAnimationPostLoad;
  }

  // create the trajectory of the root bone
  public createRootTrajectory(animId: string): Object3D {
    const trajectoryHolder = new Object3D();
    if (this.animationsAttached.size) {
      this.animationsAttached.forEach((anim) => {
        const { data } = anim.animationData;
        data &&
          data.animation.forEach((frame: any) => {
            const { frame_data } = frame;
            const m = new Mesh(new PlaneGeometry(0.03, 0.03, 1, 1), new MeshBasicMaterial({ color: TRAJECTORY_COLOR }));
            trajectoryHolder.add(m);
            m.position.set(frame_data.root_t[0], 0, frame_data.root_t[2]);
            m.rotateX(-Math.PI / 2);
            m.rotateZ(-Math.PI / 2);
          });
      });
    }
    this.hipTrajectory = trajectoryHolder;
    this.allTrajectories.set(animId, this.hipTrajectory);
    return trajectoryHolder;
  }

  public getTrajectory(id: string): Object3D | undefined {
    return this.allTrajectories.get(id);
  }

  public deleteTrajectory(id: string) {
    this.allTrajectories.delete(id);
  }

  public createGhost(b: boolean): Object3D | null {
    this.createGhosts = b;
    return b ? this.ghosts : null;
  }

  public createAnimationGhosts(): Object3D {
    // this.visible = false;
    // const frame = fram || fram === 0 ? fram : Math.floor((time || 0) / 33);
    // this.add(this.animationGhostsHolder);
    this.animationsAttached.forEach((anim) => {
      if (anim.animationData.data === undefined) return;
      const {
        data: { animation },
      } = anim.animationData;
      if (anim.animationInfo === undefined) return;
      const {
        playback: { start, speed },
      } = anim.animationInfo;

      // const animNoLoop = Math.floor((frame - (start ? start : 0)) * speed);
      if (animation && animation.length) {
        // console.log('Apply animation frame at: ', frame);
        // console.log('Apply animation frame at: ', animation[animNoLoop]);
        let fr = 0;
        // this.setRotationsFromFrameData(animation[animNoLoop], 1);
        (animation as Array<any>).forEach((frameAnim, frameNr) => {
          // console.log('frame nr: ', frameNr, frameAnim);
          this.setRotationsFromFrameData(frameAnim, 1);
          // console.log('Skeleton helper: ', this.skeletonHelper);
          this.skeleton?.update();
          this.skeletonHelper?.updateMatrixWorld(true);
          // this.skeletonHelper.geometry.computeBoundingBox();
          this.skeleton?.update();
          this.skeleton?.bones.forEach((bone) => {
            bone.updateMatrixWorld(true);
          });
          if (this.skeletonHelper === undefined) return;
          let geom = this.skeletonHelper.geometry.clone();
          // const mat = (this.skeletonHelper.material as LineBasicMaterial).clone();
          const skel = new LineSegments(
            geom,
            new LineBasicMaterial({ transparent: true, color: 0xebebeb, opacity: 1 })
          );
          let newPoss = new Vector3();
          let newQuat = new Quaternion();
          skel.position.copy(this.skeletonHelper.getWorldPosition(newPoss));
          skel.quaternion.copy(this.skeletonHelper.getWorldQuaternion(newQuat));
          this.animationGhosts[start + frameNr] = {
            frame: start + frameNr,
            object: skel,
            opacity: 1,
          };
          if ((start + frameNr) % 2 === 0) {
            // console.log('added: ', fr, ' out of:', start + frameNr);
            fr += 1;
            this.animationGhostsHolder.add(skel);
          }
        });
      }
    });
    // console.log('ghosts: ', this.animationGhostsHolder);
    this.visible = true;
    return this.animationGhostsHolder;
  }

  private chreateTemporaryGhosts(duration: number) {
    if (this.skeletonHelper) {
      // console.log('clone this: ', this.internals.skinnedMesh.skeleton);
      // const skell = SkeletonUtils.getHelperFromSkeleton(this.internals.skinnedMesh.skeleton);
      // console.log('clone: skell', skell);
      // (this.hipBone as any).isBone = true;
      // const skel = new SkeletonHelper(this.internals?.model);
      // const skell = SkeletonUtils.getHelperFromSkeleton(this.internals.skinnedMesh.skeleton).clone();
      // console.log('skell: ', skell);
      // .clone();
      // const skell = SkeletonUtils.clone(skel);

      const geom = this.skeletonHelper.geometry.clone();
      const mat = (this.skeletonHelper.material as LineBasicMaterial).clone();
      // let hsl;
      // console.log('material: ', mat);
      // mat.color.getHSL(hsl);
      // if (hsl) {
      // hsl = hsl as HSL;
      // hsl.h = this.hue;
      // mat.color.setHSL(hsl.h, hsl.s, hsl.l);
      // }
      this.hue = this.hue + 0.01 <= 1 ? this.hue + 0.01 : 0;
      const skel = new LineSegments(geom, mat);

      this.ghostsArray.push({
        duration: duration,
        total: duration,
        object: skel,
      });
      this.ghosts.add(skel);
    }
  }

  private calculateGhostDurations() {
    const markToExtract: any[] = [];
    this.ghostsArray.forEach((ghost) => {
      ghost.duration -= 1;
      ghost.object.position.z -= 0.02;
      ghost.object.scale.set(ghost.object.scale.x + 0.001, ghost.object.scale.x + 0.001, ghost.object.scale.x + 0.001);
      (ghost.object.material as LineBasicMaterial).opacity = ghost.duration / ghost.total;
      if (ghost.duration === 0) {
        markToExtract.push(ghost);
        this.ghosts.remove(ghost.object);
      }
    });
    markToExtract.forEach((ghostDead) => {
      const ind = this.ghostsArray.indexOf(ghostDead);
      this.ghostsArray.splice(ind, 1);
    });
  }

  private updateText3D(charObj?: RadicalCharacterLegacy) {
    const char = charObj || this;
    // const multi = this.isMixamo() ? 100 : 1;
    const g = char.hipBone.position; // localToWorld(pos);
    if (g && char.text3D) {
      // if (char.textPosition === "top") {
      const flavour = char.getCharacterModel()?.flavour;
      const rig = char.getCharacterModel()?.rigType;
      let zpos = g.z; // this.isMixamo() ? -g.y * multi : g.z * multi;
      let xpos = g.x;
      let ypos = g.y;
      // flavour && console.log('Rig: ', this.internals.internalScale);
      if (flavour && flavour === RigFlavours.CINEMA4D) {
        zpos = zpos * (char.internals && char.internals.internalScale ? char.internals.internalScale.z : 1);
        xpos = xpos * (char.internals && char.internals.internalScale ? char.internals.internalScale.x : 1);
        ypos = ypos * (char.internals && char.internals.internalScale ? char.internals.internalScale.y : 1);
      }
      rig === RigTypes.RADICAL && char.text3D.position.set(xpos, 2, zpos);
      rig === RigTypes.MIXAMO && char.text3D.position.set(xpos, 2, -ypos);
      if (this.cameraReference) char.text3D.lookAt(this.cameraReference.position);
    }
  }

  public removeLoader() {
    // if the character is still locked, set material to locked
    // console.log('calling updatematrixw: ', this.internals.boneHelper.updateMatrixWorld2);
    // this.internals ? this.internals.boneHelper.updateMatrixWorld(false, 'char-init') : null;

    this.locked ? this.setLocked(true) : null;

    this.remove(this.loadingBox);
    // this.loadingBox.mate
    if (this.loadingBoxMaterial) {
      this.loadingBoxMaterial.dispose();
      this.loadingBoxMaterial = undefined;
    }
    this.loadingBox = new Object3D();
    this.stop();
  }

  public cleanup(scene: any) {
    scene.remove(this.internals?.boneHelper);
    scene.remove(this.internals?.hitters);
    scene.remove(this.skeletonHelper);
    this.remove(this.internals?.model);
    // scene.remove(this.skeletonHelper);
    this.skeletonHelper && (this.skeletonHelper.visible = true);
    this.tempSkeletonHelper = this.skeletonHelper;
    if (scene && this.pointsHolder) {
      scene.remove(this.pointsHolder);
      this.pointsHolder = new Object3D();
    }

    this.internals = undefined;
    this.boneReferences = undefined;
    this.meshWithMorphs = [];
    scene.add(this.tempSkeletonHelper);
  }

  public isChar(): boolean {
    return true;
  }

  private animate(n: number) {
    // console.log('animating');
    this.updateLoader(n);
    this.frameId = requestAnimationFrame(this.animate.bind(this));
  }

  private start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate.bind(this));
    }
  }

  private stop() {
    cancelAnimationFrame(this.frameId);
    // this.frameId = null;
  }

  //TEMP

  public attachFaceAnimation(info: AnimationObjectInfo, data: AnimationFaceData): number {
    const { playback, uuid } = info;
    // const { duration } = data;
    const newAnimation = {
      animationInfo: info,
      animationData: data,
    };
    const animValues = [...this.animationsFaceAttached.values()];
    let lastAnim = animValues.length ? animValues[0].animationInfo : undefined;
    if (newAnimation.animationInfo.playback.start === undefined) {
      // new animation that does not have start time (unlike those from websocket)
      if (lastAnim !== undefined) {
        animValues.forEach((anim) => {
          if (lastAnim && anim.animationInfo)
            lastAnim =
              (anim.animationInfo.playback.start || 0) >= (lastAnim.playback.start || 0)
                ? anim.animationInfo
                : lastAnim;
        });

        newAnimation.animationInfo.playback.start = lastAnim
          ? (lastAnim.playback.start || 0) + lastAnim.playback.duration
          : 0;
      } else {
        newAnimation.animationInfo.playback.start = 0;
      }
    }
    this.animationsFaceAttached.set(uuid, newAnimation);
    playback.start = newAnimation.animationInfo ? newAnimation.animationInfo.playback.start : 0;

    this.linkedFaceAnimationsDuration = playback.start + (info ? info.playback.duration : 0);
    // console.log('---------Position after last ADD: ', this.linkedAnimationsDuration);

    return playback.start;
  }

  private updateShader(shader: any) {
    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      uniform float isHovered;
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      layout(location = 3) out vec4 id_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
        diffuseColor.a = 1.0;
        #endif
        
        #ifdef USE_TRANSMISSION
        diffuseColor.a *= material.transmissionAlpha;
        #endif
        
        vec4 objectColor = vec4( outgoingLight, diffuseColor.a );
        vec4 hoveringColor = vec4(1.0);
        
        base_FragColor = mix(objectColor, hoveringColor, isHovered);//vec4( outgoingLight, diffuseColor.a );
        bloom_FragColor = vec4( outgoingLight, 0.0 );
        depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
        id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
        `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
          bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
          base_FragColor.rgb = toneMapping( base_FragColor.rgb );
        #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
        base_FragColor = linearToOutputTexel( base_FragColor );
        `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
          #ifdef FOG_EXP2
            float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
          #else
            float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
          #endif
          bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
          base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
          // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
          bloom_FragColor.rgb *= bloom_FragColor.a;
          base_FragColor.rgb *= base_FragColor.a;
        #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
          bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
          base_FragColor.rgb = dithering( base_FragColor.rgb );
        #endif`
    );

    return shader;
  }

  public setIsHovered(value: number) {
    this.uniforms.isHovered.value = value;
  }

  public setIsSelected(value: boolean) {
    this.selected = value;
    if (this.selectionBox) this.selectionBox.visible = value;
  }

  public getIsSelected(): boolean {
    return this.selected;
  }

  public setSelectionBoxColor(value: string | null) {
    (this.selectionBox.material as LineBasicMaterial).color = new Color(value ? value : '#ffffff');
    (this.selectionBox.material as LineBasicMaterial).needsUpdate = true;
  }

  //

  public snapToBottom(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    //

    // Assets intersection
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    let closerAsset;
    let closerChar;
    let closerObject;

    if (intersectAssets.length > 0) {
      closerAsset = intersectAssets[0];
    }

    //

    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);

    if (intersectChars.length > 0) {
      closerChar = intersectChars[0];
    }

    //

    if (closerAsset && closerChar) {
      closerObject = closerAsset.distance > closerChar.distance ? closerChar : closerObject;
    } else if (closerAsset) {
      closerObject = closerAsset;
    } else if (closerChar) {
      closerObject = closerChar;
    }

    //

    if (closerObject) {
      const { x, y, z } = closerObject.point;
      this.position.set(x, y, z);
    }
  }

  public snapToObjectBelow(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    // Assets intersection
    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);
    const intersectOthers = this.snapRaycaster.intersectObjects(hitters.others);
    //const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    // Merge the intersected objects into a single array
    let intersectedObjects = [...intersectChars, ...intersectAssets, ...intersectOthers];

    // Function to find the object with the shortest distance
    const findClosestObject = (array: any) => {
      return array.reduce((min: any, obj: any) => (obj.distance < min.distance ? obj : min), array[0]);
    };

    if (intersectedObjects.length > 0) {
      // Find the closer object for snapping
      const closestObject = findClosestObject(intersectedObjects);

      if (closestObject.distance < 0.05) {
        // Remove the closest object from the array
        intersectedObjects = intersectedObjects.filter((obj) => obj.distance !== closestObject.distance);
      } else {
        const { x, y, z } = closestObject.point;
        this.position.set(x, y, z);
      }
    }
  }

  // public snapToObjectAbove(hitters: {
  //   lights: Object3D[];
  //   chars: Object3D[];
  //   assets: Object3D[];
  //   others: Object3D[];
  //   active: Object3D[];
  // }) {
  //   const characterPosition = this.position.clone();
  //   const snapDirection = new Vector3(0, 1, 0);

  //   // Update raycaster
  //   this.snapRaycaster.set(characterPosition, snapDirection);

  //   // Assets intersection
  //   const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

  //   if (intersectAssets.length > 0) {
  //     let index = 0;
  //     // console.log(intersectAssets[index]);
  //     while (intersectAssets[index].distance <= 0.05 && index < intersectAssets.length - 1) {
  //       // console.log(intersectAssets[index]);
  //       index++;
  //     }
  //     const { x, y, z } = intersectAssets[index].point;
  //     this.position.set(x, y, z);
  //     return;
  //   }
  // }
}
