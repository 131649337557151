import React from 'react';
type Props = {
  [x: string]: any;
};

export default function Image(props: Props) {
  return (
    <svg width="63" height="62" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="icons">
        <g id="fullbody-icon">
          <path
            d="M31 11.0986C28.8609 11.0986 27.1881 12.9467 27.1881 15.148C27.1881 17.3493 28.8609 19.1974 31 19.1974C33.1393 19.1974 34.8121 17.3494 34.8121 15.148C34.8121 12.9467 33.1393 11.0986 31 11.0986Z"
            fill="#979797"
          />
          <path
            d="M21.2408 19.6115C19.8863 19.6115 18.8496 20.7775 18.8496 22.1375C18.8496 23.4974 19.8863 24.6634 21.2408 24.6634H26.5848C26.5782 25.9626 26.2588 29.4512 26.0364 32.5181C25.7049 37.0904 25.1407 44.2228 25.1407 44.2228C25.0411 45.4645 25.8013 46.6404 26.9935 46.9494C28.4224 47.3198 29.7793 46.2948 29.9922 44.8346L30.7452 37.91L31.4958 44.8156L31.4986 44.8345C31.7115 46.2948 33.0681 47.3198 34.497 46.9494C35.6892 46.6404 36.4494 45.4645 36.3497 44.2228L35.4585 32.5181C35.4585 32.5181 34.9088 25.9633 34.9023 24.6634H40.7593C42.1138 24.6634 43.1505 23.4974 43.1505 22.1375C43.1505 20.7775 42.1138 19.6115 40.7593 19.6115H21.2408Z"
            fill="#979797"
          />
        </g>
        <path
          id="Rectangle 1068 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.484375 7.75C0.484375 3.73731 3.73731 0.484375 7.75 0.484375H13.5625C14.365 0.484375 15.0156 1.13496 15.0156 1.9375C15.0156 2.74004 14.365 3.39062 13.5625 3.39062H7.75C5.34238 3.39062 3.39062 5.34238 3.39062 7.75V13.5625C3.39062 14.365 2.74004 15.0156 1.9375 15.0156C1.13496 15.0156 0.484375 14.365 0.484375 13.5625V7.75ZM46.9844 1.9375C46.9844 1.13496 47.635 0.484375 48.4375 0.484375H54.25C58.2627 0.484375 61.5156 3.73731 61.5156 7.75V13.5625C61.5156 14.365 60.865 15.0156 60.0625 15.0156C59.26 15.0156 58.6094 14.365 58.6094 13.5625V7.75C58.6094 5.34238 56.6576 3.39062 54.25 3.39062H48.4375C47.635 3.39062 46.9844 2.74004 46.9844 1.9375ZM1.9375 46.9844C2.74004 46.9844 3.39062 47.635 3.39062 48.4375V54.25C3.39062 56.6576 5.34238 58.6094 7.75 58.6094H13.5625C14.365 58.6094 15.0156 59.26 15.0156 60.0625C15.0156 60.865 14.365 61.5156 13.5625 61.5156H7.75C3.73731 61.5156 0.484375 58.2627 0.484375 54.25V48.4375C0.484375 47.635 1.13496 46.9844 1.9375 46.9844ZM60.0625 46.9844C60.865 46.9844 61.5156 47.635 61.5156 48.4375V54.25C61.5156 58.2627 58.2627 61.5156 54.25 61.5156H48.4375C47.635 61.5156 46.9844 60.865 46.9844 60.0625C46.9844 59.26 47.635 58.6094 48.4375 58.6094H54.25C56.6576 58.6094 58.6094 56.6576 58.6094 54.25V48.4375C58.6094 47.635 59.26 46.9844 60.0625 46.9844Z"
          fill="#979797"
        />
      </g>
    </svg>
  );
}
