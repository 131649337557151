export const common = `
#include <common>
uniform float bloomLayer;
uniform float isHovered;
layout(location = 0) out vec4 base_FragColor;
layout(location = 1) out vec4 bloom_FragColor;
layout(location = 2) out vec4 depth_FragColor;
layout(location = 3) out vec4 id_FragColor;
`;

export const opaque_fragment = `
\#ifdef OPAQUE
    diffuseColor.a = 1.0;
#endif

#ifdef USE_TRANSMISSION
    diffuseColor.a *= material.transmissionAlpha;
#endif

float cameraNear = 0.1; //hard coded for now
float cameraFar = 40.0;
float depth = gl_FragCoord.z / gl_FragCoord.w;
float depthMapped = smoothstep(cameraNear, cameraFar, depth);

vec4 objectColor = vec4( outgoingLight, diffuseColor.a );
vec4 hoveringColor = vec4(1.0);

base_FragColor = mix(objectColor, hoveringColor, isHovered);//vec4( outgoingLight, diffuseColor.a );
bloom_FragColor = vec4( outgoingLight * bloomLayer, diffuseColor.a );
depth_FragColor = vec4( depthMapped, depthMapped, depthMapped, diffuseColor.a );
id_FragColor = vec4( depthMapped, depthMapped, depthMapped, diffuseColor.a );
`;

export const tonemapping_fragment = `
#if defined( TONE_MAPPING )
    bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
    base_FragColor.rgb = toneMapping( base_FragColor.rgb );
#endif
`;

export const colorspace_fragment = `
bloom_FragColor = linearToOutputTexel( bloom_FragColor );
base_FragColor = linearToOutputTexel( base_FragColor );
`;

export const fog_fragment = `
#ifdef USE_FOG
    #ifdef FOG_EXP2
        float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
    #else
        float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
    #endif
    bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
    base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
#endif
`;

export const premultiplied_alpha_fragment = `
#ifdef PREMULTIPLIED_ALPHA
    // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
    bloom_FragColor.rgb *= bloom_FragColor.a;
    base_FragColor.rgb *= base_FragColor.a;
#endif
    `;

export const dithering_fragment = `
#ifdef DITHERING
    bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
    base_FragColor.rgb = dithering( base_FragColor.rgb );
#endif
`;