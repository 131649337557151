import { Color, Mesh, MeshBasicMaterial, Object3D, Quaternion, SphereGeometry, Vector3, GLSL3 } from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

// Import the file path for the user's camera model
//@ts-ignore
import filePath from '../../public/assets/user_pov_clickable_nosph.fbx';

// Define a constant for the file path
const FILE_PATH = 'public/assets/user_pov_clickable_nosph.fbx';

// Create a UserCamera class that extends Object3D
export class UserCamera extends Object3D {
  private object: Object3D;
  private meshHitter: Mesh;
  private color: string;

  constructor(id: string, fov: number, color: string) {
    super();
    this.name = 'User camera ' + id;
    this.uuid = id;
    this.visible = true;
    this.color = color;

    // Load the user's camera model and create a sphere
    this.object = this.loadUserFile(color);
    this.meshHitter = this.createSphere(color);

    // Add the camera model and sphere to the UserCamera object
    this.add(this.object);
    this.add(this.meshHitter);
  }

  // Private method to load the user's camera model
  private loadUserFile(color: string): Object3D {
    const loader = new FBXLoader();
    const obj = new Object3D();

    // Load the FBX file and modify its properties
    loader.load(filePath, (obj) => {
      this.object.add(obj);

      // Traverse through the object and set material properties
      obj.traverse((obj) => {
        if ((obj as any).material) {
          (obj as any).material.color = new Color(color);
          (obj as any).material.transparent = true;
          // This editing below activates the occlusion of the POV in the bloom pass composer
          // Commented, for future references
          // (obj as any).material.onBeforeCompile = (shader: any) => {
          //   shader = this.updateShader(shader);
          // }
          (obj as any).material.needsUpdate = true;
        }
        if ((obj as any).isMesh) {
          (obj as any).material.transparent = true;
          (obj as any).material.opacity = 0; // = null;
        }
      });

      // Set the scale and position of the loaded object
      obj.scale.set(0.05, 0.05, 0.05);
      obj.position.z = 0.1;
    });
    return obj;
  }

  // Private method to create a sphere mesh
  private createSphere(color: string): Mesh {
    const g = new SphereGeometry(0.2, 10, 10);
    const m = new MeshBasicMaterial({ color: new Color(color), transparent: true });
    const mesh = new Mesh(g, m);

    // This editing below activates the occlusion of the POV in the bloom pass composer
    // Commented, for future references
    // mesh.material.onBeforeCompile = (shader: any) => {
    //   shader = this.updateShader(shader);
    // }

    // Associate the sphere mesh with this UserCamera
    (mesh as any).outlineParent = this;
    return mesh;
  }

  private updateShader(shader: any) {
    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      layout(location = 3) out vec4 id_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
      diffuseColor.a = 1.0;
      #endif
      
      #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
      #endif
      
      bloom_FragColor = vec4( outgoingLight, 0.0 );
      base_FragColor = vec4( outgoingLight, diffuseColor.a );
      depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
        bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
        base_FragColor.rgb = toneMapping( base_FragColor.rgb );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
      base_FragColor = linearToOutputTexel( base_FragColor );
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
        #ifdef FOG_EXP2
          float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
        #else
          float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
        #endif
        bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
        base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
    #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
        // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
        bloom_FragColor.rgb *= bloom_FragColor.a;
        base_FragColor.rgb *= base_FragColor.a;
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
        bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
        base_FragColor.rgb = dithering( base_FragColor.rgb );
      #endif`
    );

    return shader;
  }
}
