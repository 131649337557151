import Icon, { ImageName } from '../icons/icon/Icon';

const Overlays = ({ isDrag }: { isDrag?: boolean }) => (
  <>
    {isDrag && (
      <div className="absolute inset-0 w-full h-full opacity-40 bg-arsenic z-[2] rounded-[8px] transition-all "></div>
    )}
    <div
      className={`absolute inset-0 w-full h-full opacity-0 bg-lotion-200 z-[5] rounded-[8px] transition-all ${
        !isDrag && 'group-hover:opacity-20'
      }`}
    ></div>
  </>
);
type Props = {
  uuid?: string;
  title?: string;
  caption?: string;
  text?: string;
  thumbnailImage?: string;
  icon?: ImageName;
  selection?: {
    isChecked?: boolean;
    checking?: boolean;
  };
  time?: string | number;
  isNew?: boolean;
  isDrag?: boolean;
  onSelect?: (uuid: string | undefined) => void;
};

export default function AnimationThumbnail({
  uuid,
  title,
  caption,
  text,
  thumbnailImage,
  icon,
  selection,
  time,
  isNew,
  isDrag,
  onSelect,
}: Props) {
  return (
    <div
      className={`AnimationThumbnail flex flex-col bg-transparent group relative rounded-[8px] box-border
    ${selection !== undefined ? 'border' : ''}
    ${isDrag || selection?.isChecked ? 'border-han-blue' : ''} 
    ${isDrag ? 'p-[2px]' : ''} 
    ${!isDrag && !selection?.checking ? 'border-[#E0E0E1]' : ''}
    `}
      onClick={() => onSelect && onSelect(uuid)}
    >
      {/*       <div
        className={`flex flex-col min-h-[96px] w-[128px] items-center  bg-anti-flash-white hover:bg-alice-blue cursor-pointer relative duration-300 rounded-lg ${
          isDrag ? 'min-h-[93px]' : ''
        }  `}
      >
        <Overlays isDrag={isDrag} />

        {thumbnailImage && (
          <img src={thumbnailImage} className="absolute instet-0 h-full w-full z-1 rounded-t-lg"></img>
        )}
        {isNew && (
          <div className="flex absolute z-[3] left-0 top-0 h-[14px] w-[33px] pl-[4px] rounded-t-lg rounded-br-lg text-lotion-200 uppercase font-avenir text-[10px] items-center bg-cornflower-blue">
            new
          </div>
        )}

        <div className="flex items-center justify-center mt-[8px] h-[50px] w-[50px]">
          {icon && <Icon size={'lg'} image={icon} />}
        </div>

        <div className="mt-[3px]">
          {title && (
            <p className="px-[30px] font-works-sans text-center font-medium text-[10px] font-works-sans text-erie-black leading-[1.2]">
              {title}
            </p>
          )}
          {caption && (
            <p className="font-works-sans text-center font-medium text-[8px] font-works-sans text-granite-gray leading-[1.2]">
              {caption}
            </p>
          )}

          {!!time && (
            <div className="flex h-[16px] z-[3] p-[2px] rounded-md text-white font-avenir text-[10px] items-center bg-black bg-opacity-40 absolute left-[4px] bottom-[4px]">
              {time}
            </div>
          )}
        </div>
      </div> */}
      {text && (
        <div className="flex h-[19px] justify-between items-center">
          {text && <span className="pl-[8px] text-[10px] text-erie-black whitespace-nowrap text-left">{text}</span>}
          {!isDrag && (
            <div className="pr-[2px] pb-[2px]">
              {selection?.isChecked ? (
                <Icon size="sm" imageSize="sm" image="is-checked" />
              ) : (
                <div className="opacity-0 group-hover:opacity-100">
                  <Icon size="sm" imageSize="sm" image="thumbnail-selector" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
