import { GLSL3 } from 'three';

export function updateShader(isHoverable: number, shader: any, uniforms: any) {

  //

  // SHADERS
  
  const common = `#include <common>
  uniform float isHovered;
  layout(location = 0) out vec4 base_FragColor;
  layout(location = 1) out vec4 bloom_FragColor;
  layout(location = 2) out vec4 depth_FragColor;
  layout(location = 3) out vec4 id_FragColor;
  `;

  const opaque_fragment = `#ifdef OPAQUE
  diffuseColor.a = 1.0;
  #endif
  
  #ifdef USE_TRANSMISSION
  diffuseColor.a *= material.transmissionAlpha;
  #endif

  vec4 objectColor = vec4( outgoingLight, diffuseColor.a );

  vec4 hoveringColor = vec4(1.0);
  
  base_FragColor = mix(objectColor, hoveringColor, isHovered * float(${isHoverable}));//vec4( outgoingLight, diffuseColor.a );
  bloom_FragColor = vec4( outgoingLight, 0.0 );
  depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
  id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
  `;

  const tonemapping_fragment = `#if defined( TONE_MAPPING )
    bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
    base_FragColor.rgb = toneMapping( base_FragColor.rgb );
  #endif`;

  const colorspace_fragment = `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
  base_FragColor = linearToOutputTexel( base_FragColor );
  `;

  const fog_fragment = `#ifdef USE_FOG
      #ifdef FOG_EXP2
        float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
      #else
        float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
      #endif
      bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
      base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
  #endif`;

  const premultiplied_alpha_fragment = `#ifdef PREMULTIPLIED_ALPHA
    // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
    bloom_FragColor.rgb *= bloom_FragColor.a;
    base_FragColor.rgb *= base_FragColor.a;
  #endif`;

  const dithering_fragment = `#ifdef DITHERING
    bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
    base_FragColor.rgb = dithering( base_FragColor.rgb );
  #endif`;

  //

  //

  // Specify the GLSL version
  shader.glslVersion = GLSL3;

  shader.uniforms = {
    ...shader.uniforms,
    ...uniforms,
  };

  // Modify the fragment shader to include custom code for the modified render pipeline
  shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, common);
  shader.fragmentShader = shader.fragmentShader.replace(`#include <opaque_fragment>`, opaque_fragment);
  shader.fragmentShader = shader.fragmentShader.replace(`#include <tonemapping_fragment>`, tonemapping_fragment);
  shader.fragmentShader = shader.fragmentShader.replace(`#include <colorspace_fragment>`, colorspace_fragment);
  shader.fragmentShader = shader.fragmentShader.replace(`#include <fog_fragment>`, fog_fragment);
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <premultiplied_alpha_fragment>`,
    premultiplied_alpha_fragment
  );
  shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, dithering_fragment);

  return shader;
}
