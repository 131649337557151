import {
  AnimationClip,
  AnimationMixer,
  Clock,
  Group,
  Light,
  Mesh,
  Object3D,
  Quaternion,
  Sphere,
  Vector3,
  GLSL3,
  AnimationAction,
  Raycaster,
  MeshBasicMaterial,
  BufferGeometry,
  LineSegments,
  LineBasicMaterial,
  EdgesGeometry,
  BoxGeometry,
  Color,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';

import { AssetModelLoaded } from '@radical/canvas-fe-types';
import { AssetLoadIndicator } from './AssetLoadIndicator';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { AssetModelInfo, AssetObjectType, FileTypes } from '@radical/canvas-types';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

import {
  computeBoundsTree,
  disposeBoundsTree,
  computeBatchedBoundsTree,
  disposeBatchedBoundsTree,
  acceleratedRaycast,
  MeshBVHHelper,
  MeshBVH,
} from 'three-mesh-bvh';

// Define types for tracking asset loading status.
type AssetLoading = {
  uuid: string;
  asset: AssetObject;
  resolve: Function;
  reject: Function;
};

// Initialize collections to manage loading and loaded assets.
const loadingAssets = new Array<AssetLoading>();
const loadedAssets = new Map<string, { scene: Mesh | Group | Object3D; animations?: AnimationClip[] }>();

// The AssetObject class extends the Three.js Object3D class, adding functionalities
// specific to the management of 3D assets within the Canvas editor. This class allows
// for the loading, manipulation, and display of 3D models, including handling their
// animations, transformations, and loading states. It implements the AssetObjectType
// interface to ensure consistency with the expected structure and functionalities
// of asset objects within the application.
export class AssetObject extends Object3D implements AssetObjectType {
  private assetModel: AssetModelLoaded | undefined; // The loaded 3D model associated with this asset object.
  private assetInfo: AssetModelInfo | undefined; // Metadata and additional information about the asset

  private assetScene: Object3D | undefined; // A reference to the actual 3D scene or object that represents the asset within the Three.js environment.
  private innerGroup: Group; // An inner Group object to facilitate complex transformations and nesting of 3D objects.
  private outerGroup: Group; // An outer Group that wraps the innerGroup, providing an additional layer of transformation.

  private locked: boolean = false; // A flag indicating whether the asset is locked. A locked asset might not be movable or editable in the editor.

  private formatVersion: number = 2; // A version indicator for the asset, which could be used to handle assets differently based on their version.
  private isLegacyAsset: boolean = false; // A flag to indicate whether the asset is considered a legacy asset.

  private loadingIndicator: AssetLoadIndicator | undefined; // An optional loading indicator that can be displayed while the asset is being loaded into the scene.

  private boundingSphere: Sphere | undefined; // The bounding sphere of the asset.

  // Animation-related fields
  private frameId: number = 0; // Used to manage the requestAnimationFrame loop for animations.
  private mixer: AnimationMixer | undefined; // The AnimationMixer object from Three.js.
  private clock: Clock | undefined; // A Clock object to keep track of the time elapsed.

  // Uniforms for the shader of this object
  private uniforms: {
    bloomLayer: { value: number };
    idColor: { value: Vector3 };
    isSelected: { value: number };
    isHovered: { value: number };
  };

  //

  // Raycaster for snapping
  public snapRaycaster: Raycaster;

  public bvhVisualizer: MeshBVHHelper | null = null;

  //

  private selectionBox: LineSegments | null = null;

  //

  private selected: boolean = false;

  //

  private hoveredMaterials = new Map<string, any>();
  private originalMaterials = new Map<string, any>();

  constructor(id: string) {
    super(); // Call the constructor of the Object3D superclass.

    this.uuid = id; // Assign the unique identifier to this asset object.

    // Initialize the inner and outer group objects.
    this.outerGroup = new Group();
    this.innerGroup = new Group();

    // Nest the inner group within the outer group, and add the outer group to this asset object.
    this.outerGroup.add(this.innerGroup);
    this.add(this.outerGroup);

    // Set the uniforms
    this.uniforms = {
      bloomLayer: { value: 0.0 },
      idColor: { value: new Vector3(Math.random(), Math.random(), Math.random()) },
      isSelected: { value: 0.0 },
      isHovered: { value: 0.0 },
    };

    // console.log(this.uniforms);

    //

    //Initialize the raycaster for snap
    this.snapRaycaster = new Raycaster();

    //

    // Add the extension functions
    BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    Mesh.prototype.raycast = acceleratedRaycast;
    // LineSegments.prototype.raycast = acceleratedRaycast;
    // Object3D.prototype.raycast = acceleratedRaycast;
  }

  // Applies position and scale offsets to the asset, based on its metadata.
  private applyOffsets() {
    if (!this.assetInfo?.meta?.offsets) return;

    const { scale, position } = this.assetInfo.meta.offsets;

    if (this.formatVersion === 1) {
      this.outerGroup.scale.set(1, 1, 1);
    } else {
      this.outerGroup.scale.set(scale.x, scale.y, scale.z);
    }

    this.innerGroup.position.set(position.x, position.y, position.z);
  }

  // Determines the format version of the asset, which affects how it's processed.
  private determineFormatVersion() {
    if (!this.assetInfo) return;

    const isSiggraphAsiaAsset = this.assetInfo.owner === '106780';

    const uploadedAt = new Date(this.assetInfo.updatedAt as unknown as string);
    const newYear = new Date('2024-01-01T00:00:00.000Z');

    this.isLegacyAsset = isSiggraphAsiaAsset || uploadedAt.getTime() < newYear.getTime();

    this.formatVersion = this.isLegacyAsset ? 1 : 2;
  }

  // Public method to set the asset model and info, and apply relevant transformations.
  public setAssetModel(assetModel: AssetModelLoaded, assetInfo: AssetModelInfo) {
    this.assetModel = assetModel;
    this.assetInfo = assetInfo;

    this.determineFormatVersion();

    this.applyOffsets();
  }

  // Sets the position, scale, and orientation of the asset.
  public setTransforms(position: Vector3, scale: Vector3, quaternion: Quaternion) {
    this.position.copy(position);
    this.scale.copy(scale);
    this.quaternion.copy(quaternion);
  }

  // Accessor methods for asset model and info.
  public getAssetModel(): AssetModelLoaded | undefined {
    return this.assetModel;
  }

  public getAssetInfo(): AssetModelInfo | undefined {
    return this.assetInfo;
  }

  // Bounding sphere management for the asset.
  public getBoundingSphere(): Sphere | undefined {
    return this.boundingSphere;
  }

  public setBoundingSphere(o: Sphere) {
    this.boundingSphere = o;
  }

  // Cleanup method to be called when the asset is no longer needed.
  public cleanup() {}

  // Locks or unlocks the asset to prevent or allow transformations.
  public setLocked(lock: boolean) {
    this.locked = lock;
    if (this.locked && this.selectionBox) this.selectionBox.visible = false;
  }

  public isLocked(): boolean {
    return this.locked;
  }

  // Shows a loading indicator while the asset is being loaded.
  public showLoader() {
    if (!this.assetInfo) return;

    this.loadingIndicator = new AssetLoadIndicator(this.assetInfo);

    this.innerGroup.add(this.loadingIndicator);
  }

  public generateSelectionBox() {
    // @ts-ignore
    const { boundingBox, boxCenter } = this.assetInfo;

    const edgeGeom = new EdgesGeometry(new BoxGeometry(boundingBox.x, boundingBox.y, boundingBox.z));

    const edgeMat = new LineBasicMaterial({
      color: 0xffffff, // Set the color of the frame.
      linewidth: 1, // Set the line width of the frame.
    });

    this.selectionBox = new LineSegments(edgeGeom, edgeMat);

    // Position the frame at the center of the asset's bounding box.
    this.selectionBox.position.x = boxCenter.x;
    this.selectionBox.position.y = boxCenter.y;
    this.selectionBox.position.z = boxCenter.z;

    this.selectionBox.visible = this.selected;
    this.selectionBox.raycast = function () {};

    // Add the frame to the Group, making it part of the selector indicator.
    this.innerGroup.add(this.selectionBox);
  }

  // Removes the loading indicator once the asset is loaded.
  public removeLoader() {
    if (this.loadingIndicator) {
      this.innerGroup.remove(this.loadingIndicator);
    }
  }

  // Handles asset post-load operations, including setting up animations.
  public onAssetLoaded(object: Mesh | Group, animations: AnimationClip[]) {
    // Remove the loading indicator from the UI to signal that the asset has finished loading.
    this.removeLoader();

    this.assetScene = object; // Store the loaded 3D object (which could be a Mesh or a Group of objects) as the asset's scene representation.
    this.innerGroup.add(this.assetScene); // Add the loaded asset to the innerGroup to adhere to the established group hierarchy within the AssetObject.

    // Traverse through all child objects of the loaded asset to apply default settings and transformations.
    this.assetScene.traverse((child: any) => {
      // For each child that is an instance of Object3D, set a custom property 'outlineParent' to reference the AssetObject.
      if (child instanceof Object3D) {
        (child as any).outlineParent = this;
      }

      if (child.isMesh) {
        child.geometry.boundsTree = new MeshBVH(child.geometry, {
          maxLeafTris: 50,
          maxDepth: 10,
        });

        if (window.__ENV__.ENABLE_BVH_HELPERS) {
          const bvh = new MeshBVHHelper(child, child.geometry.boundsTree);
          // bvh.update();
          // console.log(this, scene, child, bvh);
          child.parent.add(bvh);
        }

        // console.log('creating bvh here');
      }

      // Adjust material properties for child objects that have a material defined.
      if (child.material) {
        // TODO: Why are we disabling shadow casting here?
        // Yeah indeed, why? I lost 3 hours to figure this out lol
        child.castShadow = true;
        child.receiveShadow = true;
        // child.material.envMapIntensity = 1;
        if (child.material.emissiveIntensity > 1.0) {
          child.layers.enable(2);
        }
        // child.material.customProgramCacheKey = function () {
        //   // Return a unique key for this material instance
        //   return 'custom_key_' + Math.random();
        // };
        if (child.material.emissiveMap) {
          // child.material.emissiveMap = null;
          // child.material.emissiveIntensity = 0;
          child.material.needsUpdate = true;
        }

        const hoveredMaterial = child.material.clone();

        if (
          hoveredMaterial.type === 'MeshStandardMaterial' ||
          hoveredMaterial.type === 'MeshPhongMaterial' ||
          hoveredMaterial.type === 'MeshLambertMaterial'
        ) {
          hoveredMaterial.emissive = new Color('#ffffff');
          hoveredMaterial.emissiveIntensity = 0.1;
        } else {
          hoveredMaterial.opacity = 0.25;
          hoveredMaterial.transparent = true;
        }
        hoveredMaterial.needsUpdate = true;

        this.hoveredMaterials.set(child.uuid, hoveredMaterial);
        this.originalMaterials.set(child.uuid, child.material);
        child.material.onBeforeCompile = this.onBeforeCompile;
        hoveredMaterial.onBeforeCompile = this.onBeforeCompile;

        // child.material.opacity = 1;
      }

      // TODO: Should we remove this and retain original light intensity?
      // For child objects that are instances of Light, reduce their intensity by a factor of 100.
      if (child instanceof Light) {
        child.intensity = child.intensity / 100;
      }
    });

    //

    // If animations are provided with the asset, set up an AnimationMixer to manage these animations.
    if (animations.length > 0) {
      this.clock = new Clock();
      const animationActions: AnimationAction[] = [];
      this.mixer = new AnimationMixer(this.assetScene);

      // For each animation clip, create an animation action using the mixer and add it to the animationActions array.
      animations.forEach((anim) => {
        this.mixer && animationActions.push(this.mixer.clipAction(anim));
      });

      // Start playing the first animation action.
      animationActions[0]?.play();
    }

    //
    this.generateSelectionBox();

    // If the asset was previously marked as locked, ensure it remains locked after loading.
    // if (this.locked) this.setLocked(this.locked);
  }

  // Utility methods to check the asset's loading status.
  public isAssetLoaded(fileName: string): boolean {
    return loadedAssets.has(fileName);
  }

  public isAssetLoading(uuid: string): boolean {
    // console.log('loading assets: ', loadingAssets);
    return loadingAssets.find((a) => a.uuid === uuid) !== undefined;
  }

  public isAssetProcessed(fileName: string): boolean {
    return this.isAssetLoaded(fileName) || this.isAssetLoading(fileName);
  }

  // Updates the loading progress of the asset.
  public setLoadProgress(pr: number) {
    if (!this.loadingIndicator) return;

    this.loadingIndicator.setLoadProgress(pr);
  }

  // Initiates loading the asset from a URL, handling caching and cloning of loaded assets.
  public async loadFromUrl(asset: AssetModelInfo): Promise<boolean> {
    // Check if already loaded and extract UUID and URL from the asset information.
    const { modelUuid, url } = asset;

    // Check if the asset has already been loaded to avoid redundant loading.
    if (loadedAssets.has(modelUuid)) {
      const loadedAsset = loadedAssets.get(modelUuid);
      if (loadedAsset) {
        return new Promise((resolve) => {
          // If the asset is already loaded, use the existing asset, clone it to maintain immutability, and resolve immediately.
          this.onAssetLoaded(
            // @ts-ignore
            SkeletonUtils.clone(loadedAsset.scene),
            loadedAsset.animations
          );

          resolve(true);
        });
      }
    }

    // Display the loader UI component to indicate that asset loading is in progress.
    this.showLoader();

    // //
    // this.generateSelectionBox();

    // Check if the asset is currently being loaded to prevent duplicate loading processes.
    const existingLoader = loadingAssets.find((a) => a.uuid === modelUuid);
    if (existingLoader) {
      // If the asset is already being loaded, just return a new promise that will be resolved or rejected accordingly.
      return new Promise((resolve, reject) => {
        loadingAssets.push({ uuid: modelUuid, asset: this, resolve: resolve, reject: reject });
      });
    } else {
      // If the asset is not being loaded, add a placeholder to the loading assets to indicate that loading has started.
      loadingAssets.push({ uuid: modelUuid, asset: this, resolve: () => {}, reject: () => {} });
    }

    // Return a new promise that will be resolved or rejected based on the loading outcome.
    return new Promise(
      (resolve, reject) => {
        if (url === undefined) {
          // If no URL is provided, reject the promise immediately.
          reject('No URL to load');
          return;
        }

        // Update the resolve and reject functions for all loaders associated with this asset.
        loadingAssets.forEach((loader) => {
          if (loader.uuid === modelUuid) {
            loader.resolve = resolve;
            loader.reject = reject;
          }
        });

        const onProgress = (progr: ProgressEvent<EventTarget>) => {
          // Update the loading progress based on the event's loaded and total values.
          const { loaded, total } = progr;
          let pr = 0;
          if (total && loaded) pr = Math.round((loaded * 100) / total) / 100;

          loadingAssets.forEach((loadInfo) => {
            if (loadInfo.uuid === modelUuid) {
              loadInfo.asset.setLoadProgress(pr);
            }
          });
        };

        const onError = (err: unknown) => {
          // On error, reject all loaders associated with this asset.
          const remainingLoaders: AssetLoading[] = [];

          // Reject all promises
          loadingAssets.forEach((loader) => {
            if (loader.uuid === modelUuid) {
              loader.reject(err);
            } else {
              remainingLoaders.push(loader);
            }
          });

          // Remove from loadingAssets
          loadingAssets.splice(0, loadingAssets.length, ...remainingLoaders);
        };

        const onLoaded = (scene: Group | Object3D | Mesh, animations?: AnimationClip[]) => {
          // Edit the shader of the material
          // This is to handle the selective bloom on this object
          // console.log('Loaded scene: ', scene);
          // So we can isolate objects not needing bloom

          scene.traverse((child: any) => {
            // Check if the current object is a mesh
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;

              // child.geometry.boundsTree = new MeshBVH(child.geometry, {
              //   maxLeafTris: 50,
              //   maxDepth: 10,
              // });

              if (child.material?.type === 'MeshLambertMaterial') {
                child.material = new MeshBasicMaterial({
                  color: child.material.color,
                  map: child.material.map,
                });

                // if (child.geometry) {
                //   // child.geometry.computeFaceNormals();
                //   child.geometry.computeVertexNormals();
                // }
              }

              // Only apply shader change for now if material is not of type: PhongMaterial
              // if (child.material && child.material.isMeshPhongMaterial) return;
              // Modify the shader used by the mesh's material
              // child.material.onBeforeCompile = this.onBeforeCompile;

              // If the emissive intensity of the material is high, set the bloomLayer uniform to 1.0 to enable bloom
              if (child.material.emissiveIntensity > 1) this.uniforms.bloomLayer.value = 1.0;
            }
          });

          // Once loaded, cache the asset for future use and resolve the promise.
          loadedAssets.set(modelUuid, { scene: scene, animations: animations || [] });
          const remainingLoaders: AssetLoading[] = [];
          // Notify all loaders that the asset has been loaded.
          loadingAssets.forEach((loader) => {
            if (loader.uuid === modelUuid) {
              loader.asset.onAssetLoaded(
                // @ts-ignore
                SkeletonUtils.clone(scene),
                animations
              );
              loader.resolve(true);
            } else {
              remainingLoaders.push(loader);
            }
          });

          // Remove from loadingAssets
          loadingAssets.splice(0, loadingAssets.length, ...remainingLoaders);
        };

        // console.log('Loading asset: ', asset);
        // TODO: Add OBJ & FBX loaders
        switch (asset.fileType) {
          case FileTypes.GLB:
            // Initialize the GLTFLoader to load the asset from the provided URL.
            const gltfLoader = new GLTFLoader();
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
            gltfLoader.setDRACOLoader(dracoLoader);
            gltfLoader.load(
              url,
              (glb) => {
                onLoaded(glb.scene, glb.animations);
              },
              onProgress, // on progress
              onError
            );
            break;
          case FileTypes.FBX:
            const fbxLoader = new FBXLoader();
            fbxLoader.load(
              url,
              (scene) => {
                onLoaded(scene, scene.animations);
              },
              onProgress,
              onError
            );
            break;
          case FileTypes.OBJ:
            const objLoader = new OBJLoader();
            objLoader.load(
              url,
              (scene) => {
                onLoaded(scene, scene.animations);
              },
              onProgress,
              onError
            );
            break;
          default:
            reject('Unknown file type');
        }
      }

      // Use the GLTFLoader to load the asset.
    );
  }

  //

  // Set the amount of color for this object
  // Useful when using the Bloom to isolate this object
  // When isolated (bloomLayer = 0) this object doesn't get bloom but keeps occlusion
  public setColorAmount(value: number) {
    this.uniforms.bloomLayer.value = value;
  }

  public getUniforms() {
    return this.uniforms;
  }

  //

  public isChar(): boolean {
    return false;
  }

  // Animation loop to update the asset's animations over time.
  private animate(n: number) {
    if (this.mixer) {
      const delta = this.clock ? this.clock.getDelta() : 0;
      this.mixer.update(delta);
    }

    this.frameId = requestAnimationFrame(this.animate.bind(this));
  }

  // Starts the animation loop.
  private start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate.bind(this));
    }
  }

  // Stops the animation loop.
  private stop() {
    cancelAnimationFrame(this.frameId);
  }

  //

  public snapToBottom(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    //

    // Assets intersection
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    let closerAsset;
    let closerChar;
    let closerObject;

    if (intersectAssets.length > 0) {
      // console.log(intersectAssets[0]);
      // this.position.set(0, 0, 0);
      // this.lookAt(intersectAssets[0].face.normal);
      // const { x, y, z } = intersectAssets[0].point;
      // this.position.set(x, y, z);
      closerAsset = intersectAssets[0];
    }

    //

    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);
    if (intersectChars.length > 0) {
      // console.log(intersectChars[0]);
      // const { x, y, z } = intersectChars[0].point;
      // this.position.set(x, y, z);
      closerChar = intersectChars[0];
    }

    if (closerAsset && closerChar) {
      closerObject = closerAsset.distance > closerChar.distance ? closerChar : closerObject;
    } else if (closerAsset) {
      closerObject = closerAsset;
    } else if (closerChar) {
      closerObject = closerChar;
    }

    if (closerObject) {
      const { x, y, z } = closerObject.point;
      this.position.set(x, y, z);
    }
  }

  public snapToObjectBelow(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    // Assets intersection
    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);
    const intersectOthers = this.snapRaycaster.intersectObjects(hitters.others);
    //const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    // Merge the intersected objects into a single array
    let intersectedObjects = [...intersectChars, ...intersectAssets, ...intersectOthers];

    // Function to find the object with the shortest distance
    const findClosestObject = (array: any) => {
      return array.reduce((min: any, obj: any) => (obj.distance < min.distance ? obj : min), array[0]);
    };

    if (intersectedObjects.length > 0) {
      // Find the closer object for snapping
      const closestObject = findClosestObject(intersectedObjects);

      if (closestObject.distance < 0.05) {
        // Remove the closest object from the array
        intersectedObjects = intersectedObjects.filter((obj) => obj.distance !== closestObject.distance);
      } else {
        const { x, y, z } = closestObject.point;
        this.position.set(x, y, z);
      }
    }
  }

  public snapToObjectAbove(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, 1000, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    // Assets intersection
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    if (intersectAssets.length > 0) {
      let index = 0;
      // console.log(intersectAssets[index]);
      while (intersectAssets[index].distance <= 0.05 && index < intersectAssets.length - 1) {
        // console.log(intersectAssets[index]);
        index++;
      }
      const { x, y, z } = intersectAssets[index].point;
      this.position.set(x, y, z);
      return;
    }
  }

  private onBeforeCompile = (shader: any, child: any) => {
    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    // Merge existing shader uniforms with additional custom uniforms
    shader.uniforms = {
      ...shader.uniforms,
      ...this.uniforms,
    };

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      uniform float bloomLayer;
      uniform vec3 idColor;
      uniform float isSelected;
      uniform float isHovered;
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      layout(location = 3) out vec4 id_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
      diffuseColor.a = 1.0;
      #endif
      
      #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
      #endif

      float cameraNear = 0.1; //hard coded for now
      float cameraFar = 40.0;
      float depth = gl_FragCoord.z / gl_FragCoord.w;
      float depthMapped = smoothstep(cameraNear, cameraFar, depth);

      vec4 objectColor = vec4( outgoingLight, diffuseColor.a );

      // float intensity = pow( 0.7 - dot( vNormal, vec3( 0.0, 0.0, -1.0 ) ), 200.0 ); 
      // vec4 hoveringColor = normalize(vec4(intensity));
      // // hoveringColor = step(vec4(0.5), hoveringColor);

      vec4 hoveringColor = vec4(1.0);
      
      base_FragColor = mix(objectColor, hoveringColor, isHovered);//vec4( outgoingLight, diffuseColor.a );
      // base_FragColor += vec4(0.5) * isSelected;
      bloom_FragColor = vec4( outgoingLight * bloomLayer, diffuseColor.a );
      depth_FragColor = vec4( depthMapped, depthMapped, depthMapped, diffuseColor.a );
      id_FragColor = vec4( idColor.rgb, diffuseColor.a );
                  `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
        bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
        base_FragColor.rgb = toneMapping( base_FragColor.rgb );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
                  base_FragColor = linearToOutputTexel( base_FragColor );
                  `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
                    #ifdef FOG_EXP2
                      float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
                    #else
                      float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
                    #endif
                    bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
                    base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
                #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
                    // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
                    bloom_FragColor.rgb *= bloom_FragColor.a;
                    base_FragColor.rgb *= base_FragColor.a;
                  #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
                    bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
                    base_FragColor.rgb = dithering( base_FragColor.rgb );
                  #endif`
    );
  };

  public setIsHovered(value: number) {
    // this.uniforms.isHovered.value = this.locked ? 0.0 : value;
    // let i = 0;
    this.traverse((child: any) => {
      if (child.isMesh && child.material) {
        child.material = value > 0 ? this.hoveredMaterials.get(child.uuid) : this.originalMaterials.get(child.uuid);
      }
    });
  }

  public setIsSelected(value: boolean) {
    this.selected = value;

    if (this.selectionBox) this.selectionBox.visible = value;
  }

  public getIsSelected(): boolean {
    return this.selected;
  }

  public setSelectionBoxColor(value: string | null) {
    if (this.selectionBox) {
      (this.selectionBox.material as LineBasicMaterial).color = new Color(value ? value : '#ffffff');
      (this.selectionBox.material as LineBasicMaterial).needsUpdate = true;
    }
  }
}
