import { useDrag, useDragDropManager } from 'react-dnd';
import './SceneCameraBoard.css';
import classic from './assets/classic.svg';
import midTelephoto from './assets/mid-telephoto.svg';
import standard from './assets/standard.svg';
import superTelephoto from './assets/super-telephoto.svg';
import superWide from './assets/superwide.svg';
import telephoto from './assets/telephoto.svg';
import ultrawide from './assets/ultra-wide.svg';
import wide from './assets/wide.svg';
import { FC, useEffect, useState } from 'react';
import { useSceneAssets } from '../../scene-assets.state';
import type { CinemaicCameraType, DragAsset } from '@radical/canvas-fe-types';

export const cinematicCameras: CinemaicCameraType[] = [
  {
    icon: superWide,
    name: 'Super wide',
    mm: '12 mm',
    fov: 12,
  },
  {
    icon: ultrawide,
    name: 'Ultra Wide',
    mm: '17 mm',
    fov: 17,
  },
  {
    icon: wide,
    name: 'Wide',
    mm: '24 mm',
    fov: 24,
  },
  {
    icon: standard,
    name: 'Standard',
    mm: '35 mm',
    fov: 35,
  },
  {
    icon: classic,
    name: 'Classic',
    mm: '50 mm',
    fov: 50,
  },
  {
    icon: telephoto,
    name: 'Telephoto',
    mm: '85 mm',
    fov: 85,
  },
  {
    icon: midTelephoto,
    name: 'Mid telephoto',
    mm: '105 mm',
    fov: 105,
  },
  {
    icon: superTelephoto,
    name: 'Super telephoto',
    mm: '200 mm',
    fov: 200,
  },
];

if (window.__ENV__['ENABLE_CAMERA_ZOOM'] === true) {
  cinematicCameras.push({ icon: classic, name: 'Zoom', mm: 'zoom', fov: 35 });
}

export const SceneCameraBoard = () => {
  const dragState = useSceneAssets().drag;
  const { setDragging, setPosition } = useSceneAssets();

  return (
    <div className="SceneCameraBoard h-full w-full flex justify-evenly items-center gap-[16px] pointer-events-auto flex-wrap ">
      {cinematicCameras.map((camera) => (
        <CameraBoxWithImage camera={camera} setDragging={setDragging} setPosition={setPosition} key={camera.name} />
      ))}
    </div>
  );
};

type CameraBoxWithImageProps = {
  camera: CinemaicCameraType;
  setPosition: (x: number, y: number) => void;
  setDragging: (da: DragAsset) => void;
};

function CameraBoxWithImage({ camera, setPosition, setDragging }: CameraBoxWithImageProps): JSX.Element {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const [dragg, setDragg] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'camera',
    // item: { type: 'camera' },
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging();
      document.body.style.cursor = isDragging ? 'grabbing' : 'default';
      return { isDragging };
    },
    item: () => {
      setDragg(true);
      return { type: 'camera' };
    },
    end: () => {
      setDragg(false);
    },
  }));

  useEffect(() => {
    monitor.subscribeToOffsetChange(() => {
      // do stuff like setState, though consider directly updating style through refs for performance
      const offset = monitor.getClientOffset();
      // const distance = monitor.getDifferenceFromInitialOffset();
      if (offset) setPosition(offset.x, offset.y);
      // if (distance) setDistance({ x: distance.x, y: distance.y });
    });
  }, [monitor]);

  useEffect(() => {
    if (dragg) {
      // console.log('Started asset drag');
      setDragging({ dragging: true, camera: camera });
      // Analytics.event('CANVAS/asset_add', { name: a.asset.name });
    } else {
      // console.log('stopped dragggg');
      setDragging({ dragging: false, camera: undefined });
    }
  }, [dragg]);

  return (
    <div key={camera.name + camera.fov} ref={drag}>
      <div
        className={`camera-container w-[110px] h-[110px] flex flex-col justify-center items-center 
        pointer-events-auto ${
          isDragging ? 'cursor-grabbing' : 'cursor-grab'
        } bg-[#f2f2f2] hover:bg-lotion p-[4px] rounded-[8px] overflow-hidden shrink-0`}
      >
        <img src={camera.icon} alt={camera.name} className="mb-[10px]" />

        <p className="text-[13px] text-[#555] font-semibold">{camera.name}</p>
        <p className="text-[10px] text-[#A4A4A4] font-semibold mt-[6px]">{camera.mm}</p>
      </div>
    </div>
  );
}
