import { LightInfo, LightType } from '@radical/canvas-types';
import {
  SpotLight,
  SpotLightHelper,
  ColorRepresentation,
  Color,
  Sprite,
  TextureLoader,
  SpriteMaterial,
  Object3D,
  Raycaster,
  Vector3,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  GLSL3,
  LineSegments,
  EdgesGeometry,
  BoxGeometry,
  LineBasicMaterial,
} from 'three';

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

// Import the file path for the user's camera model
//@ts-ignore
import filePath from '../../public/assets/lightHelpers/spotlight_curve_n_geolamp.fbx';
// import filePath from '../../public/assets/camera_object_curve_bodyonly.fbx';

class RadicalSpotLight extends SpotLight {
  public helper: SpotLightHelper;

  private icon: Sprite = new Sprite();
  private showHelper: boolean;
  private showIcon: boolean;
  private locked: boolean = false;
  private snapRaycaster: Raycaster;

  private meshHitter: Mesh;
  private ltarget: Object3D;
  private object: Object3D;

  public isFree: boolean = true;

  private userMaterials: any[] = [];

  private uniforms: {
    isHovered: { value: number };
  };

  private selectionBox: LineSegments | null = null;

  /**
   * Constructor for the RadicalSpotLight class.s
   * @param color - The color of the spotlight.
   * @param intensity - The intensity of the spotlight.
   * @param distance - The maximum range of the spotlight.
   * @param angle - The angle of the spotlight's cone.
   * @param penumbra - The penumbra of the spotlight.
   * @param decay - The decay rate of the spotlight.
   * @param iconUrl - The URL of the icon texture.
   * @param uuid - The UUID of the object
   */
  constructor(
    color: ColorRepresentation,
    intensity: number,
    distance: number,
    angle: number,
    penumbra: number,
    decay: number,
    castShadow: boolean,
    isLocked: boolean,
    iconUrl: string,
    uuid: string
  ) {
    super(color, intensity, distance, angle, penumbra, decay);

    // Set this uuid
    // this.uuid = uuid;

    // Create and initialize the spotlight helper and icon.
    this.helper = new SpotLightHelper(this);
    // console.log(this.helper)
    // this.add(this.helper);
    // this.icon = this.createIcon(iconUrl);
    this.showHelper = true;
    this.showIcon = true;

    this.meshHitter = this.createSphere('#000000');
    this.meshHitter.renderOrder = 1;

    this.add(this.meshHitter);

    this.object = this.loadUserFile(color); //new RadicalPointLightHelper(this); // new PointLightHelper(this);
    this.object.position.y += 0.3;
    this.add(this.object);

    // Default behavior doesn't cast shadows
    this.castShadow = castShadow;
    this.setShadowResolution(256);

    // If the asset was previously marked as locked, ensure it remains locked after loading.
    this.locked = isLocked;
    if (this.locked) this.setLocked(this.locked);

    //Initialize the raycaster for snap
    this.snapRaycaster = new Raycaster();

    this.ltarget = new Object3D();
    this.add(this.ltarget);
    this.ltarget.position.y = -20;
    this.target = this.ltarget;
    // this.shadow.normalBias = 0.01;
    // this.shadow.bias = 0.1;

    this.uniforms = {
      isHovered: { value: 0.0 },
    };

    this.generateSelectionBox();
  }

  public disposeLight() {
    if (this.helper) {
      this.helper.visible = false;
      this.helper.dispose();
    }
    this.dispose();
  }

  /**
   * Sets the intensity of the spotlight.
   * @param value - The new intensity value.
   */
  public setIntensity(value: number): void {
    this.intensity = value;
    this.helper.update();
  }

  /**
   * Sets the color of the spotlight.
   * @param value - The new color value.
   */
  public setColor(value: ColorRepresentation): void {
    this.color = new Color(value);
    this.userMaterials.forEach((material) => {
      (material as any).color = new Color(value);
      (material as any).emissive = new Color(value);
      (material as any).needsUpdate = true;
    });
    this.helper.update();
  }

  /**
   * Sets the distance of the spotlight.
   * @param value - The new distance value.
   */
  public setDistance(value: number): void {
    this.distance = value;
    this.helper.update();
  }

  /**
   * Sets the angle of the spotlight.
   * @param value - The new angle value.
   */
  public setAngle(value: number): void {
    this.angle = value;
    this.helper.update();
  }

  /**
   * Sets the penumbra of the spotlight.
   * @param value - The new penumbra value.
   */
  public setPenumbra(value: number): void {
    this.penumbra = value;
    this.helper.update();
  }

  /**
   * Sets the decay rate of the spotlight.
   * @param value - The new decay value.
   */
  public setDecay(value: number): void {
    this.decay = value;
    this.helper.update();
  }

  /**
   * Locks or unlocks the asset to prevent or allow transformations.
   * @param lock - Wheter to lock it or hide
   */
  public setLocked(lock: boolean) {
    this.locked = lock;
  }

  public setHelperVisibility(isVisible: boolean) {
    this.helper && (this.helper.visible = isVisible);
  }

  public setObjectVisibility(isVisible: boolean) {
    this.object && (this.object.visible = isVisible);
  }

  /**
   * Toggles the visibility of the spotlight helper.
   * @param show - Whether to show or hide the helper.
   */
  public toggleHelper(show: boolean): void {
    this.showHelper = show;
    this.helper && (this.helper.visible = this.showHelper);
    this.object && (this.object.visible = this.showHelper);
    this.meshHitter && (this.meshHitter.visible = this.showHelper);
    if (this.showHelper) {
      this.add(this.meshHitter);
      this.helper.update();
    } else {
      this.remove(this.meshHitter);
    }
  }

  /**
   * Sets whether the point light casts shadows.
   * @param isShadowCasting - Whether the light should cast shadows.
   */
  public setCastShadow(isShadowCasting: boolean): void {
    this.castShadow = isShadowCasting;
  }

  /**
   * Sets the resolution of the shadow map.
   * @param resolution - The resolution of the shadow map.
   */
  public setShadowResolution(resolution: number): void {
    this.shadow.mapSize.width = resolution;
    this.shadow.mapSize.height = resolution;
    // this.shadow.camera.near = 0.1;
    this.shadow.camera.far = 20;
    // this.shadow.focus = 1;
    this.shadow.bias = -0.0005;
    this.shadow.normalBias = 0.01;
  }

  /**
   * Toggles the visibility of the spotlight icon.
   * @param show - Whether to show or hide the icon.
   */
  public toggleIcon(show: boolean): void {
    this.showIcon = show;
    this.icon && (this.icon.visible = this.showIcon);
  }

  //
  //
  // GETTERS

  public isLocked(): boolean {
    return this.locked;
  }

  public getHitter(): Mesh {
    return this.meshHitter;
  }

  public getAssetInfo(): LightInfo {
    const lightInfoObject: LightInfo = {
      castShadow: this.castShadow,
      color: '#' + this.color.getHexString(),
      decay: this.decay,
      distance: this.distance,
      info: {
        position: this.position,
        quaternion: this.quaternion,
      },
      intensity: this.intensity,
      locked: false,
      lockedBy: undefined,
      name: this.name,
      selected: false,
      selectedBy: undefined,
      type: LightType.SPOT_LIGHT,
      uuid: this.uuid,
      angle: this.angle,
      penumbra: this.penumbra,
    };
    return lightInfoObject;
  }

  //

  /**
   * Creates the icon for the spotlight.
   * @returns The created icon mesh.
   */
  private createIcon(iconUrl: string): Sprite {
    const iconTexture = new TextureLoader().load(iconUrl);
    const iconMaterial = new SpriteMaterial({ map: iconTexture });
    const icon = new Sprite(iconMaterial);
    icon.scale.set(1, 1, 1);
    icon.visible = this.showIcon;
    this.add(icon);
    return icon;
  }

  //

  // Private method to create a sphere mesh
  private createSphere(color: string): Mesh {
    // BVH raycast registration globally
    SphereGeometry.prototype.computeBoundsTree = computeBoundsTree;
    SphereGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    Mesh.prototype.raycast = acceleratedRaycast;

    //
    const g = new SphereGeometry(0.6, 12, 12); // BoxGeometry(0.6, 0.6, 0.6);
    g.computeBoundsTree();
    const m = new MeshBasicMaterial({ color: new Color(color), transparent: true, opacity: 0 });
    const mesh = new Mesh(g, m);
    (mesh as any).outlineParent = this;
    return mesh;
  }

  //

  // Private method to load the cinematic camera model
  private loadUserFile(color: ColorRepresentation): Object3D {
    const loader = new FBXLoader();
    const obj = new Object3D();

    // Load the FBX file and modify its properties
    loader.load(filePath, (obj) => {
      this.object.add(obj);

      // Traverse through the object and set material properties
      obj.traverse((obj) => {
        if ((obj as any).material) {
          this.userMaterials.push((obj as any).material);
          (obj as any).material.color = new Color(color);
          (obj as any).material.emissive = new Color(color);
          (obj as any).material.emissiveIntensity = 1;
          (obj as any).material.transparent = true;
          // This editing below activates the occlusion of the POV in the bloom pass composer
          // Commented, for future references
          (obj as any).material.onBeforeCompile = (shader: any) => {
            shader = this.updateShader(shader);
          };
          (obj as any).material.needsUpdate = true;
        }
        if ((obj as any).isMesh) {
          (obj as any).material.transparent = true;
          (obj as any).material.opacity = 1; // = null;
        }
      });

      // Set the scale and position of the loaded object
      const scale = 0.0023;
      obj.scale.set(scale, scale, scale);
      obj.position.x = 0;
      obj.position.y = 0;
      obj.position.z = 0;
      obj.rotateX(-Math.PI / 2);
    });
    return obj;
  }

  private updateShader(shader: any) {
    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      uniform float isHovered;
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      layout(location = 3) out vec4 id_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
      diffuseColor.a = 1.0;
      #endif
      
      #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
      #endif
      
      vec4 objectColor = vec4( outgoingLight, diffuseColor.a );

      // float intensity = pow( 0.7 - dot( vNormal, vec3( 0.0, 0.0, -1.0 ) ), 200.0 ); 
      // vec4 hoveringColor = normalize(vec4(intensity));
      // // hoveringColor = step(vec4(0.5), hoveringColor);

      vec4 hoveringColor = vec4(1.0);
      
      base_FragColor = mix(objectColor, hoveringColor, isHovered);//vec4( outgoingLight, diffuseColor.a );
      bloom_FragColor = vec4( outgoingLight, 0.0 );
      depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
        bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
        base_FragColor.rgb = toneMapping( base_FragColor.rgb );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
      base_FragColor = linearToOutputTexel( base_FragColor );
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
        #ifdef FOG_EXP2
          float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
        #else
          float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
        #endif
        bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
        base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
    #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
        // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
        bloom_FragColor.rgb *= bloom_FragColor.a;
        base_FragColor.rgb *= base_FragColor.a;
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
        bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
        base_FragColor.rgb = dithering( base_FragColor.rgb );
      #endif`
    );

    return shader;
  }

  public generateSelectionBox() {
    const size = 0.7;

    const edgeGeom = new EdgesGeometry(new BoxGeometry(size, size, size));

    const edgeMat = new LineBasicMaterial({
      color: 0xffffff, // Set the color of the frame.
      linewidth: 1, // Set the line width of the frame.
    });

    this.selectionBox = new LineSegments(edgeGeom, edgeMat);

    // Position the frame at the center of the asset's bounding box.
    this.selectionBox.position.x = 0;
    this.selectionBox.position.y = 0;
    this.selectionBox.position.z = 0;

    this.selectionBox.visible = false;
    this.selectionBox.raycast = function () {};

    // Add the frame to the Group, making it part of the selector indicator.
    this.add(this.selectionBox);
  }

  public setIsHovered(value: number) {
    this.uniforms.isHovered.value = value;
  }

  public setIsSelected(value: boolean) {
    if (this.selectionBox) this.selectionBox.visible = value;
  }

  public setSelectionBoxColor(value: string | null) {
    if (this.selectionBox) {
      (this.selectionBox.material as LineBasicMaterial).color = new Color(value ? value : '#ffffff');
      (this.selectionBox.material as LineBasicMaterial).needsUpdate = true;
    }
  }

  //

  public snapToBottom(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    //

    // Assets intersection
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    let closerAsset;
    let closerChar;
    let closerObject;

    if (intersectAssets.length > 0) {
      // console.log(intersectAssets[0]);
      // this.position.set(0, 0, 0);
      // this.lookAt(intersectAssets[0].face.normal);
      // const { x, y, z } = intersectAssets[0].point;
      // this.position.set(x, y, z);
      closerAsset = intersectAssets[0];
    }

    //

    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);
    if (intersectChars.length > 0) {
      // console.log(intersectChars[0]);
      // const { x, y, z } = intersectChars[0].point;
      // this.position.set(x, y, z);
      closerChar = intersectChars[0];
    }

    if (closerAsset && closerChar) {
      closerObject = closerAsset.distance > closerChar.distance ? closerChar : closerObject;
    } else if (closerAsset) {
      closerObject = closerAsset;
    } else if (closerChar) {
      closerObject = closerChar;
    }

    if (closerObject) {
      const { x, y, z } = closerObject.point;
      this.position.set(x, y, z);
    }
  }

  public snapToObjectBelow(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, -1, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    // Assets intersection
    const intersectChars = this.snapRaycaster.intersectObjects(hitters.chars);
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);
    const intersectOthers = this.snapRaycaster.intersectObjects(hitters.others);
    //const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    // Merge the intersected objects into a single array
    let intersectedObjects = [...intersectChars, ...intersectAssets, ...intersectOthers];

    // Function to find the object with the shortest distance
    const findClosestObject = (array: any) => {
      return array.reduce((min: any, obj: any) => (obj.distance < min.distance ? obj : min), array[0]);
    };

    if (intersectedObjects.length > 0) {
      // Find the closer object for snapping
      const closestObject = findClosestObject(intersectedObjects);

      if (closestObject.distance < 0.05) {
        // Remove the closest object from the array
        intersectedObjects = intersectedObjects.filter((obj) => obj.distance !== closestObject.distance);
      } else {
        const { x, y, z } = closestObject.point;
        this.position.set(x, y, z);
      }
    }
  }

  public snapToObjectAbove(hitters: {
    lights: Object3D[];
    chars: Object3D[];
    assets: Object3D[];
    others: Object3D[];
    active: Object3D[];
  }) {
    const characterPosition = this.position.clone();
    const snapDirection = new Vector3(0, 1000, 0);

    // Update raycaster
    this.snapRaycaster.set(characterPosition, snapDirection);

    // Assets intersection
    const intersectAssets = this.snapRaycaster.intersectObjects(hitters.assets);

    if (intersectAssets.length > 0) {
      let index = 0;
      while (intersectAssets[index].distance <= 0.05 && index < intersectAssets.length - 1) {
        index++;
      }
      const { x, y, z } = intersectAssets[index].point;
      this.position.set(x, y, z);
      return;
    }
  }

  public changeLight(lightInfo: Partial<LightInfo>) {
    lightInfo.color && this.setColor(new Color(lightInfo.color)); // (this.color = new Color(lightInfo.color));
    lightInfo.castShadow !== undefined && (this.castShadow = lightInfo.castShadow);
    lightInfo.distance && (this.distance = lightInfo.distance);
    lightInfo.intensity && (this.intensity = lightInfo.intensity);
    lightInfo.angle && (this.angle = lightInfo.angle);
    lightInfo.penumbra && (this.penumbra = lightInfo.penumbra);
  }
}

export { RadicalSpotLight };
