// Author: Fyrestar https://mevedia.com (https://github.com/Fyrestar/THREE.InfiniteGridHelper)
import {
  Color,
  DoubleSide,
  MeshBasicMaterial,
  GridHelper,
  GLSL3,
  Vector3,
} from 'three';
// Extend the GridHelper class to create a custom, infinitely extending grid
export class InfiniteGridHelper extends GridHelper {
  constructor(size1: number, size2: number, color: Color, distance: number, axes?: string) {
    super(size1, size2, 0xff0000, 0xffffff);

    // console.log("grid distance is", distance);

    // Create a basic material that will use the custom color and make the grid double-sided and transparent
    const material = new MeshBasicMaterial({
      color: color,
      side: DoubleSide,
      transparent: true,
      opacity: 0.2,
    });

    // @ts-ignore
    // Override the default material with our new custom material
    this.material = material; //new MeshBasicMaterial({ color: 0xffffff });

    // Modify the shader code at the time of compilation
    this.material.onBeforeCompile = (shader: any) => {
      // Set GLSL version
      shader.glslVersion = GLSL3;

      // Add a custom uniform for controlling the fading distance
      shader.uniforms = {
        ...shader.uniforms,
        uDistance: { value: distance },
        uColor1: { value: new Vector3(1.0, 1.0, 1.0) },
        uColor2: { value: new Vector3(0.9, 0.9, 0.9) },
      };

      // Adjust the vertex shader to control the grid's position in world space
      shader.vertexShader =
        `
        precision mediump float;
        varying vec3 vertexPosition;
        varying vec3 worldPositionz;
      ` + shader.vertexShader;

      shader.vertexShader = shader.vertexShader.replace(
        `#include <project_vertex>`,

        `
        vertexPosition = position.xyz;
        vec4 mvPosition = vec4( vertexPosition.xyz, 1.0 );
        mvPosition = modelViewMatrix * mvPosition;

        gl_Position = projectionMatrix * mvPosition;
        worldPositionz = mvPosition.xyz;//gl_Position.xyz;
        `
      );

      shader.fragmentShader =
        `precision mediump float;
      ` + shader.fragmentShader;

      // Adjust the fragment shader to control the visual styling and add fading based on distance
      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <common>`,
        `#include <common>
        varying vec3 vertexPosition;
        varying vec3 worldPositionz;
        uniform float uDistance;
        uniform vec3 uColor1;
        uniform vec3 uColor2;
        layout(location = 0) out vec4 base_FragColor;
        layout(location = 1) out vec4 bloom_FragColor;
        layout(location = 2) out vec4 depth_FragColor;
        layout(location = 3) out vec4 id_FragColor;
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <opaque_fragment>`,

        `#ifdef OPAQUE
        diffuseColor.a = 1.0;
        #endif
        
        #ifdef USE_TRANSMISSION
        diffuseColor.a *= material.transmissionAlpha;
        #endif

        // float d = smoothstep(uDistance, uDistance*0.75, (gl_FragCoord.z / gl_FragCoord.w)); // 1.0 - min(distance(cameraPosition, vertexPosition) / uDistance, 1.0);
        float d = smoothstep(uDistance, 0.0, -worldPositionz.z); // 1.0 - min(distance(cameraPosition, vertexPosition) / uDistance, 1.0);
        
        base_FragColor = vec4( outgoingLight.rgb, diffuseColor.a );

        //

        float threshold = 0.0001;

        // Compute x line of the grid at the origin
        float xedge1 = step(-threshold, vertexPosition.x);
        float xedge2 = step(vertexPosition.x, threshold);

        float xmask = xedge1 * xedge2;

        // Compute z line of the grid at the origin
        float zedge1 = step(-threshold, vertexPosition.z);
        float zedge2 = step(vertexPosition.z, threshold);

        // Combine edges to create a mask for the interval
        float zmask = zedge1 * zedge2;

        // Combine the edges
        float mask = xmask + zmask;

        //

        base_FragColor.rgb = mix(uColor2, uColor1, mask);
        
        //

        base_FragColor.a *= d * d;
        bloom_FragColor = vec4(0.0);
        depth_FragColor = vec4(0.0);
        id_FragColor = vec4(0.0);

        if ((base_FragColor.a) <= 0.001) discard;
        `
      );

      // Remove unnecessary shader chunks for this specific material
      shader.fragmentShader = shader.fragmentShader.replace(`#include <tonemapping_fragment>`, ``);

      shader.fragmentShader = shader.fragmentShader.replace(`#include <colorspace_fragment>`, ``);

      shader.fragmentShader = shader.fragmentShader.replace(`#include <fog_fragment>`, ``);

      shader.fragmentShader = shader.fragmentShader.replace(`#include <premultiplied_alpha_fragment>`, ``);

      shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, ``);
    };

    // Optimize rendering by disabling frustum culling and setting a custom render order
    this.frustumCulled = false;
    this.renderOrder = -1;
  }
}
