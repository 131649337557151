import { getChildrenByTypeDeep } from 'react-nanny';
import { Component } from '../../../react-utils/component';
import { usePadding } from '@radical/room-util-layout-padding';
import { useRoomConnection } from '@radical/room-connection';
import { useVideoPlayerLayout } from '@radical/room-player';
import { useBreakpoints } from '../../../react-utils/breakpoints';
import { useLayoutDisplay } from '@radical/room-util-layout-display';
import React, { PropsWithChildren } from 'react';

type Props = {
  element?: React.ReactElement | React.ReactNode;
  className?: string;
};

const Room = ({ children, element, className = '' }: PropsWithChildren<Props>) => {
  const canvas = getChildrenByTypeDeep(children || element, [Room.Canvas]);
  const mobilePortraitLayout = getChildrenByTypeDeep(children || element, [Room.MobilePortraitLayout]);
  const mobileLandscapeLayout = getChildrenByTypeDeep(children || element, [Room.MobileLandscapeLayout]);
  const tabletLayout = getChildrenByTypeDeep(children || element, [Room.TabletLayout]);
  const desktopLayout = getChildrenByTypeDeep(children || element, [Room.DesktopLayout]);
  const { isExpanded } = useVideoPlayerLayout();
  const { padding } = usePadding();
  const { interactionsLocked } = useRoomConnection();
  const { isMobileLandscape, isTabletLandscape, isDesktop } = useBreakpoints();
  const { isHeaderHidden } = useLayoutDisplay();

  // useEffect(() => {
  //   console.log('Room mounted----------------------------------------s');
  // }, []);

  return (
    <>
      <div className={`visualizer w-full h-full relative ${className}`}>
        <div className="visualzer-element">{element}</div>
        <div
          className={`canvas-container w-full h-full absolute top-0 left-0`}
          style={{
            padding: `${!isHeaderHidden ? padding.top : 0} ${padding.right} ${padding.bottom} ${padding.left}`,
          }}
        >
          {canvas}
        </div>
        {isExpanded && (
          <div
            className={
              'video-preview-container absolute ' +
              (isMobileLandscape || isTabletLandscape || isDesktop
                ? 'w-1/2 h-full right-0'
                : 'w-full h-1/2 pt-[50px] bottom-0')
            }
            id="video-preview-container"
          ></div>
        )}
        {mobilePortraitLayout}
        {mobileLandscapeLayout}
        {tabletLayout}
        {desktopLayout}
      </div>
      {interactionsLocked && (
        <div className="absolute left-0 top-0 w-full h-full pointer-events-all opacity-[.01] z-50 bg-slate-50" />
      )}
      <style>
        {`
        body {
          overscroll-behavior: none;
          user-select: none;
        }
        `}
      </style>
    </>
  );
};

Room.Canvas = Component();
Room.MobilePortraitLayout = Component();
Room.MobileLandscapeLayout = Component();
Room.TabletLayout = Component();
Room.DesktopLayout = Component();

export default Room;
