import { Color } from 'three';
import { Pass } from 'three/examples/jsm/postprocessing/Pass';

class MRTPass extends Pass {
  constructor(scene, camera, mrt) {
    super();

    this.scene = scene;
    this.camera = camera;

    this.renderTarget = mrt;

    this.needsSwap = false;
    this._oldClearColor = new Color();

    this.isPass = true;
    this.enabled = true;
    this.clear = true;
    this.renderToScreen = false;

    this.name = 'MRTTarget';
  }

  setSize( /* width, height */ ) {}

  dispose() {}

  render(renderer) {
    renderer.setRenderTarget(this.renderTarget);
    renderer.clear();
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null);
    renderer.clear();
  }
}

export { MRTPass };
