import { createContext, useState, useContext } from 'react';

type UserPreviewModeState = {
  isPreviewMode: boolean;
};

const initalState: UserPreviewModeState = {
  isPreviewMode: false,
};

const initialActions = {
  setIsPreviewMode: (isPreviewMode: boolean) => {},
};

const UserPreviewModeContext = createContext({ state: initalState, actions: initialActions });

export function UserPreviewModeProvider({ children }: { children: React.ReactElement }) {
  const [state, setState] = useState(initalState);

  const setIsPreviewMode = (isPreviewMode: boolean) => setState((prevState) => ({ ...prevState, isPreviewMode }));
  const actions = { setIsPreviewMode };
  return <UserPreviewModeContext.Provider value={{ state, actions }}>{children}</UserPreviewModeContext.Provider>;
}

export function useUserPreviewMode() {
  const { state, actions } = useContext(UserPreviewModeContext);
  return { ...state, ...actions };
}
