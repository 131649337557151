import { BoxGeometry, EdgesGeometry, Group, LineBasicMaterial, LineSegments, Mesh, MeshBasicMaterial } from 'three';
import { AssetModelInfo } from '@radical/canvas-types';

// AssetLoadIndicator extends the Three.js Group class to create a grouped set of objects
// that represent the loading state of an asset, including a frame around the asset and a progress bar.
export class AssetLoadIndicator extends Group {
  private assetInfo: AssetModelInfo; // Asset information including the bounding box and center, used to position and scale the loading indicator.
  private loadProgress: number = 0; // The current loading progress of the asset, represented as a value between 0 and 1.
  private loadingFrame: LineSegments | undefined; // A LineSegments object that visually represents the frame around the asset's bounding box.
  private loadingProgress: Group | undefined; // A Group object that contains the progress bar mesh, allowing for scalable progress indication.

  constructor(assetInfo: AssetModelInfo) {
    super(); // Call the constructor of the superclass (Group).

    this.assetInfo = assetInfo; // Store the asset information for later use.

    this.createObjects(); // Call the method to create the loading frame and progress bar.
  }

  // This method creates the visual elements of the loading indicator, including the frame and progress bar.
  private createObjects() {
    // Destructure the bounding box and center from the asset information.
    const { boundingBox, boxCenter } = this.assetInfo;

    // Create an EdgesGeometry from a BoxGeometry based on the asset's bounding box,
    // which will serve as the frame around the asset.
    const edgeGeom = new EdgesGeometry(new BoxGeometry(boundingBox.x, boundingBox.y, boundingBox.z));

    const edgeMat = new LineBasicMaterial({
      color: 4285378, // Set the color of the frame.
      linewidth: 1, // Set the line width of the frame.
    });

    this.loadingFrame = new LineSegments(edgeGeom, edgeMat);

    // Position the frame at the center of the asset's bounding box.
    this.loadingFrame.position.x = boxCenter.x;
    this.loadingFrame.position.y = boxCenter.y;
    this.loadingFrame.position.z = boxCenter.z;

    // Add the frame to the Group, making it part of the loading indicator.
    this.add(this.loadingFrame);

    // Create a BoxGeometry for the progress bar with the same dimensions as the asset's bounding box.
    const progressGeo = new BoxGeometry(boundingBox.x, boundingBox.y, boundingBox.z);
    const progressMat = new MeshBasicMaterial({ color: 7701939, transparent: true, opacity: 0.5 });
    const progressMesh = new Mesh(progressGeo, progressMat);

    // Position the progress bar mesh at the bottom of the bounding box.
    progressMesh.position.y = boundingBox.y / 2;

    // Group the progress bar mesh to allow for easy scaling to represent loading progress.
    this.loadingProgress = new Group();
    this.loadingProgress.add(progressMesh);

    // Position the progress group at the base of the asset's bounding box, centered on the x and z axes.
    this.loadingProgress.position.x = boxCenter.x;
    this.loadingProgress.position.y = boxCenter.y - boundingBox.y / 2; //boundingBox.y / -2;
    this.loadingProgress.position.z = boxCenter.z;

    // Initialize the scale of the progress group to reflect no progress (0% loaded).
    this.loadingProgress.scale.x = 1;
    this.loadingProgress.scale.y = this.loadProgress;
    this.loadingProgress.scale.z = 1;

    // Add the progress group to the Group, making it part of the loading indicator.
    this.add(this.loadingProgress);
  }

  // This public method updates the loading progress of the asset.
  public setLoadProgress(pr: number) {
    this.loadProgress = pr; // Update the internal load progress value.

    this.loadingProgress?.scale.setY(pr);
  }
}
