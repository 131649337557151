import { RemoveIcon } from '@components/icons';
import DropdownIcon from './icons/dropdownIcon.svg';
import { ErrorMessage, Field, useField } from 'formik';
type InputProps = {
  name: string;
  min?: number;
  max?: number;
};
export default function NumberInput({ name, min, max }: InputProps) {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div className="relative w-full max-w-[120px]">
      <Field
        value={value}
        name={name}
        onChange={(e: any) => {
          e.preventDefault();
          if (e.nativeEvent.inputType === 'deleteContentBackward') {
            setValue(e.target.value || min || 1);
          }
          const { value } = e.target;
          if (max !== undefined && value >= max) {
            setValue(max);
          } else if (min !== undefined && value <= min) {
            setValue(min);
          } else setValue(value);
        }}
        type={'number'}
        className={`pt-[9px] font-demi pb-[7px] px-[15px] bg-springtime-rain  rounded-[4px] leading-[21.5px] w-full outline-0 relative text-center caret-transparent
        `}
      />
      <div className="absolute right-0 top-[50%] translate-y-[-50%] w-[20px] h-[14px] flex flex-col items-center">
        <img
          src={DropdownIcon}
          className={`h-[50%] rotate-180 cursor-pointer`}
          onClick={() => max !== undefined && value < max && setValue(value + 1)}
        />
        <img
          src={DropdownIcon}
          className={`h-[50%] cursor-pointer`}
          onClick={() => min !== undefined && value > min && setValue(value - 1)}
        />
      </div>
      <ErrorMessage
        name={name}
        component="span"
        className="text-[11px] text-error-red absolute left-[5px] top-[100%]"
      />
    </div>
  );
}
